import React from "react";
import ChromeDefault from "Images/ChromeDefault.png";
import FirefoxDefault from "Images/FirefoxDefault.png";
import { ImageLogo } from "./windows";

const imagePath = `${process.env.REACT_APP_ASSETS_PATH}/troubleshoot/android`;

export const android = [
  {
    key: "chrome",
    name: "Chrome",
    logo: <ImageLogo src={ChromeDefault} />,
    data: [
      {
        type: "text",
        content: "Allow access to camera and microphone",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/1.png`,
      },
      {
        type: "text",
        content: "Or, click the lock icon to go to Site settings",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/2.png`,
      },
      {
        type: "text",
        content: "Click Access your camera and choose Allow",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/3.png`,
      },
      {
        type: "text",
        content: "Click Access your microphone and choose Allow",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/4.png`,
      },
    ],
  },
  {
    key: "firefox",
    name: "Firefox",
    logo: <ImageLogo src={FirefoxDefault} />,
    data: [
      {
        type: "text",
        content: "Allow Firefox access to camera and microphone",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/1.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/2.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/3.png`,
      },
      {
        type: "text",
        content: "Or, Click the three vertical dots and then choose Settings",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/4.png`,
      },
      {
        type: "text",
        content: "Under Privacy and security, click Site permissions",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/5.png`,
      },
      {
        type: "text",
        content: "Allow access to Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/6.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/7.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/8.png`,
      },
    ],
  },
];
