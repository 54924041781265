import React from "react";
export var happyCloudSvg = (React.createElement("svg", { width: "100px", height: "100px", viewBox: "0 0 40 40", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
    React.createElement("title", null, "Icon/Product/MAX/Reporting/Metric Importer, Easter Egg - Happy"),
    React.createElement("defs", null,
        React.createElement("filter", { x: "-29.5%", y: "-29.5%", width: "159.1%", height: "159.1%", filterUnits: "objectBoundingBox", id: "filter-1" },
            React.createElement("feOffset", { dx: "0", dy: "1", in: "SourceAlpha", result: "shadowOffsetOuter1" }),
            React.createElement("feGaussianBlur", { stdDeviation: "1", in: "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowBlurOuter1", result: "shadowMatrixOuter1" }),
            React.createElement("feMerge", null,
                React.createElement("feMergeNode", { in: "shadowMatrixOuter1" }),
                React.createElement("feMergeNode", { in: "SourceGraphic" }))),
        React.createElement("path", { d: "M5,15 C2.23857625,15 0,12.7614237 0,10 C0,7.48474699 1.85724473,5.40327673 4.2751686,5.05215482 C5.11784342,2.13425344 7.80968091,0 11,0 C14.1903191,0 16.8821566,2.13425344 17.7256306,5.05287847 C20.1427553,5.40327673 22,7.48474699 22,10 C22,12.7614237 19.7614237,15 17,15 L17,15 Z", id: "path-2" }),
        React.createElement("radialGradient", { cx: "0%", cy: "-2.64909517e-06%", fx: "0%", fy: "-2.64909517e-06%", r: "107.185281%", id: "radialGradient-4" },
            React.createElement("stop", { "stop-color": "#FFFFFF", offset: "0%" }),
            React.createElement("stop", { "stop-color": "#FFFFFF", "stop-opacity": "0", offset: "100%" })),
        React.createElement("path", { d: "M8,4 C8.09420482,4 8.18536718,4.01953949 8.2717841,4.05606401 C8.10995826,4.13861077 8,4.30646432 8,4.5 C8,4.77614237 8.22385763,5 8.5,5 C8.65735732,5 8.79773679,4.92730916 8.88939084,4.81367504 C8.95993599,5.01840277 9,5.2521833 9,5.5 C9,6.32842712 8.55228475,7 8,7 C7.44771525,7 7,6.32842712 7,5.5 C7,4.67157288 7.44771525,4 8,4 Z", id: "path-5" }),
        React.createElement("filter", { x: "-12.5%", y: "-8.3%", width: "125.0%", height: "116.7%", filterUnits: "objectBoundingBox", id: "filter-6" },
            React.createElement("feOffset", { dx: "0", dy: "-0.5", in: "SourceAlpha", result: "shadowOffsetInner1" }),
            React.createElement("feComposite", { in: "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: "-1", k3: "1", result: "shadowInnerInner1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowInnerInner1" })),
        React.createElement("path", { d: "M14,4 C14.0942048,4 14.1853672,4.01953949 14.2717841,4.05606401 C14.1099583,4.13861077 14,4.30646432 14,4.5 C14,4.77614237 14.2238576,5 14.5,5 C14.6573573,5 14.7977368,4.92730916 14.8893908,4.81367504 C14.959936,5.01840277 15,5.2521833 15,5.5 C15,6.32842712 14.5522847,7 14,7 C13.4477153,7 13,6.32842712 13,5.5 C13,4.67157288 13.4477153,4 14,4 Z", id: "path-7" }),
        React.createElement("filter", { x: "-12.5%", y: "-8.3%", width: "125.0%", height: "116.7%", filterUnits: "objectBoundingBox", id: "filter-8" },
            React.createElement("feOffset", { dx: "0", dy: "-0.5", in: "SourceAlpha", result: "shadowOffsetInner1" }),
            React.createElement("feComposite", { in: "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: "-1", k3: "1", result: "shadowInnerInner1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowInnerInner1" })),
        React.createElement("path", { d: "M0,0 C0.666666667,0.666666667 2.33333333,1 5,1 C7.66666667,1 9.33333333,0.666666667 10,0 L10,7 L5,12 L1,12 C0.44771525,12 6.76353751e-17,11.5522847 0,11 L0,0 L0,0 Z", id: "path-9" }),
        React.createElement("linearGradient", { x1: "50%", y1: "1.94289029e-14%", x2: "50%", y2: "100%", id: "linearGradient-11" },
            React.createElement("stop", { "stop-color": "#8EE9C9", offset: "0%" }),
            React.createElement("stop", { "stop-color": "#24BA86", offset: "100%" }))),
    React.createElement("g", { id: "Icon/Product/MAX/Reporting/Metric-Importer,-Easter-Egg---Happy", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
        React.createElement("g", { id: "Group-39", filter: "url(#filter-1)", transform: "translate(9.000000, 9.000000)" },
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("mask", { id: "mask-3", fill: "white" },
                    React.createElement("use", { xlinkHref: "#path-2" })),
                React.createElement("use", { id: "Mask", fill: "#FFFFFF", xlinkHref: "#path-2" }),
                React.createElement("g", { mask: "url(#mask-3)", fill: "url(#radialGradient-4)", opacity: "0.45" },
                    React.createElement("path", { d: "M19.4960832,0.000595209414 C19.8250423,1.27889569 20,2.61901606 20,4 C20,12.836556 12.836556,20 4,20 C2.61901606,20 1.27889569,19.8250423 0.000595209414,19.4960832 L0,0 Z" }))),
            React.createElement("path", { d: "M5.68907222,8.82810736 C6.67811972,9.60936912 8.44842898,10 11,10 C13.551571,10 15.3218803,9.60936912 16.3109278,8.82810736 C16.4953116,8.68253118 16.7628044,8.71392751 16.9084243,8.89827674 C16.967737,8.97336434 17,9.06625717 17,9.16194491 L17,9.99024671 C16.9999712,10.254587 16.8953044,10.5081735 16.7088568,10.6955583 C15.8435784,11.5651861 13.9406261,12 11,12 C8.05933524,12 6.15637159,11.5651747 5.29110904,10.695524 C5.10466387,10.5081361 5,10.2545486 5,9.99020793 L5,9.16194491 C5,8.92701982 5.19044441,8.7365754 5.4253695,8.7365754 C5.52105724,8.7365754 5.61395007,8.7688384 5.68907222,8.82810736 Z", id: "Rectangle-52", fill: "#000000" }),
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("use", { fill: "#000000", "fill-rule": "evenodd", xlinkHref: "#path-5" }),
                React.createElement("use", { fill: "black", "fill-opacity": "1", filter: "url(#filter-6)", xlinkHref: "#path-5" })),
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("use", { fill: "#000000", "fill-rule": "evenodd", xlinkHref: "#path-7" }),
                React.createElement("use", { fill: "black", "fill-opacity": "1", filter: "url(#filter-8)", xlinkHref: "#path-7" })),
            React.createElement("g", { id: "Rectangle-58", transform: "translate(6.000000, 10.000000)" },
                React.createElement("g", { id: "Combined-Shape" },
                    React.createElement("mask", { id: "mask-10", fill: "white" },
                        React.createElement("use", { xlinkHref: "#path-9" })),
                    React.createElement("use", { id: "Mask", fill: "#24BA86", xlinkHref: "#path-9" }),
                    React.createElement("g", { mask: "url(#mask-10)", fill: "url(#radialGradient-4)", opacity: "0.45" },
                        React.createElement("g", { transform: "translate(-6.000000, -10.000000)" },
                            React.createElement("path", { d: "M19.4960832,0.000595209414 C19.8250423,1.27889569 20,2.61901606 20,4 C20,12.836556 12.836556,20 4,20 C2.61901606,20 1.27889569,19.8250423 0.000595209414,19.4960832 L0,0 Z" })))),
                React.createElement("path", { d: "M0,0 C0.666666667,0.666666667 2.33333333,1 5,1 C7.66666667,1 9.33333333,0.666666667 10,0 L10,4 L0,4 L0,0 Z", id: "Rectangle-11", "fill-opacity": "0.64", fill: "url(#linearGradient-11)" }),
                React.createElement("polygon", { id: "Path-21", fill: "#FFFFFF", points: "3 9 3 6 2 6 5 3 8 6 7 6 7 9" }),
                React.createElement("path", { d: "M7,7 L10,7 L10,7 L5,12 L5,9 C5,7.8954305 5.8954305,7 7,7 Z", fill: "#104F38" }))))));
export var sadCloudSvg = (React.createElement("svg", { width: "100px", height: "100px", viewBox: "0 0 40 40", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
    React.createElement("title", null, "Icon/Product/MAX/Reporting/Metric Importer, Easter Egg - Sad"),
    React.createElement("defs", null,
        React.createElement("filter", { x: "-29.5%", y: "-29.5%", width: "159.1%", height: "159.1%", filterUnits: "objectBoundingBox", id: "filter-1" },
            React.createElement("feOffset", { dx: "0", dy: "1", in: "SourceAlpha", result: "shadowOffsetOuter1" }),
            React.createElement("feGaussianBlur", { stdDeviation: "1", in: "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowBlurOuter1", result: "shadowMatrixOuter1" }),
            React.createElement("feMerge", null,
                React.createElement("feMergeNode", { in: "shadowMatrixOuter1" }),
                React.createElement("feMergeNode", { in: "SourceGraphic" }))),
        React.createElement("path", { d: "M5,15 C2.23857625,15 0,12.7614237 0,10 C0,7.48474699 1.85724473,5.40327673 4.2751686,5.05215482 C5.11784342,2.13425344 7.80968091,0 11,0 C14.1903191,0 16.8821566,2.13425344 17.7256306,5.05287847 C20.1427553,5.40327673 22,7.48474699 22,10 C22,12.7614237 19.7614237,15 17,15 L17,15 Z", id: "path-2" }),
        React.createElement("radialGradient", { cx: "0%", cy: "-2.64909517e-06%", fx: "0%", fy: "-2.64909517e-06%", r: "107.185281%", id: "radialGradient-4" },
            React.createElement("stop", { "stop-color": "#FFFFFF", offset: "0%" }),
            React.createElement("stop", { "stop-color": "#FFFFFF", "stop-opacity": "0", offset: "100%" })),
        React.createElement("path", { d: "M8,4 C8.09420482,4 8.18536718,4.01953949 8.2717841,4.05606401 C8.10995826,4.13861077 8,4.30646432 8,4.5 C8,4.77614237 8.22385763,5 8.5,5 C8.65735732,5 8.79773679,4.92730916 8.88939084,4.81367504 C8.95993599,5.01840277 9,5.2521833 9,5.5 C9,6.32842712 8.55228475,7 8,7 C7.44771525,7 7,6.32842712 7,5.5 C7,4.67157288 7.44771525,4 8,4 Z", id: "path-5" }),
        React.createElement("filter", { x: "-12.5%", y: "-8.3%", width: "125.0%", height: "116.7%", filterUnits: "objectBoundingBox", id: "filter-6" },
            React.createElement("feOffset", { dx: "0", dy: "-0.5", in: "SourceAlpha", result: "shadowOffsetInner1" }),
            React.createElement("feComposite", { in: "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: "-1", k3: "1", result: "shadowInnerInner1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowInnerInner1" })),
        React.createElement("path", { d: "M14,4 C14.0942048,4 14.1853672,4.01953949 14.2717841,4.05606401 C14.1099583,4.13861077 14,4.30646432 14,4.5 C14,4.77614237 14.2238576,5 14.5,5 C14.6573573,5 14.7977368,4.92730916 14.8893908,4.81367504 C14.959936,5.01840277 15,5.2521833 15,5.5 C15,6.32842712 14.5522847,7 14,7 C13.4477153,7 13,6.32842712 13,5.5 C13,4.67157288 13.4477153,4 14,4 Z", id: "path-7" }),
        React.createElement("filter", { x: "-12.5%", y: "-8.3%", width: "125.0%", height: "116.7%", filterUnits: "objectBoundingBox", id: "filter-8" },
            React.createElement("feOffset", { dx: "0", dy: "-0.5", in: "SourceAlpha", result: "shadowOffsetInner1" }),
            React.createElement("feComposite", { in: "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: "-1", k3: "1", result: "shadowInnerInner1" }),
            React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowInnerInner1" }))),
    React.createElement("g", { id: "Icon/Product/MAX/Reporting/Metric-Importer,-Easter-Egg---Sad", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
        React.createElement("g", { id: "Group-39", filter: "url(#filter-1)", transform: "translate(9.000000, 9.000000)" },
            React.createElement("path", { d: "M15.9090909,22 C16.4545455,22 17,21.1962783 17,20.3333333 C17,19.7580367 16.6363636,18.6469256 15.9090909,17 C15.9090909,17.9722222 15.7878788,18.6666667 15.5454545,19.0833333 C15.1818182,19.7083333 15,20.125 15,20.75 C15,21.375 15.3636364,22 15.9090909,22 Z", id: "Oval", fill: "#1F95ED" }),
            React.createElement("path", { d: "M10.9090909,19 C11.4545455,19 12,18.1962783 12,17.3333333 C12,16.7580367 11.6363636,15.6469256 10.9090909,14 C10.9090909,14.9722222 10.7878788,15.6666667 10.5454545,16.0833333 C10.1818182,16.7083333 10,17.125 10,17.75 C10,18.375 10.3636364,19 10.9090909,19 Z", id: "Oval", fill: "#1F95ED" }),
            React.createElement("path", { d: "M5.90909091,21 C6.45454545,21 7,20.1962783 7,19.3333333 C7,18.7580367 6.63636364,17.6469256 5.90909091,16 C5.90909091,16.9722222 5.78787879,17.6666667 5.54545455,18.0833333 C5.18181818,18.7083333 5,19.125 5,19.75 C5,20.375 5.36363636,21 5.90909091,21 Z", id: "Oval", fill: "#1F95ED" }),
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("mask", { id: "mask-3", fill: "white" },
                    React.createElement("use", { xlinkHref: "#path-2" })),
                React.createElement("use", { id: "Mask", fill: "#E6E9EB", xlinkHref: "#path-2" }),
                React.createElement("g", { mask: "url(#mask-3)", fill: "url(#radialGradient-4)", opacity: "0.45" },
                    React.createElement("path", { d: "M19.4960832,0.000595209414 C19.8250423,1.27889569 20,2.61901606 20,4 C20,12.836556 12.836556,20 4,20 C2.61901606,20 1.27889569,19.8250423 0.000595209414,19.4960832 L0,0 Z" }))),
            React.createElement("path", { d: "M5.68907222,11.9084507 C5.61395007,11.9677196 5.52105724,12.0000084 5.4253695,12.0000084 C5.19044441,12.0000084 5,11.8095382 5,11.5746131 L5,10.7463501 C5,10.4820094 5.10466387,10.2284219 5.29110904,10.0410341 C6.15637159,9.17138338 8.05933524,8.73655803 11,8.73655803 C13.9406261,8.73655803 15.8435784,9.17137195 16.7088568,10.0409998 C16.8953044,10.2283846 17,10.4819711 17,10.7463113 L17,11.5746131 C17,11.6703009 16.967737,11.7631937 16.9084243,11.8382813 C16.7628044,12.0226305 16.4953116,12.0540268 16.3109278,11.9084507 C15.3218803,11.1271889 13.551571,10.736558 11,10.736558 C8.44842898,10.736558 6.67811972,11.1271889 5.68907222,11.9084507 Z", id: "Rectangle-52", fill: "#000000" }),
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("use", { fill: "#000000", "fill-rule": "evenodd", xlinkHref: "#path-5" }),
                React.createElement("use", { fill: "black", "fill-opacity": "1", filter: "url(#filter-6)", xlinkHref: "#path-5" })),
            React.createElement("g", { id: "Combined-Shape" },
                React.createElement("use", { fill: "#000000", "fill-rule": "evenodd", xlinkHref: "#path-7" }),
                React.createElement("use", { fill: "black", "fill-opacity": "1", filter: "url(#filter-8)", xlinkHref: "#path-7" }))))));
