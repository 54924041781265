import styled from "styled-components";
import {
  Div,
  Input,
  TextareaInput,
  SelectInput,
  DatePicker,
  useIsMobile,
} from "@max/common-ui";
import { Icon, Input as InputBase } from "notes";
import { Section } from "./common";
import { useFormikContext } from "formik";
import { ShoutoutAllocationMode } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { responseTimeOptions } from "./common/initialValues";

const header = "The Basics";
const description = "Here are the fields required on the shoutout order form.";

const Label = styled(Div)`
  font-size: 15px;
  margin-top: 28px;
  margin-bottom: 7px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
  }
`;

const RAlignInput = styled(InputBase)`
  text-align: right;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const noteText =
  "This is the amount of time you will have to review and complete a fan’s message request. After the selected time has passed, request will automatically be declined and refunded.";

const BasicFields = () => {
  const { values } = useFormikContext<any>();
  const isMobile = useIsMobile();
  const inputWidth = isMobile ? "100%" : "392px";
  const inputWidthShort = isMobile ? "100%" : "270px";

  return (
    <Section withMargin header={header} description={description}>
      <Label>Introduction</Label>
      <TextareaInput
        placeholder="This will be an introductory message to your fans who wish to join the meet & greet. Hype them up. Get them excited…"
        name="introduction"
      />
      {values?.type === ShoutoutAllocationMode.date && (
        <>
          <Label>Campaign end date</Label>
          <DatePicker width={inputWidthShort} name="campaignEndDate" />
        </>
      )}
      {values?.type === ShoutoutAllocationMode.quantity && (
        <>
          <Label>Maximum number of requests</Label>
          <Input
            type="number"
            width={inputWidthShort}
            name="maxNumberRequests"
            placeholder="Qty."
            Component={RAlignInput}
          />
        </>
      )}
      <Label>Cost per message</Label>
      <Input
        leftIcon={
          <Div mt_n2>
            <Icon form name="USD" />
          </Div>
        }
        type="number"
        width={inputWidthShort}
        name="unitPrice"
        placeholder="00"
        Component={RAlignInput}
      />
      <Label>Response Time</Label>
      <SelectInput
        width={inputWidthShort}
        name="responseTimeDays"
        placeholder="How are you connected to this person?"
        options={responseTimeOptions}
      />
      <Div mt_8 lite italic fs_11>
        {noteText}
      </Div>
      <Label>Campaign name (optional)</Label>
      <Input
        width={inputWidth}
        name="name"
        placeholder="What would you like to call this campaign?"
      />
      <Label>Rules and limitations (optional)</Label>
      <Div mb_10>
        <TextareaInput
          name="rules"
          placeholder="This is an overview of any prohibited subject matter, questions, requests, etc. along with any rules or limitations on shout out requests..."
        />
      </Div>
    </Section>
  );
};

export default BasicFields;
