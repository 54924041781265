import {
  Div,
  Modal as ModalBase,
  ModalWrapper,
  shareMenuItems,
} from "@max/common-ui";
import { useMessagesAppContext } from "components/Messages/MessagesAppContextProvider";
import styled from "styled-components";
import { getShoutoutPath } from "utils";

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    width: 472px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 60vw;
    }
  }
`;

const UrlWrapper = styled(Div)`
  padding: 10px 12px;
  color: ${(props) => props.theme.colors.text};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray20};
  width: 100%;
`;

const UrlText = styled(Div)`
  width: calc(100% - 80px);
`;

const CopyLink = styled(Div)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.action};
  font-size: 12px;
  text-transform: uppercase;
  width: 65px;
  font-weight: 700;
`;

const ShareIcons = ({ url }) => {
  const CopyLinkComponent = shareMenuItems("24")[2].shareComponent;
  const copyLinkUI = (
    <Div centered mt_30 w100>
      <UrlWrapper dflex spaceBetween>
        <UrlText truncateText>{url}</UrlText>
        <CopyLinkComponent url={url}>
          <CopyLink link mt_4 mr_5>
            Copy link
          </CopyLink>
        </CopyLinkComponent>
      </UrlWrapper>
    </Div>
  );

  return (
    <Div w100>
      <Div centered>
        <Div dflex svg_text>
          {shareMenuItems("24")
            .slice(0, 2)
            .map((mi, index) => {
              const Share = mi.shareComponent;
              return (
                <Div key={index} clickable mr_12 ml_12>
                  <Share
                    style={{ outline: "none" }}
                    url={url}
                    onShareWindowClose={null}
                  >
                    {mi.icon}
                  </Share>
                </Div>
              );
            })}
        </Div>
      </Div>
      {copyLinkUI}
    </Div>
  );
};

export const ShareCampaignModal = ({ isOpen, onClose, title }) => {
  const { selectedShoutout } = useMessagesAppContext();

  return (
    <Modal withCloseIcon={false} {...{ isOpen, onClose }}>
      <Div c_text textAlignCenter>
        <Div fs_25 extraBold>
          {title}
        </Div>
        <Div bold fs_14 c_gray20 truncate>
          {selectedShoutout?.data?.name}
        </Div>
        <Div centered>
          <Div w_392 lite fs_17 mt_24 mb_40>
            How would you like to share your campaign with fans?
          </Div>
        </Div>
        <Div centered>
          <ShareIcons url={getShoutoutPath(selectedShoutout?.id)} />
        </Div>
        <Div link c_action fs_17 mt_40 mb_15 bold onClick={onClose}>
          Done
        </Div>
      </Div>
    </Modal>
  );
};
