import { Button } from "./Button";
import Div from "./Div";
import styled, { css } from "styled-components";
import { Backdrop, ModalContainer } from "./overlay";

const ModalWrapper = styled(Div)`
  color: #222222;
  background-color: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  width: 400px;
  padding: 40px;
  @media all and (max-width: 500px) {
    width: 70%;
  }

  @media all and ${(props) => props.theme.media.mobile} {
    padding: 20px;
    text-align: left;
    justify-content: left;
  }
`;

const mobileButtonCss = css`
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #26a6fa;
`;

const ModalButton = styled(Button)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 80px;
    ${mobileButtonCss}
    :hover {
      ${mobileButtonCss}
      user-select: none;
      :hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
`;

const DropShadow = styled(Backdrop)`
  background-color: black;
  opacity: 0.38;
`;

const ButtonWrapper = styled(Div)`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 20px;
    justify-content: flex-end;
  }
`;

const DiscardRecordingText = styled(Div)`
  text-align: center;
  @media all and ${(props) => props.theme.media.mobile} {
    font-weight: 400;
    text-align: left;
    font-size: 20px;
  }
`;

const WarningText = styled(Div)`
  text-align: center;
  margin-top: 8px;
  @media all and ${(props) => props.theme.media.mobile} {
    text-align: left;
    font-weight: 300;
  }
`;

const DiscardRercordingModal = ({
  isDiscardRecordingModalOpen,
  setIsDiscardRecordingModalOpen,
  onConfirm,
}) => {
  const buttonsUI = (
    <ButtonWrapper>
      <ModalButton
        secondary
        onClick={() => {
          setIsDiscardRecordingModalOpen(false);
        }}
      >
        Cancel
      </ModalButton>
      <ModalButton
        onClick={() => {
          onConfirm();
          setIsDiscardRecordingModalOpen(false);
        }}
      >
        Discard
      </ModalButton>
    </ButtonWrapper>
  );

  return (
    <>
      {isDiscardRecordingModalOpen && (
        <>
          <DropShadow
            onClick={() => {
              setIsDiscardRecordingModalOpen(false);
            }}
          />
          <ModalContainer>
            <ModalWrapper>
              <Div>
                <DiscardRecordingText bold fs_25>
                  Discard Recording
                </DiscardRecordingText>
                <WarningText fs_17>
                  Are you sure? This cannot be undone.
                </WarningText>
              </Div>
              {buttonsUI}
            </ModalWrapper>
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default DiscardRercordingModal;
