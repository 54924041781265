import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import firebase from "firebase-tools";
import { useUserContext } from "./UserContextProvider";
import { useAppContext } from "./AppContextProvider";

const DEFAULT_REDIRECT = "/";

interface LoginParams {
  /**
   * Email of user
   */
  email: string;

  /**
   * Target path for redirect
   */
  to: string;
}

const getParams = (search: Location["search"]): LoginParams => {
  const parsed = new URLSearchParams(search);

  const params = {
    email: parsed.get("email") || "",
    to: parsed.get("to") || DEFAULT_REDIRECT,
  };

  return params;
};

/**
 * Logs in the user using the email link login URL generated by Firebase.
 */
const AccountLogin = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const location = useLocation();
  const [redirectToUrl, setRedirectToUrl] = useState(null);
  const { setIsLoading } = useAppContext();

  useEffect(() => {
    if (user && user?.uid && redirectToUrl) history.push(redirectToUrl);
  }, [user, redirectToUrl]);

  useEffect(() => {
    (async () => {
      if (firebase.auth().isSignInWithEmailLink(location.search)) {
        // Link is a sign-in link, let's try to sign in with it
        const params = getParams(location.search);
        try {
          setIsLoading(true);
          await firebase
            .auth()
            .signInWithEmailLink(params.email, location.search);
          setIsLoading(false);
          setRedirectToUrl(params.to);
        } catch (error) {
          console.error(error);
          history.push("/");
        }
      }
    })();
  }, [location.search, history]);

  return <></>;
};

export default AccountLogin;
