import { DateTime } from "luxon";

export const EXPIRATION_DAYS_WARNING_THRESHOLD = 4;

export const getResponseTimeWarningMessage = (ttl) => {
  if (!ttl) return null;
  const { days, hours, minutes } = ttl;
  const getTime = (val, label) =>
    `${Math.floor(val)} ${label}${val > 1 ? "s" : ""}`;
  if (days >= EXPIRATION_DAYS_WARNING_THRESHOLD) return null;
  if (days > 0) return getTime(days, "day");
  if (hours > 0) return getTime(hours, "hour");
  if (minutes > 0) return getTime(minutes, "minute");
};

export const getTimetoLive = (createdAt, responseTimeDays) => {
  return createdAt
    .plus({ days: responseTimeDays })
    .diff(DateTime.now(), ["days", "hours", "minutes", "milliseconds"]);
};
