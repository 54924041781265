var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
var prefixPropMappings = {
    mt: "margin-top",
    ml: "margin-left",
    mr: "margin-right",
    mb: "margin-bottom",
    pt: "padding-top",
    pb: "padding-bottom",
    pr: "padding-right",
    pl: "padding-left",
    p: "padding",
    m: "margin",
    w: "width",
    h: "height",
    fs: "font-size",
    br: "border-radius",
    ls: "letter-spacing",
    lh: "line-height",
    maxw: "max-width",
    maxh: "max-height",
    minw: "min-width",
};
export var Div = styled("div")(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n\n  ", "\n  ", "\n"], ["\n  ", ";\n  ",
    ";\n  ",
    ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ", ";\n  ",
    ";\n  ",
    ";\n  ",
    ";\n  ",
    "\n  ",
    "\n  ",
    "\n  ",
    "\n\n  ",
    "\n  ",
    "\n"])), function (props) { return props.dflex && "display: flex"; }, function (props) {
    return props.dflexColumn && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n    "], ["\n      display: flex;\n      flex-direction: column;\n    "])));
}, function (props) {
    return props.truncateText && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      overflow: hidden;\n      word-wrap: break-word;\n      overflow-wrap: break-word;\n      display: -webkit-box;\n      -webkit-line-clamp: 1;\n      -webkit-box-orient: vertical;\n      -webkit-hyphens: auto;\n      -moz-hyphens: auto;\n      hyphens: auto;\n    "], ["\n      overflow: hidden;\n      word-wrap: break-word;\n      overflow-wrap: break-word;\n      display: -webkit-box;\n      -webkit-line-clamp: 1;\n      -webkit-box-orient: vertical;\n      -webkit-hyphens: auto;\n      -moz-hyphens: auto;\n      hyphens: auto;\n    "])));
}, function (props) { return props.w100 && "width: 100%"; }, function (props) { return props.h100 && "height: 100%"; }, function (props) { return props.h50 && "height: 50%"; }, function (props) { return props.textAlignCenter && "text-align: center"; }, function (props) { return props.uppercase && "text-transform: uppercase"; }, function (props) { return props.capitalize && "text-transform: capitalize"; }, function (props) { return props.lowercase && "text-transform: lowercase"; }, function (props) {
    return props.floatLeft && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      float: left;\n    "], ["\n      float: left;\n    "])));
}, function (props) {
    return props.disabled && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      pointer-events: none;\n      opacity: 0.5;\n    "], ["\n      pointer-events: none;\n      opacity: 0.5;\n    "])));
}, function (props) {
    return props.justifyCenter && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: flex;\n      justify-content: center;\n    "], ["\n      display: flex;\n      justify-content: center;\n    "])));
}, function (props) {
    return props.link && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.8;\n        text-decoration: underline;\n      }\n    "], ["\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.8;\n        text-decoration: underline;\n      }\n    "])));
}, function (props) {
    return props.italic && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      font-style: italic;\n    "], ["\n      font-style: italic;\n    "])));
}, function (props) {
    return props.spaceBetween && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-between;\n    "], ["\n      display: flex;\n      justify-content: space-between;\n    "])));
}, function (props) {
    return props.spaceAround && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-around;\n    "], ["\n      display: flex;\n      justify-content: space-around;\n    "])));
}, function (props) {
    return props.justifyEnd && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      display: flex;\n      justify-content: flex-end;\n    "], ["\n      display: flex;\n      justify-content: flex-end;\n    "])));
}, function (props) { return props.alignItemsCenter && "align-items: center"; }, function (props) { return props.border && "border: solid 1px black"; }, function (props) { return props.borderwhite && "border: solid 1px white"; }, function (props) { return props.borderred && "border: solid 1px red"; }, function (props) { return props.bgred && "background: red"; }, function (props) { return props.positionRelative && "position: relative"; }, function (props) { return props.positionRelative && "position: relative"; }, function (props) { return props.positionAbsolute && "position: absolute"; }, function (props) { return props.opacity5 && "opacity: .5"; }, function (props) { return props.lite && "font-weight: 300"; }, function (props) { return props.semiBold && "font-weight: 500"; }, function (props) { return props.bold && "font-weight: 600"; }, function (props) { return props.extraBold && "font-weight: 700"; }, function (props) {
    return props.flexRowReverse && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: row-reverse;\n    "], ["\n      display: flex;\n      flex-direction: row-reverse;\n    "])));
}, function (props) {
    return props.underline && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      text-decoration: underline;\n    "], ["\n      text-decoration: underline;\n    "])));
}, function (props) {
    return props.centered && css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    "], ["\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    "])));
}, function (props) {
    return props.flexWrap && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      display: flex;\n      flex-wrap: wrap;\n    "], ["\n      display: flex;\n      flex-wrap: wrap;\n    "])));
}, function (props) { return props.grow && "flex-grow: 1"; }, function (props) {
    return props.alignCenter && css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n    "], ["\n      display: flex;\n      align-items: center;\n    "])));
}, function (props) {
    return props.alignItemsEnd && css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n      align-items: flex-end;\n      display: flex;\n    "], ["\n      align-items: flex-end;\n      display: flex;\n    "])));
}, function (props) {
    return props.clickable && css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.8;\n      }\n    "], ["\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.8;\n      }\n    "])));
}, function (props) {
    return props.forMobile && css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n      @media all and ", " {\n        display: none;\n      }\n    "], ["\n      @media all and ", " {\n        display: none;\n      }\n    "])), function (props) { return props.theme.media.desktop; });
}, function (props) {
    return props.forDesktop && css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      @media all and ", " {\n        display: none;\n      }\n    "], ["\n      @media all and ", " {\n        display: none;\n      }\n    "])), function (props) { return props.theme.media.mobile; });
}, function (props) {
    return props.yscroll && css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      overflow-y: scroll;\n    "], ["\n      overflow-y: scroll;\n    "])));
}, function (props) {
    var propKeys = Object.keys(props);
    var cssProps = [];
    var addCss = function (prefix, property) {
        var prp = propKeys.filter(function (pk) { return pk.startsWith(prefix + "_"); })[0];
        if (prp)
            cssProps.push(property + ": " + prp.split("_")[1].replace("n", "-") + "px");
    };
    var prefixPropMappingKeys = Object.keys(prefixPropMappings);
    for (var i = 0; i < prefixPropMappingKeys.length; i++) {
        var key = prefixPropMappingKeys[i];
        addCss(key, prefixPropMappings[key]);
    }
    var svg = propKeys.filter(function (pk) { return pk.startsWith("svg_"); })[0];
    if (svg)
        cssProps.push("\n          svg {\n            path {\n              fill: " + props.theme.colors[svg.split("_")[1]] + ";\n            }\n          }\n        ");
    var color = propKeys.filter(function (pk) { return pk.startsWith("c_"); })[0];
    if (color)
        cssProps.push("color: " + props.theme.colors[color.split("_")[1]] + ";");
    var hcolor = propKeys.filter(function (pk) { return pk.startsWith("c_h"); })[0];
    if (hcolor)
        cssProps.push("color: #" + hcolor.split("_h")[1] + ";");
    var bgcolor = propKeys.filter(function (pk) { return pk.startsWith("bg_"); })[0];
    if (bgcolor)
        cssProps.push("background: " + props.theme.colors[bgcolor.split("_")[1]] + ";");
    var bghcolor = propKeys.filter(function (pk) { return pk.startsWith("bg_h"); })[0];
    if (bghcolor)
        cssProps.push("background: #" + bghcolor.split("_h")[1] + ";");
    return cssProps.join(";");
}, function (props) {
    return props.mcontainer && css(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n      @media all and ", " {\n        padding-left: 5%;\n        padding-right: 5%;\n      }\n    "], ["\n      @media all and ", " {\n        padding-left: 5%;\n        padding-right: 5%;\n      }\n    "])), function (props) { return props.theme.media.mobile; });
}, function (props) {
    return props.mcontainer_left && css(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n      @media all and ", " {\n        padding-left: 5%;\n      }\n    "], ["\n      @media all and ", " {\n        padding-left: 5%;\n      }\n    "])), function (props) { return props.theme.media.mobile; });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
