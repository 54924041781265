import { AppLoading, Div, Modal } from "@max/common-ui";
import { Divider, IconWrapper, TeammateFormModal } from "./common";
import { Icon, ButtonPrimary, ButtonSecondary } from "notes";
import { useState } from "react";
import { artists } from "@musicaudienceexchange/shoutout-interface/lib/functions/artists";
import { useArtistGroupContext } from "../../common/ArtistGroupProvider";
import { fb, fstore } from "firebase-tools";
import { capitalize } from "utils";

type Props = {
  teammate: artists.ArtistGroupMember;
};

const Teammate: React.FC<Props> = ({
  teammate: { uid, name, email, verified, roles },
}) => {
  const { artistGroupId, getData } = useArtistGroupContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const formInitialValues = { name, email, roles };

  const onDeleteTeammate = async () => {
    setIsLoading(true);
    setIsRemoveModalOpen(false);
    await fstore
      .doc(`artist_groups/${artistGroupId}`)
      .set(
        { members: { [uid]: fb.firestore.FieldValue.delete() } },
        { merge: true }
      );
    await getData();
    setIsLoading(false);
  };

  const removeModalButtonsUI = (
    <Div dflex mt_100>
      <Div mr_24>
        <ButtonSecondary onClick={() => setIsRemoveModalOpen(false)}>
          Cancel
        </ButtonSecondary>
      </Div>
      <Div>
        <ButtonPrimary onClick={onDeleteTeammate}>Confirm</ButtonPrimary>
      </Div>
    </Div>
  );

  const removeModalUI = (
    <Modal
      isOpen={isRemoveModalOpen}
      withCloseIcon={false}
      onClose={() => setIsRemoveModalOpen(false)}
    >
      <Div textAlignCenter c_text>
        <Div fs_24 semiBold>
          Remove Teammate
        </Div>
        <Div c_gray20 mt_10 fs_14 bold>
          {name}
        </Div>
        <Div mt_24 fs_17 lite>
          Are you sure? This cannot be undone.
        </Div>
      </Div>
      {removeModalButtonsUI}
    </Modal>
  );

  const viewDataButtonsUI = (
    <Div dflex>
      <IconWrapper
        mr_15
        clickable
        title="Edit Teammate"
        onClick={() => setIsFormModalOpen(true)}
      >
        <Icon tertiary name="Edit" />
      </IconWrapper>
      <IconWrapper
        isDelete
        clickable
        title="Delete Teammate"
        onClick={() => setIsRemoveModalOpen(true)}
      >
        <Icon tertiary name="Delete" />
      </IconWrapper>
    </Div>
  );

  const viewDataUI = (
    <>
      <Div mb_30 mt_20 spaceBetween>
        <Div>
          <Div dflex alignCenter mb_3>
            <Div mr_10 fs_17 c_text bold>
              {name}
            </Div>
            <Div svg_green30 mt_5>
              {verified && <Icon badge name="FacebookVerified" />}
            </Div>
          </Div>
          <Div lite fs_17 c_gray30>
            <Div>{email}</Div>
            <Div>{roles.map((r) => capitalize(r)).join(", ")}</Div>
          </Div>
        </Div>
        {viewDataButtonsUI}
      </Div>
      <Divider />
    </>
  );

  return (
    <>
      {isLoading && <AppLoading />}
      <TeammateFormModal
        isOpen={isFormModalOpen}
        isEdit={true}
        initialValues={formInitialValues}
        onClose={() => setIsFormModalOpen(false)}
      />
      {removeModalUI}
      {viewDataUI}
    </>
  );
};

export default Teammate;
