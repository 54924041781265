import styled from "styled-components";
import { MAXTheme } from "notes";
import Div from "../../../common/Div";

const Container = styled(Div)`
  background: #ededed;
  height: 7px;
  border-radius: 5px;
  overflow: hidden;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    height: 4px;
    border-radius: 0;
  }
`;

const Level = styled(Div)`
  border-radius: 5px;
  width: ${(props) => props.level}%;
  background: ${(props) => props.color};
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    border-radius: 0;
  }
`;

const Meter = ({ level = 0 }) => {
  let color = MAXTheme.colors.approved;
  if (level > 60) color = MAXTheme.colors.pending;
  if (level > 80) color = MAXTheme.colors.inputErrorBorder;
  return (
    <Container w100 positionRelative>
      <Level h100 level={level} color={color} />
    </Container>
  );
};

export default Meter;
