import { AppLoading } from "@max/common-ui";
import { createContext, useContext, useState } from "react";

type State = {
  isNewCampaign: boolean;
  setIsNewCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  isCampaignEdit: boolean;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCampaignEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isTroubleshootModalOpen: boolean;
  setIsTroubleshootModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const AppContext = createContext<State>({} as State);

export const AppContextProvider = ({ children }) => {
  const [isTroubleshootModalOpen, setIsTroubleshootModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewCampaign, setIsNewCampaign] = useState(false);
  const [isCampaignEdit, setIsCampaignEdit] = useState(false);
  const value = {
    isNewCampaign,
    setIsNewCampaign,
    isCampaignEdit,
    isLoading,
    setIsLoading,
    setIsCampaignEdit,
    isTroubleshootModalOpen,
    setIsTroubleshootModalOpen,
  };
  return (
    <AppContext.Provider value={value}>
      {isLoading && <AppLoading />}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
