import React, { useState, useEffect } from "react";
import { Icon } from "notes";
import {
  Container,
  Title,
  Content,
  IconContainer,
  TitleContainer,
} from "./styled";

export const Accordion = ({
  title = "",
  onClick = null,
  active = false,
  children,
}) => {
  const [open, toggleOpen] = useState(active);

  useEffect(() => {
    toggleOpen(active);
  }, [active]);

  const handleClick = () => {
    toggleOpen(!open);
    if (onClick && typeof onClick === "function") onClick(open);
  };

  return (
    <Container>
      <Title onClick={() => handleClick()}>
        <IconContainer>
          <Icon indicator name={open ? "Open" : "Closed"} />
        </IconContainer>
        <TitleContainer>{title}</TitleContainer>
      </Title>
      {open && <Content>{children}</Content>}
    </Container>
  );
};
