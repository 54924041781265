import styled from "styled-components";
import { Div, useIsMobile, SetLogo } from "@max/common-ui";
import { ShoutoutLogoSvg } from "./Svg";

const Text = styled(Div)``;

const ShoutoutLogoSvgWrapper = styled(Div)``;
const SetLogoWrapper = styled(Div)``;

const LogoSection = styled(Div)`
  user-select: none;
  ${ShoutoutLogoSvgWrapper} {
    svg {
      path {
        fill: ${(props) => props.theme.colors.text};
      }
    }
  }
  ${SetLogoWrapper} {
    svg {
      path {
        fill: ${(props) => props.theme.colors.gray20};
      }
    }
  }
  ${Text} {
    color: ${(props) => props.theme.colors.gray20};
  }
`;

export const ShoutoutLogo = () => {
  const isMobile = useIsMobile();

  return (
    <LogoSection dflex alignCenter>
      <ShoutoutLogoSvgWrapper>
        <ShoutoutLogoSvg
          height={isMobile ? "22px" : "30px"}
          width={isMobile ? "130px" : "230px"}
        />
      </ShoutoutLogoSvgWrapper>
      <Div forDesktop>
        <Div>
          <Text fs_10 mb_n2>
            Powered by
          </Text>
          <SetLogoWrapper>
            <SetLogo width="31px" height="16px" />
          </SetLogoWrapper>
        </Div>
      </Div>
    </LogoSection>
  );
};
