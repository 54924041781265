var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon } from "notes";
import React from "react";
import styled from "styled-components";
import { Div } from ".";
export var WarningMessageWrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: solid 1px ", ";\n  background: ", ";\n  color: ", ";\n  font-size: 17px;\n  padding: 16px 24px;\n  span {\n    margin-left: 5px;\n    color: ", ";\n    text-decoration: underline;\n    user-select: none;\n    :hover {\n      cursor: pointer;\n      opacity: 0.8;\n    }\n  }\n  @media all and ", " {\n    margin-top: 0;\n    border-top: none;\n    font-size: 15px;\n    padding: 20px 5%;\n    border: none;\n    border-top: solid 1px ", ";\n    border-bottom: solid 1px ", ";\n  }\n"], ["\n  border: solid 1px ", ";\n  background: ", ";\n  color: ", ";\n  font-size: 17px;\n  padding: 16px 24px;\n  span {\n    margin-left: 5px;\n    color: ", ";\n    text-decoration: underline;\n    user-select: none;\n    :hover {\n      cursor: pointer;\n      opacity: 0.8;\n    }\n  }\n  @media all and ", " {\n    margin-top: 0;\n    border-top: none;\n    font-size: 15px;\n    padding: 20px 5%;\n    border: none;\n    border-top: solid 1px ", ";\n    border-bottom: solid 1px ", ";\n  }\n"])), function (props) { return props.theme.colors.yellow20; }, function (props) { return props.theme.colors.yellow10; }, function (props) { return props.theme.colors.text; }, function (props) { return props.theme.colors.linkDefault; }, function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.colors.yellow20; }, function (props) { return props.theme.colors.yellow20; });
export var WarningIcon = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n"], ["\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (props) { return props.theme.colors.orange40; });
export var WarningMessage = function (_a) {
    var message = _a.message;
    return (React.createElement(WarningMessageWrapper, null,
        React.createElement(Div, { dflex: true },
            React.createElement(WarningIcon, { mt_3: true },
                React.createElement(Icon, { form: true, name: "Error" })),
            React.createElement(Div, { ml_17: true }, message))));
};
var templateObject_1, templateObject_2;
