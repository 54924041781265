import { useEffect } from "react";
import styled from "styled-components";
import { GridColumn, ButtonGoogle, ButtonFacebook } from "notes";
import firebase from "firebase";

const signInWithGoogle = async () => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  } catch (err) {
    console.log(err);
    window.scrollTo(0, 0);
  }
};

const signInWithFacebook = async () => {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("email");
    firebase.auth().signInWithRedirect(provider);
  } catch (err) {
    console.log(err);
    window.scrollTo(0, 0);
  }
};

export const OpenAuth = ({ onSuccess }) => {
  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(function (result: any) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        const user = result.user;
        if (user) {
          onSuccess(result, result?.credential?.providerId);
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        // ...
      });
  }, []);

  return (
    <GridColumn noShrink>
      <ButtonFacebook onClick={signInWithFacebook}>
        Continue With Facebook
      </ButtonFacebook>
      <GoogleButton onClick={signInWithGoogle}>
        Continue With Google
      </GoogleButton>
    </GridColumn>
  );
};

const GoogleButton = styled(ButtonGoogle)`
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  margin-top: 8px;
`;
