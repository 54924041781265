import { Section } from "./common";
import { CheckboxMultiselect, Div, Input } from "@max/common-ui";
import { useFormikContext } from "formik";
import { conversationStarterOptions } from "./common/initialValues";

const header = "Conversation Starters";
const description =
  "These are the prompts that a fan can use to tell you what they would like in their Shout Out.";

const StarterFields = () => {
  const { values } = useFormikContext<any>();

  return (
    <Section withMargin header={header} description={description}>
      <Div mt_30>
        <CheckboxMultiselect
          name="starters"
          options={conversationStarterOptions}
        />
      </Div>
      {values.starters.includes("Other") && (
        <Div>
          <Input
            width="100%"
            name="customStarter"
            placeholder="Custom conversation starter..."
          />
        </Div>
      )}
    </Section>
  );
};

export default StarterFields;
