import { Div, SetLogo } from "@max/common-ui";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { useAppContext } from "components/AppContextProvider";
import Settings from "components/common/Settings";
import { fstore } from "firebase-tools";
import { Icon, Message } from "notes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { removeLocalBlobsById, resetUploadErrorCount } from "utils";
import { useMediaQuery } from "../../../../hooks";
import ldb from "../../../../ldb";
import { theme } from "../../../../theme";
import DiscardRercordingModal from "../../../common/DiscardRecordingModal";
import { ERROR_COUNT_THRESHOLD } from "../../MessageList";
import {
  messageIsCompleted,
  TABS,
  useMessagesContext,
} from "../../MessagesContextProvider";
import { useVideoMessageContext } from "../../VideoMessageContextProvider";
import RejectedExpiredMessage from "./RejectedExpiredMessage";
import ToClipboard from "./ToClipboard";
import Videojs from "./Videojs";
import VideoRecorder from "./VideoRecorder";

const WIDTH = "100%";
const HEIGHT = "100%";

const BottomControls = styled(Div)`
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    position: fixed;
    height: 65px;
    border-top: solid 1px #e0e0e0;
    background-color: white;
    bottom: 0;
    left: 0;
    justify-content: space-around;
    z-index: 10000000;
    width: 100vw;
  }
`;

const WatermarkWrapper = styled(Div)`
  position: absolute;
  bottom: 19px;
  left: 30px;
  pointer-events: none;
  opacity: 0.9;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    left: 15px;
    opacity: 0.4;
    bottom: 0px;
  }
`;

const SettingsIconWrapper = styled(Div)`
  svg {
    path {
      fill: white;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const SettingsWrapper = styled(Div)`
  position: absolute;
  z-index: 10;
  right: 16px;
  top: 16px;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  user-select: none;
  background: rgba(0, 0, 0, 0.72);
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    margin-right: 10px;
  }
`;

const DesktopSettingsIcon = styled(Div)`
  display: none;
  @media all and ${(props) => props.theme.media.desktop} {
    display: block;
    @media all and ${(props) => props.theme.media.verticalDisplay} {
      display: none;
    }
  }
`;

const TabletSettingsIcon = styled(Div)`
  display: none;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    display: block;
    @media all and ${(props) => props.theme.media.mobile} {
      display: none;
    }
  }
`;

const Watermark = ({ url }) => {
  const isVerticalDisplaySmall = useMediaQuery(
    theme.media.verticalDisplaySmall
  );

  const logoUI = (
    <>
      {url ? (
        <img height={!isVerticalDisplaySmall ? "40px" : "25px"} src={url} />
      ) : (
        <SetLogo height={!isVerticalDisplaySmall ? "40" : "25"} />
      )}
    </>
  );
  return (
    <Div positionAbsolute>
      <Div positionRelative h100 w100>
        <WatermarkWrapper>{logoUI}</WatermarkWrapper>
      </Div>
    </Div>
  );
};

const VideoMessageRecorder = () => {
  const {
    selectedTab,
    uploadFile,
    selectMessage,
    fileUploadProgress,
    selectedMessage,
    onInitVideoRecorder,
    userInitializedCamera,
    setUserInitializedCamera,
    onMountingVideoRecorder,
  } = useMessagesContext();
  const {
    preferences,
    selectedAudioDeviceId,
    selectedVideoDeviceId,
    setSelectedAudioDeviceId,
    setSelectedVideoDeviceId,
    userIsRecordingVideo,
    setDevices,
    setUserIsRecordingVideo,
  } = useVideoMessageContext();
  const { setIsTroubleshootModalOpen } = useAppContext();

  const selectedMessageId = selectedMessage.id;
  const { autoPlay } = preferences;
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [recording, setRecording] = useState(null);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const isUploadingFile = !!fileUploadProgress[selectedMessageId];
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  let recordingUrl = null;
  let shortRecordingUrl = null;
  const {
    id: messageRequestId,
    public: {
      status,
      download: downloadData,
      shoutout: {
        assets: { overlay },
      },
    },
    private: { error },
  } = selectedMessage;

  if (status === RequestStatus.completed) {
    recordingUrl = downloadData?.source;
    shortRecordingUrl = downloadData?.url;
  }

  const recordingData = recording?.data;
  const isRecordingAvailable = recordingData || recordingUrl;

  const isMobile = useMediaQuery(theme.media.mobile);
  const isVerticalDisplay = useMediaQuery(theme.media.verticalDisplay);

  const userShouldDiscardRecording =
    selectedTab === TABS.QUEUE &&
    status === RequestStatus.failed &&
    error?.count >= ERROR_COUNT_THRESHOLD;

  useEffect(() => {
    const loadLocalRecordingData = async () => {
      const blob = await ldb.collection("blobs").doc(selectedMessageId).get();
      setRecording({ data: blob?.data, isFromLocal: true });
    };
    loadLocalRecordingData();
  }, []);

  useEffect(() => {
    if (!recording?.isFromLocal) setUserIsRecordingVideo(!!recordingData);
  }, [recordingData]);

  const onVideoRecorderError = (err) => {
    setIsErrorMessageOpen(true);
    setErrorMessage(err);
    setTimeout(() => setIsErrorMessageOpen(false), 4000);
  };

  const sendVideoRecording = async () => {
    if (userShouldDiscardRecording) {
      await removeLocalBlobsById([selectedMessage.id]);
      fstore.doc(`shoutout_requests/${messageRequestId}`).update({
        status: RequestStatus.viewed,
      });
      resetUploadErrorCount(messageRequestId);
    }
    setUserIsRecordingVideo(false);
    if (isVerticalDisplay) selectMessage(null);

    await ldb
      .collection("blobs")
      .add(
        { data: recordingData, shoutoutId: selectedMessage.public.shoutoutId },
        messageRequestId
      );

    // File upload is handled by Messagelist component on mobile
    if (!isMobile) uploadFile(messageRequestId);
  };

  const discardRecording = async () => {
    if (userShouldDiscardRecording) {
      await removeLocalBlobsById([selectedMessage.id]);
      resetUploadErrorCount(selectedMessage.id);
    }
    setRecording({ data: null });
  };

  const messageExpiredRejected = () => {
    const statuses = [RequestStatus.expired, RequestStatus.rejected];
    return (
      statuses.includes(selectedMessage.public.status) ||
      statuses.includes(selectedMessage.private.previousStatus)
    );
  };

  const bottomControls = (
    <BottomControls mt_28 w100 spaceBetween alignCenter>
      <DiscardButton dflex onClick={() => setIsDiscardRecordingModalOpen(true)}>
        <Div mr_5 centered>
          <Icon name="Delete" />
        </Div>
        <DiscardButtonText>Discard Recording</DiscardButtonText>
      </DiscardButton>
      <SendRecordingButton onClick={sendVideoRecording}>
        <Div ml_n7 mr_2 centered>
          <Icon width="30" height="30" name="Send" />
        </Div>
        {userShouldDiscardRecording ? "Retry Sending" : "Send Recording"}
      </SendRecordingButton>
    </BottomControls>
  );

  const errorMessageUI = (
    <Message
      open={isErrorMessageOpen}
      setOpen={setIsErrorMessageOpen}
      color="red"
    >
      <Div dflex alignCenter>
        <Icon form name="Error" />
        <Div bold ml_15>
          {errorMessage}
        </Div>
      </Div>
    </Message>
  );

  const recordingSettingsUI = !messageExpiredRejected() && (
    <SettingsWrapper>
      <SettingsIconWrapper onClick={() => setIsSettingsModalOpen(true)}>
        <Icon name="Gear" />
      </SettingsIconWrapper>
      {isSettingsModalOpen && (
        <Div mt_n30>
          <Settings
            onClose={() => {
              setIsSettingsModalOpen(false);
            }}
          />
        </Div>
      )}
    </SettingsWrapper>
  );
  const copyToClipboardUI = selectedTab === TABS.COMPLETED &&
    !messageExpiredRejected() &&
    messageIsCompleted(selectedMessage) && (
      <ToClipboardContainer centered pb_40>
        <ToClipboard url={shortRecordingUrl} />
      </ToClipboardContainer>
    );

  const videoSectionUI = () => {
    if (!messageExpiredRejected())
      return (
        <VideoRecorder
          width={WIDTH}
          height={HEIGHT}
          initializeOnMount={userInitializedCamera}
          selectedAudioDeviceId={selectedAudioDeviceId}
          selectedVideoDeviceId={selectedVideoDeviceId}
          setSelectedAudioDeviceId={setSelectedAudioDeviceId}
          setSelectedVideoDeviceId={setSelectedVideoDeviceId}
          setDevices={setDevices}
          onMounting={onMountingVideoRecorder}
          onTroubleshoot={() => setIsTroubleshootModalOpen(true)}
          onInit={() => {
            onInitVideoRecorder();
            setUserInitializedCamera(true);
          }}
          onError={onVideoRecorderError}
          onStartCountDown={() => setUserIsRecordingVideo(true)}
          onStartRecording={() => setUserIsRecordingVideo(true)}
          onCancelCountDown={() => setUserIsRecordingVideo(false)}
          onStopRecording={(blob) => {
            setRecording({ data: blob });
          }}
        />
      );
  };

  return (
    <Wrapper dflexColumn alignCenter>
      {errorMessageUI}
      <DiscardRercordingModal
        setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
        isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
        onConfirm={discardRecording}
      />
      <Div w100>
        {messageExpiredRejected() && (
          <RejectedExpiredMessage message={selectedMessage} />
        )}
        <Div centered>
          <TabletSettingsIcon>{recordingSettingsUI}</TabletSettingsIcon>
          <VideoRecorderWrapper>
            <DesktopSettingsIcon>{recordingSettingsUI}</DesktopSettingsIcon>
            {isRecordingAvailable ? (
              <Videojs
                withObjectFitCover={!!recordingData}
                src={recordingUrl}
                autoPlay={autoPlay}
                width={WIDTH}
                height={HEIGHT}
                blob={recordingData}
              />
            ) : (
              videoSectionUI()
            )}
            {userIsRecordingVideo && <Watermark url={overlay?.path} />}
          </VideoRecorderWrapper>
        </Div>
        {((selectedTab === TABS.QUEUE && recordingData && !isUploadingFile) ||
          userShouldDiscardRecording) &&
          bottomControls}
      </Div>
      {copyToClipboardUI}
    </Wrapper>
  );
};

const ToClipboardContainer = styled(Div)`
  width: 310px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    display: none;
  }
`;

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 395px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    width: 100%;
    border-bottom: 4px solid #a3a3a3;
  }
`;

const VideoRecorderWrapper = styled(Div)`
  height: 500px;
  width: 300px;
  position: relative;
  border-radius: 5px;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    height: 288px;
    width: 172.8px;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    border-radius: 0;
  }
`;

const SendRecordingButton = styled(Div)`
  background-color: #26a6fa;
  border-radius: 4px;
  font-size: 17px;
  color: white;
  width: 178px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  user-select: none;
  svg {
    path {
      fill: white;
    }
  }
  :hover {
    cursor: pointer;
    background-color: #3f7595;
    opacity: 0.9;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    width: auto;
    font-size: 16px;
    padding: 0 10px;
  }
`;

const DiscardButtonText = styled(Div)`
  margin-top: 2px;
  border-bottom: solid 2px transparent;
`;

const DiscardButton = styled(Div)`
  font-family: Overpass, sans-serif;
  color: #d8514a;
  font-size: 18px;
  font-weight: bold;
  user-select: none;
  border-bottom: solid 2px transparent;
  svg {
    path {
      fill: #d8514a;
    }
  }
  :hover {
    cursor: pointer;
    opacity: 0.9;
    ${DiscardButtonText} {
      border-bottom: solid 2px #d8514a;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 16px;
  }
`;

export default VideoMessageRecorder;
