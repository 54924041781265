import {
  Div,
  Modal as ModalBase,
  ModalWrapper,
  shareMenuItems,
  useIsMobile,
  ModalContainer,
} from "@max/common-ui";
import { useMessagesAppContext } from "components/Messages/MessagesAppContextProvider";
import styled from "styled-components";
import { LoaderInline } from "notes";
import { getShoutoutPath } from "utils";

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    @media all and ${(props) => props.theme.media.mobile} {
      margin-top: 40px;
    }
  }
  ${ModalWrapper} {
    width: 680px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 70vw;
    }
  }
`;

const DoneButtonWrapper = styled(Div)`
  margin-top: 50px;
  margin-bottom: 15px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
    margin-bottom: 0;
  }
`;

export const MyLinksModal = ({ isOpen, onClose }) => {
  return (
    <Modal {...{ isOpen, onClose }}>
      <Div c_text w100>
        <Div fs_25 extraBold>
          Share Campaign
        </Div>
        <Div lite fs_17 mt_2>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Div>
        <LinksTable />
        <DoneButtonWrapper link centered c_action bold onClick={onClose}>
          Done
        </DoneButtonWrapper>
      </Div>
    </Modal>
  );
};

const LTColumn = styled(Div)`
  width: ${(props) => props.width || "100%"};
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray30};
  height: 100%;
  display: flex;
  align-items: center;
`;

const ShareIcons = ({ url }) => {
  return (
    <Div dflex svg_text>
      {shareMenuItems("16").map((mi, index) => {
        const Share = mi.shareComponent;
        return (
          <Div key={index} clickable mr_15>
            <Share
              style={{ outline: "none" }}
              url={url}
              onShareWindowClose={null}
            >
              {mi.icon}
            </Share>
          </Div>
        );
      })}
    </Div>
  );
};

const ShareLinkText = styled(Div)`
  color: ${(props) => props.theme.colors.action};
  max-width: 370px;
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  @media all and ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const LTRow = styled(Div)`
  display: flex;
  padding: 8px 0;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    padding: 15px 0;
  }
`;

const ShareIconsWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 8px;
    margin-bottom: 4px;
  }
`;

const CampaignName = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    color: ${(props) => props.theme.colors.text};
    font-size: 15px;
    font-weight: bold;
  }
`;

const ScrollArea = styled(Div)`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 270px;
  @media all and ${(props) => props.theme.media.mobile} {
    max-height: 40vh;
  }
`;

const campaignColumnWidth = "300px";
const LinksTable = () => {
  const { shoutouts, isLoadingShoutouts } = useMessagesAppContext();
  const rows = shoutouts?.map((s) => ({
    campaign: s.data.name,
    shareLink: getShoutoutPath(s.id),
  }));

  const isMobile = useIsMobile();
  const headersUI = (
    <Div dflex mt_25 h_25 mb_7 c_gray30 fs_15 semiBold>
      {isMobile ? (
        <Div>Campaigns</Div>
      ) : (
        <>
          <LTColumn width={campaignColumnWidth}>Campaign</LTColumn>
          <LTColumn>Share Link</LTColumn>
        </>
      )}
    </Div>
  );

  const dataUI = (
    <>
      {headersUI}
      <ScrollArea>
        {rows?.map(({ campaign, shareLink }, index) => (
          <LTRow key={index}>
            <LTColumn width={campaignColumnWidth} semiBold>
              <CampaignName mr_18 truncate>
                {campaign}
              </CampaignName>
            </LTColumn>
            <LTColumn>
              <ShareLinkText
                link
                onClick={() => window.open(shareLink, "_blank")}
              >
                {shareLink}
              </ShareLinkText>
              <ShareIconsWrapper>
                <ShareIcons url={shareLink} />
              </ShareIconsWrapper>
            </LTColumn>
          </LTRow>
        ))}
      </ScrollArea>
    </>
  );
  const tableUI = (
    <>
      {rows?.length > 0 ? (
        dataUI
      ) : (
        <Div h_220 w100 centered c_gray30 fs_18>
          No campaigns available
        </Div>
      )}
    </>
  );
  return (
    <>
      {isLoadingShoutouts ? (
        <Div w100 mt_50 centered>
          <LoaderInline />
        </Div>
      ) : (
        tableUI
      )}
    </>
  );
};
