import { Shoutout } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { fstore } from "firebase-tools";
import { createContext, useContext, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

type State = {
  isNewArtistGroup: boolean;
  shoutouts: {
    id: number;
    data: Shoutout;
  }[];
  selectedShoutout: {
    id: number;
    data: Shoutout;
  };
  isLoadingShoutouts: boolean;
};
const MessagesAppContext = createContext<State>({} as State);

export const MessagesAppContextProvider = ({ children }) => {
  const { artistGroupId, shoutoutId } = useParams<any>();
  const [shoutouts, isLoadingShoutouts] = useCollection(
    fstore.collection("shoutouts").where("artistGroupId", "==", artistGroupId)
  );

  const shoutoutsData: {
    id: number;
    data: any;
  }[] = shoutouts?.docs.map((s) => {
    return {
      id: s.id,
      data: s.data(),
    };
  });

  const value = {
    isNewArtistGroup: shoutoutsData?.length === 0,
    shoutouts: shoutoutsData,
    selectedShoutout:
      shoutoutId && shoutoutsData?.find((s) => s.id === shoutoutId),
    isLoadingShoutouts,
  };
  return (
    <MessagesAppContext.Provider value={value}>
      {children}
    </MessagesAppContext.Provider>
  );
};

export const useMessagesAppContext = () => useContext(MessagesAppContext);
