import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { useEffect } from "react";
import { useUserContext } from "./UserContextProvider";
import { Login as LoginDev } from "./Login";
import Messages from "./Messages";
import CampaignForm from "./CampaignForm";
import ArtistManagement from "./ArtistManagement";
import { ArtistGroups } from "./ArtistGroups";
import User from "./User";
import AccountLogin from "./AccountLogin";
import Login from "./User/Login";
import { StripeConnectRedirect } from "./StripeConnectRedirect";

export const ROUTES = {
  LOGIN: "/user/login",
  STRIPE_CONNECT: "/stripe-connect/:artistGroupId/:redirectTo",
  ACCOUNT_LOGIN: "/account-login",
  LOGIN_DEV: "/login-max-dev",
  MESSAGES: "/messages/:artistGroupId",
  SHOUTOUT_MESSAGES: "/messages/:artistGroupId/:shoutoutId",
  NEW_CAMPAIGN_FORM: "/new-campaign/:artistGroupId",
  EDIT_CAMPAIGN_FORM: "/edit-campaign/:artistGroupId/:shoutoutId",
  ARTIST_MANAGEMENT: "/artist-management",
  USER: "/user",
  ARTISTS_GROUPS: "/",
};

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

const RouterScrollToTop = withRouter(ScrollToTop);

const ProtectedRoute = ({ path, children, exact = false }) => {
  const { isLoggedIn } = useUserContext();
  return (
    <>
      {isLoggedIn ? (
        <Route exact={exact} path={path}>
          {children}
        </Route>
      ) : (
        <Redirect to={ROUTES.LOGIN} />
      )}
    </>
  );
};

const Routes = () => {
  const { isLoggedIn } = useUserContext();

  const DefaultRoute = () => (
    <Redirect to={isLoggedIn ? ROUTES.ARTISTS_GROUPS : ROUTES.LOGIN} />
  );
  return (
    <Router>
      <RouterScrollToTop />
      <Switch>
        <Route path={ROUTES.ACCOUNT_LOGIN}>
          <AccountLogin />
        </Route>
        <Route path={ROUTES.LOGIN_DEV}>
          <LoginDev />
        </Route>
        <Route path={ROUTES.LOGIN}>
          <DefaultRoute />
          <Login />
        </Route>

        <ProtectedRoute path={ROUTES.USER}>
          <User />
        </ProtectedRoute>

        <ProtectedRoute exact path={ROUTES.STRIPE_CONNECT}>
          <StripeConnectRedirect />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.MESSAGES}>
          <Messages />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.SHOUTOUT_MESSAGES}>
          <Messages />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.NEW_CAMPAIGN_FORM}>
          <CampaignForm />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.EDIT_CAMPAIGN_FORM}>
          <CampaignForm />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.ARTIST_MANAGEMENT}>
          <ArtistManagement />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.ARTISTS_GROUPS}>
          <ArtistGroups />
        </ProtectedRoute>

        <Route path={ROUTES.USER}>
          <User />
        </Route>
        <Route component={() => <DefaultRoute />} />
      </Switch>
    </Router>
  );
};
export default Routes;
