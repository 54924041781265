var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FacebookShareButton, TwitterShareButton } from "react-share";
import styled from "styled-components";
import { Icon } from "notes";
import React, { useState } from "react";
import { Div, theme } from ".";
var ShareIcon = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  svg {\n    path {\n      fill: ", " !important;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  svg {\n    path {\n      fill: ", " !important;\n    }\n  }\n"])), function (props) { return props.fill; });
var CopiedToClipboardContainer = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: #07090f;\n  font-size: 12px;\n  padding: 5px;\n  font-weight: 600;\n  position: absolute;\n  margin-top: 5px;\n  border-radius: 4px;\n  color: white;\n"], ["\n  background-color: #07090f;\n  font-size: 12px;\n  padding: 5px;\n  font-weight: 600;\n  position: absolute;\n  margin-top: 5px;\n  border-radius: 4px;\n  color: white;\n"])));
export var CopyToClipboardShareButton = function (_a) {
    var children = _a.children, url = _a.url, _b = _a.onShareWindowClose, onShareWindowClose = _b === void 0 ? null : _b;
    var _c = useState(false), copiedToClipboard = _c[0], setCopiedToClipboard = _c[1];
    var onCopyToClipboard = function () {
        setCopiedToClipboard(true);
        setTimeout(function () {
            setCopiedToClipboard(false);
        }, 2000);
    };
    return (React.createElement(Div, { onClick: function () {
            navigator.clipboard.writeText(url);
            if (onShareWindowClose)
                onShareWindowClose();
            onCopyToClipboard();
        } },
        children,
        copiedToClipboard && (React.createElement(CopiedToClipboardContainer, null, "Link copied"))));
};
export var shareMenuItems = function (dimension) {
    if (dimension === void 0) { dimension = "16"; }
    return [
        {
            shareComponent: FacebookShareButton,
            label: "Share on Facebook",
            icon: (React.createElement(ShareIcon, { fill: theme.colors.facebook },
                React.createElement(Icon, { name: "Facebook", width: dimension, height: dimension }))),
        },
        {
            shareComponent: TwitterShareButton,
            label: "Share on Twitter",
            icon: (React.createElement(ShareIcon, { fill: theme.colors.twitter },
                React.createElement(Icon, { name: "Twitter", width: dimension, height: dimension }))),
        },
        {
            shareComponent: CopyToClipboardShareButton,
            label: "Copy Link",
            icon: (React.createElement(ShareIcon, { fill: theme.colors.text },
                React.createElement(Icon, { tertiary: true, name: "Link", width: dimension, height: dimension }))),
        },
    ];
};
export var ShareIcons = function (_a) {
    var shareUrl = _a.shareUrl;
    return (React.createElement(Div, null,
        React.createElement(Div, { dflex: true, mt_38: true, justifyEnd: true, alignCenter: true },
            React.createElement(Div, { fs_14: true, c_subheaderDefault: true }, "Share:"),
            shareMenuItems("24").map(function (mi, index) {
                var Share = mi.shareComponent;
                return (React.createElement(Div, { key: index, clickable: true },
                    React.createElement(Share, { style: { outline: "none" }, url: shareUrl },
                        React.createElement(Div, { ml_20: true }, mi.icon))));
            }))));
};
var templateObject_1, templateObject_2;
