import { useAppContext } from "components/AppContextProvider";
import { BrowserInfo, Icon, Toggle } from "notes";
import styled from "styled-components";
import { useDivTop, useWindowInnerHeight } from "../../../hooks";
import { useVideoMessageContext } from "../../Messages/VideoMessageContextProvider";
import Div from "../Div";
import { Backdrop, DropdownDiv } from "../overlay";
import { IODeviceSelector } from "./IODeviceSelector";

const autoPlayPreferenceProps = {
  header: "Auto-Play Recordings",
  text: `Turn on to allow recorded video messages to start playing after
          recording (prior to being sent) to check for quality and satisfaction.
          This will also apply to completed videos when a fan is selected.`,
};

const saveCameraPreferenceProps = {
  header: "Save camera/microphone preferences",
  text: `When turned off, Shout Out will automatically select the default camera and microphone devices for your browser.`,
};

const Dropdown = styled(DropdownDiv)`
  width: 450px;
  margin-left: -410px;
  margin-top: -15px;
  z-index: 21;
  @media all and ${(props) => props.theme.media.mobile} {
    position: fixed;
    top: 56px;
    border-radius: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: none;
    border: none;
  }
`;

const PreferenceWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    flex-direction: row-reverse;
  }
`;

const PreferencesDescription = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-right: 40px;
  }
`;

const ToggleWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const SettingsBackdrop = styled(Backdrop)`
  z-index: 20;
`;

const Preference = ({ checked, onToggle, header, text }) => {
  return (
    <Div mt_25 mb_15>
      <PreferenceWrapper spaceBetween dflex>
        <ToggleWrapper mr_13>
          <Toggle disabled={false} checked={checked} onChange={onToggle} />
        </ToggleWrapper>
        <PreferencesDescription mt_n4>
          <Div bold fs_17>
            {header}
          </Div>
          <AutoPlayText fs_11 mt_3>
            {text}
          </AutoPlayText>
        </PreferencesDescription>
      </PreferenceWrapper>
    </Div>
  );
};

const Settings = ({
  onClose,
  withAutoPlayOption = true,
  onHelpClick = null,
}) => {
  const { preferences, setPreferences } = useVideoMessageContext();
  const { setIsTroubleshootModalOpen } = useAppContext();
  const { ref, divTop } = useDivTop();
  const windowInnerHeight = useWindowInnerHeight();

  const updatePreference = (preference, value) => {
    setPreferences((prev) => {
      return { ...prev, [preference]: value };
    });
  };

  const headerUI = (
    <Div forDesktop alignCenter spaceBetween>
      <Div fs_25 bold>
        Settings
      </Div>
      <Div clickable onClick={onClose}>
        <Icon platform name="Close" />
      </Div>
    </Div>
  );

  const deviceSelectorsUI = (
    <Div forDesktop pb_20>
      <Divider mb_15 />
      <CameraAndMicText fs_14 bold>
        Camera and Microphone (Desktop Only)
      </CameraAndMicText>
      <IODeviceSelector />
    </Div>
  );

  const isSafari =
    BrowserInfo.getBrowser().name === "Safari" ||
    BrowserInfo.getBrowser().name === "Mobile Safari";
  return (
    <Div>
      <SettingsBackdrop forDesktop onClick={onClose} />
      <Dropdown
        innerHeight={windowInnerHeight}
        ref={ref}
        top={divTop}
        onClose={onClose}
      >
        <Wrapper>
          {headerUI}
          {withAutoPlayOption && (
            <Preference
              {...autoPlayPreferenceProps}
              checked={preferences.autoPlay}
              onToggle={(checked) => updatePreference("autoPlay", checked)}
            />
          )}

          {!isSafari && (
            <>
              {deviceSelectorsUI}
              <Divider />
              <Div forDesktop>
                <Link
                  onClick={() => {
                    setIsTroubleshootModalOpen(true);
                  }}
                >
                  Camera or microphone not working?
                </Link>
              </Div>
            </>
          )}
          {onHelpClick && (
            <Link forMobile onClick={onHelpClick}>
              {"Help & FAQs"}
            </Link>
          )}
          {!isSafari && (
            <Div forDesktop>
              <Preference
                {...saveCameraPreferenceProps}
                checked={preferences.saveSelectedVideoAudioDeviceIds}
                onToggle={(checked) =>
                  updatePreference("saveSelectedVideoAudioDeviceIds", checked)
                }
              />
            </Div>
          )}
        </Wrapper>
      </Dropdown>
    </Div>
  );
};

const Link = styled(Div)`
  color: #27b0ff;
  border-bottom: solid 1px #e2e6e8;
  font-weight: bold;
  padding: 12px 0;
  font-size: 17px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Divider = styled(Div)`
  background-color: #e2e6e8;
  height: 1px;
  width: 100%;
`;

const AutoPlayText = styled(Div)`
  font-style: italic;
`;

const CameraAndMicText = styled(Div)`
  color: #a6aeb2;
  font-size: 14px;
  font-weight: bold;
`;

const Wrapper = styled(Div)`
  color: #222222;
  padding: 17px 20px;
  margin-bottom: 10px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 0;
  }
`;

export default Settings;
