import {
  Div,
  FormikErrorFocus,
  Input,
  Modal as ModalBase,
  ModalWrapper,
  AppLoading,
  CheckboxMultiselect,
} from "@max/common-ui";
import { useFormikContext } from "formik";
import * as yup from "yup";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import { ButtonPrimary, ButtonSecondary } from "notes";
import { Form as FForm, Formik } from "formik";
import { ArtistGroupRoles } from "@musicaudienceexchange/shoutout-interface/lib/firestore/artists";
import { yupStringRequired } from "../common";
import { useEffect, useState } from "react";
import { shoutoutI } from "firebase-tools";
import { useArtistGroupContext } from "../../common/ArtistGroupProvider";
import { capitalize } from "utils";

export const IconWrapper = styled(Div)`
  border-radius: 100%;
  height: 36px;
  width: 36px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: ${(props) => props.theme.colors.gray05};
    cursor: pointer;
    opacity: 0.8;
    ${(props) =>
      props.isDelete &&
      css`
        background: ${(props) => props.theme.colors.red20};
        svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      `}
  }
`;

export const FormLink = styled(Div)`
  font-size: 17px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.action};
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
`;

export const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray20};
`;

const TeammateWrapper = styled(Div)`
  margin-top: 28px;
`;

const teammateTypeOptions = ArtistGroupRoles.map((a) => ({
  value: a,
  label: capitalize(a),
}));

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    max-width: 700px;
    width: 80%;
  }
`;

const teammateValidationSchema = yup.object().shape({
  name: yupStringRequired,
  email: yupStringRequired.email("Enter valid email"),
  roles: yup.array().of(yup.string()),
});

export const TeammateFormModal = ({
  isOpen,
  initialValues,
  isEdit = false,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getData, artistGroupId } = useArtistGroupContext();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [isOpen]);

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    onClose();
    const { name, email, roles } = values;
    await shoutoutI.functions().artists.updateArtistGroupMember({
      groupId: artistGroupId,
      member: {
        email,
        name,
        roles,
      },
    });
    await getData();
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <AppLoading />}
      <Modal isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={teammateValidationSchema}
          enableReinitialize
        >
          {() => (
            <FForm>
              <Div fs_24 semiBold>
                {`${isEdit ? "Edit" : "Add"} Teammate`}
              </Div>
              <TeammateFields {...{ isEdit, onClose }} />
              <FormikErrorFocus />
            </FForm>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const TeammateFields = ({ isEdit = false, onClose = null }) => {
  const { submitForm } = useFormikContext<any>();

  const createButtonsUI = !isEdit && (
    <Div dflex mt_40>
      <Div mr_25>
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
      </Div>
      <ButtonPrimary onClick={submitForm}>Send Invitation</ButtonPrimary>
    </Div>
  );

  const editButtonsUI = isEdit && (
    <Div dflex mt_35>
      <FormLink mr_60 onClick={onClose}>
        Cancel
      </FormLink>
      <FormLink onClick={submitForm}>Save</FormLink>
    </Div>
  );

  return (
    <TeammateWrapper>
      <Input
        disabled={isEdit}
        name="name"
        width="100%"
        placeholder="First and last name..."
      />
      <Div dflex mt_25>
        <Div w100>
          <Input
            disabled={isEdit}
            name="email"
            leftIcon={
              <Div mt_n2>
                <Icon form name="Email" />
              </Div>
            }
            width="100%"
            placeholder="Email address..."
          />
        </Div>
      </Div>
      <Div fs_17 bold c_text mt_30 mb_10>
        Roles
      </Div>
      <Div mb_15>
        <CheckboxMultiselect name="roles" options={teammateTypeOptions} />
      </Div>
      {isEdit ? editButtonsUI : createButtonsUI}
    </TeammateWrapper>
  );
};
