import { theme } from "@max/common-ui";

theme.media = { ...theme.media, mobile: "(max-width: 860px)" };
theme.media = { ...theme.media, desktop: "(min-width: 861px)" };
theme.media = { ...theme.media, verticalDisplay: "(max-width: 1279px)" };
theme.media = {
  ...theme.media,
  verticalDisplaySmall: "(max-height: 980px) and (max-width: 1279px)",
};

export { theme };
