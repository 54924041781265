import { useEffect, useRef, useState } from "react";
import { Icon } from "notes";
import styled from "styled-components";
import Div from "../../../../common/Div";
import Meter from "../Meter";

let mediaRecorder;
let audioContext;
let stream;
let micNode;
let scriptNode;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isInlineRecordingSupported =
  !!window.MediaSource &&
  !!window.MediaRecorder &&
  !!navigator.mediaDevices &&
  !isSafari;

/* connectAudioNodes and disconnectAudioNodes prevent
state updates on unmounted component with setVolumeLevel */
const connectAudioNodes = () => {
  micNode.connect(scriptNode);
  scriptNode.connect(audioContext.destination);
};

const disconnectAudioNodes = () => {
  micNode.disconnect(scriptNode);
  scriptNode.disconnect(audioContext.destination);
};

const getMimeType = () => {
  let mimeType = "video/webm;codecs=vp9,opus";
  if (!MediaRecorder.isTypeSupported(mimeType)) {
    console.error(`${mimeType} is not supported`);
    mimeType = "video/webm;codecs=vp8,opus";
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      console.error(`${mimeType} is not supported`);
      mimeType = "video/webm";
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        console.error(`${mimeType} is not supported`);
        mimeType = "video/mp4; codecs=avc1.4d002a";
        if (!MediaRecorder.isTypeSupported(mimeType)) {
          console.error(`${mimeType} is not supported`);
          mimeType = "";
        }
      }
    }
  }
  return mimeType;
};

const useStopwatch = () => {
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const startStopwatch = () => {
    setSeconds(0);
    const iid = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);
    setIntervalId(iid);
  };
  const stopStopwatch = () => {
    if (intervalId) clearInterval(intervalId);
  };
  const format_mmss = () => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    const pad0 = (t) => (t < 10 ? `0${t}` : t);
    const mm = pad0(m);
    const ss = pad0(s);
    return `${mm}:${ss}`;
  };
  return {
    startStopwatch,
    stopStopwatch,
    seconds,
    stopwatch: format_mmss(),
  };
};

const getDeviceIdsByKind = (devices, kind) => {
  const dvsbyKind = devices?.filter((device) => device.kind === kind);
  return dvsbyKind?.map((inp) => inp.deviceId);
};

const useMediaDevices = () => {
  const [devices, setDevices] = useState(null);

  const getDevices = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      const dvs = await navigator.mediaDevices.enumerateDevices();
      setDevices(dvs);
      return dvs;
    } catch (err) {
      setDevices(null);
      throw err;
    }
  };

  const getValidDeviceIds = async (
    idealAudioDeviceId = null,
    idealVideoDeviceId = null
  ) => {
    let dvs;
    if (!devices) {
      dvs = await getDevices();
      navigator.mediaDevices.addEventListener("devicechange", getDevices);
    } else dvs = devices;

    const audioDeviceIds = getDeviceIdsByKind(dvs, "audioinput");
    const videoDeviceIds = getDeviceIdsByKind(dvs, "videoinput");
    const defaultAudioDeviceId = audioDeviceIds[0];
    const defaultVideoDeviceId = videoDeviceIds[0];

    const selectedDeviceIds = {
      audioDeviceId: audioDeviceIds.includes(idealAudioDeviceId)
        ? idealAudioDeviceId
        : defaultAudioDeviceId,
      videoDeviceId: videoDeviceIds.includes(idealVideoDeviceId)
        ? idealVideoDeviceId
        : defaultVideoDeviceId,
    };
    return selectedDeviceIds;
  };

  useEffect(() => {
    return () => {
      if (devices)
        navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);

  return {
    devices,
    getValidDeviceIds,
  };
};

const COUNT_DOWN_SECONDS = 3;

const VideoRecorder = ({
  width = "350px",
  height = "620px",
  constraints = {},
  initializeOnMount = false,
  withVolumeLevel = true,
  onStartCountDown = null,
  onCancelCountDown = null,
  onStartRecording = null,
  onStopRecording = null,
  onTroubleshoot = null,
  onError = null,
  onInit = null,
  onMounting = null,
  selectedAudioDeviceId = null,
  selectedVideoDeviceId = null,
  setSelectedAudioDeviceId,
  setSelectedVideoDeviceId,
  setDevices = null,
}) => {
  const videoRef = useRef(null);
  const startRecordingTimeoutId = useRef(null);
  const countDownIntervalId = useRef(null);
  const videoInputRef = useRef(null);
  const isMounted = useRef(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [missingCameraOrMic, setMissingCameraOrMic] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [countDown, setCountDown] = useState(0);
  const { seconds, stopwatch, startStopwatch, stopStopwatch } = useStopwatch();
  const { devices, getValidDeviceIds } = useMediaDevices();
  const [shouldTroubleshoot, setShouldTroubleshoot] = useState(false);

  const handleError = (err) => {
    console.log(err);
    setShouldTroubleshoot(true);
    clearTimers();
    if (onStopRecording) onStopRecording();
    if (onCancelCountDown) onCancelCountDown();
    setIsCameraOpen(false);
    setIsRecording(false);
    setMissingCameraOrMic(true);
    if (onError) onError("Missing camera or microphone");
  };

  const initMediaRecoder = async () => {
    const setupAudio = () => {
      audioContext = new AudioContext();
      scriptNode = audioContext.createScriptProcessor(2048, 1, 1);
      scriptNode.onaudioprocess = (event) => {
        const input = event.inputBuffer.getChannelData(0);
        let i;
        let sum = 0.0;
        let clipcount = 0;
        for (i = 0; i < input.length; ++i) {
          sum += input[i] * input[i];
          if (Math.abs(input[i]) > 0.99) {
            clipcount += 1;
          }
        }
        const instant = Math.sqrt(sum / input.length);
        setVolumeLevel(instant);
      };
      micNode = audioContext.createMediaStreamSource(stream);
      connectAudioNodes();
    };

    try {
      const { audioDeviceId, videoDeviceId } = await getValidDeviceIds(
        selectedAudioDeviceId,
        selectedVideoDeviceId
      );

      const defaultVideoConstraints = {
        deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined,
        facingMode: "user",
      };

      const preferredVideoConstraints = {
        ...defaultVideoConstraints,
        width: {
          min: 640,
          ideal: 1280,
          max: 1920,
        },
        height: {
          min: 480,
          ideal: 720,
          max: 1280,
        },
        frameRate: {
          min: 15,
        },
      };

      const mediaConstraints = {
        audio: {
          deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined,
        },
        ...constraints,
      };

      // updating video width and height causes performance issues on chrome android
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          ...mediaConstraints,
          video: preferredVideoConstraints,
        });
      } catch (err) {
        stream = await navigator.mediaDevices.getUserMedia({
          ...mediaConstraints,
          video: defaultVideoConstraints,
        });
      }
      setSelectedAudioDeviceId(audioDeviceId);
      setSelectedVideoDeviceId(videoDeviceId);
      if (videoRef && videoRef.current) videoRef.current.srcObject = stream;
      setupAudio();
      setIsCameraOpen(true);
      setMissingCameraOrMic(false);
    } catch (err) {
      handleError(err);
    }
  };

  const clearTimers = () => {
    if (countDownIntervalId.current) clearInterval(countDownIntervalId.current);
    if (startRecordingTimeoutId.current)
      clearTimeout(startRecordingTimeoutId.current);
    setCountDown(0);
  };

  const stopMedia = async () => {
    if (isInlineRecordingSupported && stream)
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    if (audioContext && audioContext.state === "running")
      await audioContext.close();
  };

  useEffect(() => {
    isMounted.current = true;
    if (initializeOnMount) init();
    if (onMounting) onMounting();
    return () => {
      clearTimers();
      stopMedia().then(() => {
        isMounted.current = false;
      });
    };
  }, []);

  useEffect(() => {
    if (seconds > 600) stopRecording();
  }, [seconds]);

  useEffect(() => {
    if (countDown < 1 && countDownIntervalId.current)
      clearInterval(countDownIntervalId.current);
  }, [countDown]);

  useEffect(() => {
    const reset = async () => {
      await stopMedia();
      await initMediaRecoder();
    };

    const verifySelectedDevices = () => {
      const audioDeviceIds = getDeviceIdsByKind(devices, "audioinput");
      const videoDeviceIds = getDeviceIdsByKind(devices, "videoinput");
      const disconnectedSelectedDevice =
        !audioDeviceIds?.includes(selectedAudioDeviceId) ||
        !videoDeviceIds?.includes(selectedVideoDeviceId);
      if (isRecording && disconnectedSelectedDevice && onError)
        onError(
          "Missing camera or microphone. Please discard recording and try again"
        );
    };

    verifySelectedDevices();
    setDevices(devices);
    clearTimers();
    if (stream && !isRecording && isCameraOpen) reset();
  }, [devices, selectedAudioDeviceId, selectedVideoDeviceId]);

  const startRecording = () => {
    setCountDown(COUNT_DOWN_SECONDS);
    if (onStartCountDown) onStartCountDown();
    const iId = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);
    countDownIntervalId.current = iId;
    const sTId = setTimeout(() => {
      try {
        const mimeType = getMimeType();
        const onEndRecording = async (event) => {
          if (event.data && event.data.size > 0) {
            const blob = new Blob([event.data], { type: mimeType });
            onStopRecording(blob);
          }
        };
        const options = { mimeType };
        mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorder.ondataavailable = onEndRecording;
        mediaRecorder.start();
        connectAudioNodes();
        startStopwatch();
        if (onStartRecording) onStartRecording();
        setIsRecording(true);
        setMissingCameraOrMic(false);
      } catch (err) {
        handleError(err);
      }
    }, COUNT_DOWN_SECONDS * 1000);
    startRecordingTimeoutId.current = sTId;
  };

  const stopRecording = () => {
    stopStopwatch();
    disconnectAudioNodes();
    mediaRecorder.stop();
  };

  const cancelCountDown = () => {
    if (onCancelCountDown) onCancelCountDown();
    clearTimers();
  };

  const init = async () => {
    if (isInlineRecordingSupported) {
      await initMediaRecoder();
      if (onInit) onInit();
    } else {
      videoInputRef.current.value = null;
      videoInputRef.current.click();
    }
    setIsRecording(false);
  };

  const handleVideoSelected = (e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (files.length === 0) return;
    const blob = files[0];
    e.target.value = null;
    onStopRecording(blob);
  };

  const videoInput = (
    <VideoInput
      ref={videoInputRef}
      type="file"
      accept="video/*"
      capture="user"
      onChange={handleVideoSelected}
    />
  );

  const recordingAction = !isRecording ? (
    <Button onClick={startRecording}>
      <RecordIcon />
      <RecordingActionText>Start Recording</RecordingActionText>
    </Button>
  ) : (
    <StopRecordingButton onClick={stopRecording}>
      <StopIcon />
      <RecordingActionText>End Recording</RecordingActionText>
    </StopRecordingButton>
  );

  const getReadyUI = (
    <GetReadyContainer>
      <GetReadyBackground />
      <Div w100 h100 positionAbsolute>
        <GetReadyContent> GET READY!</GetReadyContent>
        <GetReadyCountdown> {countDown}</GetReadyCountdown>
        <GetReadyCancel onClick={cancelCountDown}>Cancel</GetReadyCancel>
      </Div>
    </GetReadyContainer>
  );

  const troubleshootUI = (
    <VideoControls>
      <TroubleshootText>
        Please enable camera and microphone access in your web browser’s
        settings.
      </TroubleshootText>
      <TroubleshootButton
        onClick={() => {
          if (onTroubleshoot) onTroubleshoot();
        }}
      >
        <Icon name="Gear" />
        <Div ml_4>Troubleshoot</Div>
      </TroubleshootButton>
    </VideoControls>
  );

  const videoControlsUI = (
    <VideoControls>
      {isRecording && <Stopwatch centered> {stopwatch}</Stopwatch>}
      {countDown > 0 ? (
        getReadyUI
      ) : (
        <>
          {isCameraOpen ? (
            recordingAction
          ) : (
            <>
              {(!initializeOnMount ||
                missingCameraOrMic ||
                !isInlineRecordingSupported) && (
                <>
                  <CameraButton onClick={init}>
                    <Icon name="Video" />
                    <Div ml_7>Use Camera</Div>
                  </CameraButton>
                </>
              )}
            </>
          )}
        </>
      )}
    </VideoControls>
  );
  return (
    <>
      <WorkSansFont>ws</WorkSansFont>
      <Wrapper positionRelative width={width} height={height}>
        {videoInput}
        {videoControlsUI}
        {shouldTroubleshoot && troubleshootUI}
        {isInlineRecordingSupported && (
          <>
            <Video ref={videoRef} muted playsInline autoPlay />
            {isRecording && withVolumeLevel && (
              <VolumeMeterWrapper dflex mt_5>
                <Meter level={volumeLevel * 100} />
              </VolumeMeterWrapper>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

const WorkSansFont = styled(Div)`
  position: absolute;
  opacity: 0;
  font-weight: 700;
  font-family: Work Sans;
`;

const VolumeMeterWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    margin-top: -7px;
  }
`;

const GetReadyContent = styled(Div)`
  margin-top: 130px;
  font-size: 35px;
  font-weight: 700;
`;

const GetReadyCountdown = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 86px;
`;

const GetReadyCancel = styled.div`
  margin-top: 90px;
  font-weight: 700;
  font-size: 22px;
  user-select: none;
  font-family: Overpass, sans-serif;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const GetReadyContainer = styled(Div)`
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    position: fixed;
    top: 0;
    left: 0;
  }
  height: 100%;
  width: 100%;
  color: white;
  font-family: Work Sans;
`;

const GetReadyBackground = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0.6;
  height: 100%;
  width: 100%;
`;

const Stopwatch = styled(Div)`
  border-radius: 30px;
  height: 40px;
  position: absolute;
  top: 30px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    top: 14px;
    height: 28px;
    font-size: 14px;
  }
  width: 80px;
  font-weight: bold;
  font-family: Roboto, sans-serif;
  background-color: #120f0b;
  color: white;
  font-size: 17px;
  opacity: 0.7;
`;

const RecordIconWrapper = styled.div`
  height: 14px;
  width: 14px;
  border: solid 2px #df534b;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    height: 20px;
    width: 20px;
  }
`;

const RedCircle = styled.div`
  width: 100%;
  height: 12px;
  width: 12px;
  margin: 0;
  padding: 0;
  background-color: #df534b;
  border-radius: 100%;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    border: solid 2px #df534b;
    height: 12px;
    width: 12px;
  }
`;

const RecordIcon = () => (
  <RecordIconWrapper>
    <RedCircle />
  </RecordIconWrapper>
);

const CameraButton = styled.div`
  color: white;
  font-weight: 500;
  font-size: 17px;
  width: 140px;
  svg {
    path {
      fill: white;
    }
  }
  background-color: ${(props) => props.theme.colors.blue20};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  user-select: none;
  position: absolute;
  bottom: 50px;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    bottom: 28px;
  }
`;

const TroubleshootText = styled(Div)`
  color: white;
  padding: 20px;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    padding: 0px;
    margin-top: -120px;
  }
`;

const TroubleshootButton = styled(CameraButton)`
  color: #1f1f1f;
  background: white;
  bottom: 105px;
  ${Icon} {
    svg {
      path {
        fill: #1f1f1f;
      }
    }
  }
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    bottom: 82px;
  }
`;

const Wrapper = styled(Div)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background-color: #252a3c;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    background-color: black;
  }
`;

const VideoInput = styled.input`
  display: none;
`;

const Button = styled(Div)`
  font-family: Overpass, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 43px;
  width: 190px;
  background-color: white;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  border-radius: 35px;
  font-weight: 600;
  font-size: 17px;
  user-select: none;
  position: absolute;
  bottom: 33px;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    bottom: 12px;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
  }
  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const RecordingActionText = styled(Div)`
  margin-left: 9px;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    display: none;
  }
`;

const StopRecordingButton = styled(Button)`
  background-color: #df534b;
  color: white;
  :hover {
    background-color: #831713;
  }
`;

const StopIcon = styled.div`
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 3px;
  @media all and ${(props) => props.theme.media.verticalDisplaySmall} {
    height: 21px;
    width: 21px;
  }
`;

const VideoControls = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Video = styled.video`
  outline: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export default VideoRecorder;
