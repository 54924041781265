import { Div, Modal } from "@max/common-ui";
import { useUserContext } from "components/UserContextProvider";
import { fb } from "firebase-tools";
import React, { useState } from "react";
import styled from "styled-components";

const Meter = styled("meter")`
  width: 100%;
`;

const uploadBlob = async (userId, blob, setUploadProgress = null, label) => {
  let promiseResolve;
  const promise = new Promise((resolve) => (promiseResolve = resolve));

  const mimeType = blob.type;
  const ext = mimeType.split(";")[0].split("/")[1];

  const storageRef = fb
    .storage()
    .ref(
      `/shoutout_media/${userId}/${Math.random()
        .toString(36)
        .substring(2)}.${ext}`
    );
  const task = storageRef.put(blob);

  task.on(
    "state_changed",
    (s) => setUploadProgress((s.bytesTransferred / s.totalBytes) * 100),
    () => console.log("uploadError"),
    async () =>
      promiseResolve({ url: await task.snapshot.ref.getDownloadURL(), label })
  );

  return promise;
};

export const useBlobUploads = () => {
  const [isUploadProgressModalOpen, setIsUploadProgressModalOpen] = useState(
    false
  );
  const [desktopImgUploadProgress, setDesktopImgUploadProgress] = useState(0);
  const [mobileImgUploadProgress, setMobileImgUploadProgress] = useState(0);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [isUploadingDesktopImg, setIsUploadingDesktopImg] = useState(false);
  const [isUploadingMobileImg, setIsUploadingMobileImg] = useState(false);
  const { user } = useUserContext();

  const uploadBlobs = async (desktopImg, mobileImg, recordingBlob) => {
    setIsUploadProgressModalOpen(true);
    const promises = [];
    if (desktopImg) {
      promises.push(
        uploadBlob(
          user.uid,
          desktopImg,
          setDesktopImgUploadProgress,
          "desktopImgUrl"
        )
      );
      setIsUploadingDesktopImg(true);
    }
    if (mobileImg) {
      promises.push(
        uploadBlob(
          user.uid,
          mobileImg,
          setMobileImgUploadProgress,
          "mobileImgUrl"
        )
      );
      setIsUploadingMobileImg(true);
    }
    if (recordingBlob) {
      promises.push(
        uploadBlob(user.uid, recordingBlob, setVideoUploadProgress, "videoUrl")
      );
      setIsUploadingVideo(true);
    }
    const urls = await Promise.all(promises);
    setIsUploadProgressModalOpen(false);
    setIsUploadingVideo(false);
    setIsUploadingDesktopImg(false);
    setIsUploadingMobileImg(false);

    return urls.reduce((obj, curr) => ({ ...obj, [curr.label]: curr.url }), {});
  };

  const UploadProgress = ({ label, progress }) => {
    return (
      <Div fs_14 c_text mt_30 mb_10 semibold>
        {label}:
        <Div bold centered mt_5>
          <Meter value={progress} min="0" max="100" />
        </Div>
      </Div>
    );
  };

  const progressModalUI = (
    <>
      <Modal
        isOpen={isUploadProgressModalOpen}
        withBackdropClose={false}
        withCloseIcon={false}
      >
        <Div mb_20>
          <Div bold fs_16>
            Uploading...
          </Div>
          {isUploadingVideo && (
            <UploadProgress
              label="Promotional Video"
              progress={videoUploadProgress}
            />
          )}
          {isUploadingDesktopImg && (
            <UploadProgress
              label="Header image (desktop)"
              progress={desktopImgUploadProgress}
            />
          )}
          {isUploadingMobileImg && (
            <UploadProgress
              label="Header image (mobile)"
              progress={mobileImgUploadProgress}
            />
          )}
        </Div>
      </Modal>
    </>
  );

  return {
    progressModalUI,
    uploadBlobs,
  };
};
