import { AppContainer, AppLayout, Div, Footer } from "@max/common-ui";
import { ShoutoutLogo } from "components/common/ShoutoutLogo";
import styled from "styled-components";

const Header = () => {
  return (
    <Div centered mt_40>
      <ShoutoutLogo />
    </Div>
  );
};

const Container = styled(Div)`
  width: 400px;
  border: 1px solid #a6aeb2;
  border-radius: 8px;
  margin-top: 56px;
  padding: 32px 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 40px;
    padding: 0;
    border: none;
  }
`;

const Title = styled(Div)`
  font-weight: 800;
  font-size: 25px;
`;

const Description = styled(Div)`
  font-size: 17px;
`;

export const SectionHeader = ({ title, description }) => {
  return (
    <Div c_text>
      <Title>{title}</Title>
      <Description lite>{description}</Description>
    </Div>
  );
};

export const Field = ({ label, value }) => {
  return (
    <Div>
      <Div fs_15 c_gray20>
        {label}
      </Div>
      <Div fs_17 c_text>
        {value}
      </Div>
    </Div>
  );
};

export const Layout = ({ children }) => {
  return (
    <AppLayout>
      <Header />
      <AppContainer>
        <Div centered mcontainer>
          <Container>{children}</Container>
        </Div>
      </AppContainer>
      <Div mt_50>
        <Footer background={"white"} />
      </Div>
    </AppLayout>
  );
};
