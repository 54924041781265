import React from "react";
import styled from "styled-components";
import ChromeDefault from "Images/ChromeDefault.png";
import FirefoxDefault from "Images/FirefoxDefault.png";
import EdgeDefault from "Images/EdgeDefault.png";

export const ImageLogo = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  flex-shrink: 0;
  margin-right: 12px;
`;

const imagePath = `${process.env.REACT_APP_ASSETS_PATH}/troubleshoot/windows`;

export const windows = [
  {
    key: "chrome",
    name: "Chrome",
    logo: <ImageLogo src={ChromeDefault} />,
    data: [
      {
        type: "text",
        content: "Allow Chrome access to Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/1.png`,
      },
      {
        type: "text",
        content: "Or, click the lock icon and choose Site settings",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/2.png`,
      },
      {
        type: "text",
        content:
          "Select Allow under the drop-downs to allow access to your Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/chrome/3.png`,
      },
      {
        type: "text",
        content: "Refresh website",
      },
    ],
  },
  {
    key: "firefox",
    name: "Firefox",
    logo: <ImageLogo src={FirefoxDefault} />,
    data: [
      {
        type: "text",
        content: "Allow Firefox access to your Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/1.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/2.png`,
      },
      {
        type: "image",
        content: `${imagePath}/firefox/3.png`,
      },
      {
        type: "text",
        content: "Or, click the lock icon and then the arrow to the right",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/4.png`,
      },
      {
        type: "text",
        content: "Click More Information to access additional settings",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/5.png`,
      },
      {
        type: "text",
        content: "Click Permissions tab and scroll to bottom",
      },
      {
        type: "text",
        content:
          "Select Allow to allow Firefox access to both your Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/firefox/6.png`,
      },
    ],
  },
  {
    key: "edge",
    name: "Edge",
    logo: <ImageLogo src={EdgeDefault} />,
    data: [
      {
        type: "text",
        content: "Allow Edge access to Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/edge/1.png`,
      },
      {
        type: "text",
        content: "Or, click the lock icon and choose Site permissions",
      },
      {
        type: "image",
        content: `${imagePath}/edge/2.png`,
      },
      {
        type: "text",
        content:
          "Select Allow under the drop-downs to allow access to both your Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/edge/3.png`,
      },
      {
        type: "text",
        content: "Refresh website",
      },
      {
        type: "image",
        content: `${imagePath}/edge/4.png`,
      },
    ],
  },
];
