import { AppContainer, Div } from "@max/common-ui";
import { ROUTES } from "components/Routes";
import { fstore } from "firebase-tools";
import { Icon } from "notes";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { generatePath, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const CreateCampaignButton = styled(Div)`
  svg {
    path {
      fill: #7c60d9;
    }
  }
  font-size: 20px;
  font-weight: bold;
  margin-top: 45px;
  color: ${(props) => props.theme.colors.text};
  background-color: white;
  padding: 15px 0px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 392px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    color: ${(props) => props.theme.colors.action};
    font-size: 20px;
    padding: 9px 0;
    max-width: 280px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
  }
`;

const Header = styled(Div)`
  color: ${(props) => props.theme.colors.white};
  font-size: bold;
  max-width: 600px;
  font-size: 40px;
  font-family: Work Sans;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 44px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    max-width: 300px;
    font-size: 30px;
    line-height: 33px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Description = styled(Div)`
  max-width: 600px;
  font-size: 25px;
  color: ${(props) => props.theme.colors.gray20};
  line-height: 39px;
  font-weight: bold;
  margin-top: 20px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    font-size: 22px;
    line-height: 26px;
    max-width: 300px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    line-height: 27px;
    font-size: 17px;
  }
`;

const Container = styled(Div)`
  margin-top: 72px;
  margin-left: 30px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 55px;
  }
`;

const Wrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const NewArtistGroupMessage = () => {
  const { artistGroupId } = useParams<any>();
  const [artistGroup, isLoadingartistGroup]: any = useDocumentData(
    fstore.doc(`artist_groups/${artistGroupId}`)
  );
  return (
    <>
      {!isLoadingartistGroup && (
        <NewArtistGroupMessageWithData artistGroupName={artistGroup.name} />
      )}
    </>
  );
};

export const NewArtistGroupMessageWithData = ({ artistGroupName }) => {
  const { artistGroupId } = useParams<any>();
  const history = useHistory();
  return (
    <Container>
      <AppContainer>
        <Wrapper>
          <Header>{`Let's Get Started by Creating your First Campaign for ${artistGroupName}`}</Header>
          <Description>
            {`Each campaign you create can have its own price, restrictions, and artwork.`}
          </Description>
          <CreateCampaignButton
            onClick={() =>
              history.push(
                generatePath(ROUTES.NEW_CAMPAIGN_FORM, { artistGroupId })
              )
            }
          >
            <Icon form name="Description" height="21px" />
            <Div ml_13>Create Campaign</Div>
          </CreateCampaignButton>
        </Wrapper>
      </AppContainer>
    </Container>
  );
};
