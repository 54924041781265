import {
  Div,
  ErrorMessageWarning,
  FormikErrorFocus,
  Input,
} from "@max/common-ui";
import { Field, SectionHeader } from "./common";
import { Button as ButtonBase, Icon } from "notes";
import { Form as FForm, Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useUserContext } from "components/UserContextProvider";

const Button = styled(ButtonBase)`
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;
`;

const initialValues = {
  password: "",
  confirmPassword: "",
};
const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");

const validationSchema = yup.object().shape({
  password: yupRequired.min(6, "Password is too short."),
  confirmPassword: yupRequired.oneOf(
    [yup.ref("password"), null],
    "Passwords do not match"
  ),
});

const Label = styled(Div)`
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  margin-bottom: 8px;
`;

const AccountSetup = () => {
  const history = useHistory();
  const { user } = useUserContext();

  const onSubmitForm = async ({ password }) => {
    await user.updatePassword(password);
    history.push("/");
  };

  const userDataUI = (
    <Div mt_35>
      <Div mt_24>
        <Field label="Email" value={user.email} />
      </Div>
    </Div>
  );

  const fieldsUI = (
    <>
      <Label mt_28>Password</Label>
      <Input
        leftIcon={
          <Div mt_n1>
            <Icon form name="Key" />
          </Div>
        }
        width="100%"
        type="password"
        name="password"
        placeholder="Choose a new password..."
      />
      <Div lite c_text italic fs_11 mt_8>
        Password must contain at least six characters
      </Div>
      <Label mt_35>Confirm Password</Label>
      <Input
        leftIcon={
          <Div mt_n1>
            <Icon form name="Key" />
          </Div>
        }
        width="100%"
        type="password"
        name="confirmPassword"
        placeholder="Passwords must match..."
      />
    </>
  );

  return (
    <Div>
      <SectionHeader
        title="Account Set-Up"
        description="In oculis quidem faciunt, ut de utilitatibus, nihil molestiae consequatur."
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm }) => (
          <FForm>
            <Div mt_30>
              <ErrorMessageWarning />
            </Div>
            {userDataUI}
            {fieldsUI}
            <FormikErrorFocus />
            <Button type="primary" onClick={submitForm}>
              Create Acount
            </Button>
          </FForm>
        )}
      </Formik>
    </Div>
  );
};

export default AccountSetup;
