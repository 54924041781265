var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Div } from "./Div";
import { scrollBarCss } from "./layout";
export var SelectMenuBackdrop = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  display: flex;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  display: flex;\n"])));
export var SelectMenuDiv = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  margin-top: 4px;\n  max-height: 250px;\n  z-index: 10000000000;\n  ", ";\n"], ["\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  margin-top: 4px;\n  max-height: 250px;\n  z-index: 10000000000;\n  ", ";\n"])), scrollBarCss);
export var SelectMenu = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, _d = _a.onClose, onClose = _d === void 0 ? null : _d, children = _a.children;
    return (React.createElement(React.Fragment, null, isOpen && (React.createElement(Div, { positionRelative: true, className: className },
        React.createElement(SelectMenuBackdrop, { onClick: onClose }),
        React.createElement(SelectMenuDiv, null, children)))));
};
var templateObject_1, templateObject_2;
