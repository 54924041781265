import { Div, overlay, SetLogo } from "@max/common-ui";
import { useAppContext } from "components/AppContextProvider";
import FullScreenModal from "components/common/FullScreenModal";
import Settings from "components/common/Settings";
import { useMessagesAppContext } from "components/Messages/MessagesAppContextProvider";
import { Icon, Link } from "notes";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import shoutoutLogoSVG from "../../../../assets/shoutout-logo.svg";
import { useMediaQuery } from "../../../../hooks";
import { theme } from "../../../../theme";
import DiscardRercordingModal from "../../../common/DiscardRecordingModal";
import UserMenu from "../../../common/UserMenu";
import { TABS, useMessagesContext } from "../../MessagesContextProvider";
import { useVideoMessageContext } from "../../VideoMessageContextProvider";
import HelpModal from "./Help";
import { MyLinksModal } from "./MyLinksModal";
import SearchBox from "./SearchBox";
const {
  DropdownDiv: DropdownDivBase,
  MenuItem,
  Backdrop,
  ModalContainer,
} = overlay;

const DropdownDiv = styled(DropdownDivBase)`
  margin-left: -120px;
  margin-top: 40px;
  width: 150px;
`;

export const HeaderWrapper = styled(Div)`
  height: 32px;
  margin-bottom: 10px;
  ${(props) =>
    props.isNewArtistGroup &&
    css`
      height: 72px;
      margin-bottom: 0;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-bottom: 0;
    height: 56px;
  }
`;

const HelpIconText = styled(Div)`
  border-bottom: solid 2px transparent;
`;

const SearchModalWrapper = styled(Div)`
  width: 472px;
  background: ${(props) => props.theme.colors.white};
  padding: 32px 40px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  @media all and ${(props) => props.theme.media.mobile} {
    width: 70%;
  }
`;

export const HelpButton = styled(Div)`
  margin-left: 25px;
  svg {
    path {
      fill: white;
    }
  }
  user-select: none;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 14px;
  }
  :hover {
    cursor: pointer;
    opacity: 0.9;
    ${HelpIconText} {
      border-bottom: solid 2px white;
    }
  }
`;

export const DefaultHeader = styled(Div)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media all and ${(props) => props.theme.media.mobile} {
    ${(props) =>
      props.isMessageSelected &&
      css`
        display: none;
      `}
  }
`;

const ShoutoutLogoImg = styled.img`
  height: 30px;
  margin-right: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    height: 25px;
    margin-right: 15px;
  }
`;

export const SelectedMessageHeader = styled(Div)`
  color: #222222;
  font-weight: bold;
  font-size: 17px;
  ${(props) =>
    !props.isMessageSelected &&
    css`
      display: none;
    `}
  @media all and ${(props) => props.theme.media.desktop} {
    display: none;
  }
`;

export const BackIcon = styled(Div)`
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const MoreIcon = styled(Div)`
  transform: rotate(90deg);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const LogoSection = styled(Div)`
  user-select: none;
  :hover {
    cursor: pointer;
  }
`;

const MyLinks = styled(Div)`
  font-weight: bold;
  margin-top: 1px;
  margin-right: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 13px;
    font-weight: bold;
    margin-right: 8px;
  }
`;

const SearchIcon = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-right: 9px;
  }
`;

export const Header = () => {
  const {
    selectedMessage,
    selectMessage,
    isMountingVideoMessageUI,
    userInitializedCamera,
    selectedTab,
  } = useMessagesContext();
  const { isNewArtistGroup } = useMessagesAppContext();
  const {
    userIsRecordingVideo,
    setUserIsRecordingVideo,
  } = useVideoMessageContext();
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const isMessageSelected = !!selectedMessage;
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [isMyLinksModalOpen, setIsMyLinksModalOpen] = useState(false);
  const history = useHistory();

  const isMobile = useMediaQuery(theme.media.mobile);
  useEffect(() => {
    setIsHelpModalOpen(false);
  }, [isMobile]);

  const goBack = () => {
    if (
      selectedTab === TABS.QUEUE &&
      isMountingVideoMessageUI &&
      userInitializedCamera
    )
      return;
    if (userIsRecordingVideo) setIsDiscardRecordingModalOpen(true);
    else selectMessage(null);
  };

  const helpUI = (
    <>
      {isHelpModalOpen && (
        <HelpModal onClose={() => setIsHelpModalOpen(false)} />
      )}
    </>
  );

  const logoUI = (
    <LogoSection dflex alignCenter onClick={() => history.push("/")}>
      <Div forDesktop>
        <ShoutoutLogoImg src={shoutoutLogoSVG} />
      </Div>
      <Div forMobile mt_10 ml_2>
        <ShoutoutLogoImg src={shoutoutLogoSVG} width="100px" />
      </Div>
      <Div mb_n2 forDesktop>
        <Div fs_10 mb_n2>
          Powered by
        </Div>
        <Div>
          <SetLogo width="31px" height="16px" />
        </Div>
      </Div>
    </LogoSection>
  );

  const helpButtonUI = (
    <>
      {helpUI}
      <HelpButton
        alignCenter
        onClick={() => {
          setIsHelpModalOpen(true);
        }}
      >
        <Div mr_8 alignCenter>
          <Icon indicator name="Questions" />
        </Div>
      </HelpButton>
    </>
  );

  const searchUI = (
    <>
      <SearchModal
        isOpen={isSearchBoxOpen}
        onClose={() => setIsSearchBoxOpen(false)}
      />
      <SearchIcon svg_white clickable centered h100 w_40 mr_25>
        <Div onClick={() => setIsSearchBoxOpen(true)} centered>
          <Icon name="Search" />
        </Div>
      </SearchIcon>
    </>
  );

  const headerIconsUI = (
    <Div dflex centered>
      {!isNewArtistGroup && (
        <>
          <MyLinksModal
            isOpen={isMyLinksModalOpen}
            onClose={() => setIsMyLinksModalOpen(false)}
          />
          <MyLinks link onClick={() => setIsMyLinksModalOpen(true)}>
            My Links
          </MyLinks>
          {searchUI}
        </>
      )}
      <UserMenu />
      {helpButtonUI}
    </Div>
  );

  const selectedMessageHeaderUI = (
    <>
      {isMessageSelected && (
        <SelectedMessageHeader
          alignCenter
          isMessageSelected={isMessageSelected}
          spaceBetween
        >
          <DiscardRercordingModal
            setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
            isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
            onConfirm={() => {
              selectMessage(null);
              setUserIsRecordingVideo(false);
            }}
          />
          <Div dflex alignCenter>
            <BackIcon clickable onClick={goBack}>
              <Icon name="Left" />
            </BackIcon>
            <Div onClick={goBack} clickable>
              {selectedMessage.private.form.requestedBy}
            </Div>
          </Div>
          <Options {...{ setIsHelpModalOpen }} />
        </SelectedMessageHeader>
      )}
    </>
  );

  return (
    <HeaderWrapper
      isNewArtistGroup={isNewArtistGroup}
      isMessageSelected={isMessageSelected}
    >
      <Div forMobile>{helpUI}</Div>
      <DefaultHeader isMessageSelected={isMessageSelected}>
        {logoUI}
        {headerIconsUI}
      </DefaultHeader>
      {selectedMessageHeaderUI}
    </HeaderWrapper>
  );
};

const SearchModal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <Div>
          <Backdrop onClick={onClose} />
          <ModalContainer>
            <SearchModalWrapper>
              <Div c_text bold fs_17 mb_8>
                Search For A Request
              </Div>
              <SearchBox onClose={onClose} />
              <Div flexRowReverse c_text mt_40>
                <Link onClick={onClose}>Cancel</Link>
              </Div>
            </SearchModalWrapper>
          </ModalContainer>
        </Div>
      )}
    </>
  );
};

const Options = ({ setIsHelpModalOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { setIsTroubleshootModalOpen } = useAppContext();

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <Div>
      {isSettingsModalOpen && (
        <FullScreenModal
          title="Settings"
          onClose={() => setIsSettingsModalOpen(false)}
        >
          <Settings onClose={() => setIsSettingsModalOpen(false)} />
        </FullScreenModal>
      )}
      {isSettingsModalOpen && (
        <Settings onClose={() => setIsSettingsModalOpen(false)} />
      )}
      {isMenuOpen && (
        <>
          <Backdrop onClick={closeMenu} />
          <DropdownDiv onClick={closeMenu}>
            <MenuItem onClick={() => setIsHelpModalOpen(true)}>Help</MenuItem>
            <MenuItem onClick={() => setIsTroubleshootModalOpen(true)}>
              Troubleshoot
            </MenuItem>
            <MenuItem onClick={() => setIsSettingsModalOpen(true)}>
              Settings
            </MenuItem>
          </DropdownDiv>
        </>
      )}
      <MoreIcon clickable onClick={() => setIsMenuOpen(true)}>
        <Icon platform name="More" />
      </MoreIcon>
    </Div>
  );
};
