var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Div } from "./Div";
import { Icon } from "notes";
export var ModalContainer = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000000000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"], ["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000000000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"])));
export var ModalBackdrop = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  background: black;\n  opacity: 0.38;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  background: black;\n  opacity: 0.38;\n"])));
export var ModalWrapper = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  padding: 32px 40px;\n  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n"], ["\n  background: ", ";\n  padding: 32px 40px;\n  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n"])), function (props) { return props.theme.colors.white; });
export var ModalCloseIcon = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 0;\n  display: flex;\n  flex-direction: row-reverse;\n"], ["\n  height: 0;\n  display: flex;\n  flex-direction: row-reverse;\n"])));
export var Modal = function (_a) {
    var _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.onClose, onClose = _c === void 0 ? null : _c, children = _a.children, _d = _a.withCloseIcon, withCloseIcon = _d === void 0 ? true : _d, _e = _a.withBackdropClose, withBackdropClose = _e === void 0 ? true : _e, _f = _a.className, className = _f === void 0 ? "" : _f;
    return (React.createElement(React.Fragment, null, isOpen && (React.createElement(Div, { className: className },
        React.createElement(ModalBackdrop, { onClick: withBackdropClose ? onClose : null }),
        React.createElement(ModalContainer, null,
            React.createElement(ModalWrapper, null,
                withCloseIcon && (React.createElement(ModalCloseIcon, null,
                    React.createElement(Div, { clickable: true, mt_n10: true, mr_n20: true, onClick: onClose },
                        React.createElement(Icon, { platform: true, name: "Close", height: 18 })))),
                React.createElement(Div, null, children)))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
