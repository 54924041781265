import { useUserContext } from "components/UserContextProvider";
import { shoutoutI } from "firebase-tools";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

type State = {
  isArtistGroupAdmin: boolean;
  data: shoutoutI.functions.artists.GetArtistGroupResponse;
  artistGroupId: any;
  getData: () => Promise<void>;
};

const ArtistGroupContext = createContext<State>({} as State);

export const ArtistGroupContextProvider = ({ children }) => {
  const { user } = useUserContext();
  const { artistGroupId } = useParams<any>();
  const [data, setData] = useState<
    shoutoutI.functions.artists.GetArtistGroupResponse
  >(null);

  const isArtistGroupAdmin = () => {
    if (data) {
      const res = data.members.find(
        (m) => m.roles.includes("admin") && m.uid === user.uid
      );
      if (res) return true;
    }
    return false;
  };

  const getData = async () => {
    const {
      result,
      error,
    } = await shoutoutI
      .functions()
      .artists.getArtistGroup({ groupId: artistGroupId });
    if (!error) setData(result);
  };

  useEffect(() => {
    getData();
  }, []);

  const value = {
    isArtistGroupAdmin: isArtistGroupAdmin(),
    data,
    artistGroupId,
    getData,
  };
  return (
    <ArtistGroupContext.Provider value={value}>
      {children}
    </ArtistGroupContext.Provider>
  );
};

export const useArtistGroupContext = () => useContext(ArtistGroupContext);
