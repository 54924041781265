import styled from "styled-components";
import { P, H3, GridColumn, Subheader, GridRow } from "notes";
import { ManualAuth } from "./ManualAuth";
import { OpenAuth } from "./OpenAuth";
import { scrollBarDarkCss } from "components/common/styles";
import Div from "components/common/Div";
import { useHistory } from "react-router";

const FormContainer = styled(GridColumn)`
  max-width: 472px;
  margin: 0 auto;
  width: 100%;
  margin-top: 50px;
  padding: 0 24px 50px 24px;

  ${H3} {
    line-height: 32px;
    margin-bottom: 4px;
  }
  ${H3} + ${P} {
    margin-bottom: 24px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 20px;
    ${H3} {
      font-size: 17px;
      height: auto;
      line-height: 22px;
    }
    ${H3} + ${P} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const LineContainer = styled(GridRow)`
  height: 24px;
  justify-content: space-between;
  margin-top: 24px;
`;

const Line = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  height: 1px;
  width: 39%;
`;

const ScrollArea = styled(Div)`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  ${scrollBarDarkCss}
`;

const OrDivider = () => {
  return (
    <LineContainer yCenter>
      <Line />
      <Subheader>Or</Subheader>
      <Line />
    </LineContainer>
  );
};

const signInMessage = `Please sign in with your credentials to complete your Shout Outs.`;

export const Login = () => {
  const history = useHistory();
  const handleSuccess = (value) => {
    history.push("/");
  };

  return (
    <ScrollArea>
      <FormContainer>
        <Div mb_23>{signInMessage}</Div>
        <OpenAuth onSuccess={handleSuccess} />
        <OrDivider />
        <ManualAuth onSuccess={handleSuccess} />
      </FormContainer>
    </ScrollArea>
  );
};
