import { Div } from "@max/common-ui";
import styled from "styled-components";
export const PercentageBar = ({
  percent = 60,
  light = false,
  width = "104px",
}) => {
  const props = { light, percent };
  return (
    <Container {...props} width={width}>
      <Percentage {...props} />
    </Container>
  );
};
const Percentage = styled(Div)`
  height: 5px;
  width: ${(props) => props.percent}%;
  border-radius: 2.5px;
  background-color: ${(props) => (props.light ? "#39A4A1" : "#a7dbda")};
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
`;
const Container = styled(Div)`
  background-color: ${(props) => (props.light ? "#E6E9EB" : "#20273F")};
  border-radius: 2.5px;
  box-shadow: ${(props) =>
    props.light
      ? "inset 0 1px 2px 0 rgba(0,0,0,0.32)"
      : "inset 0 1px 2px 0 rgba(0, 0, 0, 0.5)"};
  width: ${(props) => props.width};
  height: 5px;
  overflow: hidden;
`;
