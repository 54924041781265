import { Div, Section as SectionBase } from "@max/common-ui";
import styled, { css } from "styled-components";

export const HeaderText = styled(Div)`
  font-weight: 700;
  font-size: 17px;
  ${(props) =>
    props.h1 &&
    css`
      font-size: 25px;
    `}
  color: ${(props) => props.theme.colors.text};
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: -10px;
  }
`;

export const Description = styled(Div)`
  font-weight: 300;
  font-size: 17px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: -5px;
`;

const SectionWrapper = styled(SectionBase)`
  @media all and ${(props) => props.theme.media.desktop} {
    ${(props) =>
      props.withMargin &&
      css`
        margin-top: 27px;
      `}
  }
`;

export const Section = ({
  children,
  header,
  description,
  withMargin = false,
}) => {
  return (
    <SectionWrapper withMargin={withMargin}>
      <Div mcontainer>
        <HeaderText>{header}</HeaderText>
        <Description>{description}</Description>
        {children}
      </Div>
    </SectionWrapper>
  );
};
