var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled, { css } from "styled-components";
import { Div } from "../../..";
var IconSvg = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 1000;\n"], ["\n  z-index: 1000;\n"])));
var IconBackground = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  background: ", ";\n  opacity: 0.72;\n  height: 100%;\n  width: 100%;\n"], ["\n  position: absolute;\n  background: ", ";\n  opacity: 0.72;\n  height: 100%;\n  width: 100%;\n"])), function (props) { return props.theme.colors.black; });
var IconWrapper = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  width: 40px;\n  border-radius: 4px;\n  overflow: hidden;\n  margin: 15px 15px 0 0;\n  user-select: none;\n  ", " {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    svg {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n  :hover {\n    cursor: pointer;\n    box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n    ", "\n    ", " {\n      opacity: 1;\n      ", "\n    }\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  width: 40px;\n  border-radius: 4px;\n  overflow: hidden;\n  margin: 15px 15px 0 0;\n  user-select: none;\n  ", " {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    svg {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n  :hover {\n    cursor: pointer;\n    box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n    ",
    "\n    ", " {\n      opacity: 1;\n      ",
    "\n    }\n  }\n"])), IconSvg, function (props) { return props.theme.colors.white; }, function (props) {
    return !props.isDelete && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        svg {\n          path {\n            fill: ", ";\n          }\n        }\n      "], ["\n        svg {\n          path {\n            fill: ", ";\n          }\n        }\n      "])), function (props) { return props.theme.colors.text; });
}, IconBackground, function (props) {
    return props.isDelete
        ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n              background: ", ";\n            "], ["\n              background: ", ";\n            "])), function (props) { return props.theme.colors.red20; }) : css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n              background: ", ";\n            "], ["\n              background: ", ";\n            "])), function (props) { return props.theme.colors.white; });
});
var MediaButton = function (_a) {
    var icon = _a.icon, onClick = _a.onClick, _b = _a.isDelete, isDelete = _b === void 0 ? false : _b;
    return (React.createElement(IconWrapper, { isDelete: isDelete, onClick: onClick },
        React.createElement(IconBackground, null),
        React.createElement(IconSvg, null, icon)));
};
export default MediaButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
