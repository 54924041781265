import { AppLoading, Div } from "@max/common-ui";
import { PercentageBar } from "components/common/PercentageBar";
import { Icon } from "notes";
import { useState } from "react";
import styled, { css } from "styled-components";
import { overlay, fadeinAnimation } from "@max/common-ui";
import { useMediaQuery } from "hooks";
import { theme } from "theme";
import { ShareCampaignModal } from "../../common/ShareCampaignModal";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "components/Routes";
import { useMessagesAppContext } from "../MessagesAppContextProvider";
import { ShoutoutAllocationMode } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { DateTime } from "luxon";
import { fb, fstore } from "firebase-tools";
import { Message } from "notes";
import { EndCampaignModal } from "./EndCampaignModal";
const {
  DropdownDiv: DropdownDivBase,
  Backdrop: BackdropBase,
  MenuItem: MenuItemBase,
} = overlay;

const MenuItem = styled(MenuItemBase)`
  display: flex;
  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
    `}
  :hover {
    background-color: #f3f3f3;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    border-bottom: none;
    font-size: 17px;
    padding: 17px;
  }
`;

const Backdrop = styled(BackdropBase)`
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    background-color: #11141d;
    opacity: 0.6;
  }
`;

export const MoreIcon = styled(Div)`
  transform: rotate(90deg);
  height: 40px;
  width: 40px;
  margin-top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    transform: rotate(0deg);
    svg {
      path {
        fill: ${(props) => props.theme.colors.text};
      }
    }
  }
`;

const DropdownDiv = styled(DropdownDivBase)`
  margin-top: 15px;
  margin-left: -275px;
  border-radius: 0;
  width: 280px;
`;

const IconWrapper = styled(Div)`
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.gray20};
    }
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    svg {
      path {
        fill: ${(props) => props.theme.colors.gray40};
      }
    }
  }
  ${(props) =>
    props.isRed &&
    css`
      svg {
        path {
          fill: ${(props) => props.theme.colors.red25};
        }
      }
    `}
`;

const Wrapper = styled(Div)`
  color: ${(props) => props.theme.colors.gray04};
  ${(props) =>
    !props.isMenuOpen &&
    css`
      align-items: flex-end;
      display: flex;
    `}
  padding-bottom: 15px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    align-items: center;
    box-shadow: 0 -8px 16px 0 rgba(0, 0, 0, 0.24);
    z-index: 100001;
    margin-bottom: 0;
    padding-bottom: 0;
    position: absolute;
    width: 100vw;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.text};
    left: 0;
    bottom: 0;
    display: flex;
    height: 58px;
    justify-content: space-around;
    transition: height 0.2s cubic-bezier(0, 0, 0.3, 1);
    ${(props) =>
      props.isMenuOpen &&
      css`
        height: 185px;
      `}
  }
`;

const CampaignEndedText = styled(Div)`
  font-size: 14px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    font-size: 16px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 16px;
  }
`;

const campaignInfoMobileCss = css`
  font-size: 14px;
  display: block;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 0px;
`;

const CampaignInfo = styled(Div)`
  font-size: 13px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.colors.white};
  @media all and ${(props) => props.theme.media.mobile} {
    ${campaignInfoMobileCss};
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    ${campaignInfoMobileCss};
  }
`;

const ProgressBarWrapper = styled(Div)`
  width: 230px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    width: 45vw;
  }
`;

const MobileMenu = styled(Div)`
  ${fadeinAnimation};
`;

const getDate = (date) => DateTime.fromJSDate(new Date(date.toMillis()));

const ShoutoutOptions = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEndCampaignModalOpen, setIsEndCampaignModalOpen] = useState(false);
  const [isCampaignEndedMessageOpen, setIsCampaignEndedMessageOpen] = useState(
    false
  );
  const isVerticalDisplay = useMediaQuery(theme.media.verticalDisplay);
  const { artistGroupId, shoutoutId } = useParams<any>();
  const { selectedShoutout } = useMessagesAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const submittedRequests = selectedShoutout.data?.quantity?.total;

  const history = useHistory();
  const MenuContainer = isVerticalDisplay ? MobileMenu : DropdownDiv;

  const endCampaign = async () => {
    setIsMenuOpen(false);
    setIsLoading(true);
    await fstore.doc(`shoutouts/${selectedShoutout.id}`).update({
      endedAt: fb.firestore.Timestamp.fromDate(new Date()),
    });
    setIsLoading(false);
    setIsCampaignEndedMessageOpen(true);
    setTimeout(() => setIsCampaignEndedMessageOpen(false), 3000);
  };

  const endCampaignMessageUI = (
    <Message
      open={isCampaignEndedMessageOpen}
      setOpen={setIsCampaignEndedMessageOpen}
      color="green"
      timeout={3000}
    >
      <Div semiBold>Campaign ended successfully</Div>
    </Message>
  );

  const menuUI = (
    <Div w100>
      <MenuItem
        onClick={() => {
          setIsMenuOpen(false);
          setIsShareModalOpen(true);
        }}
      >
        <IconWrapper>
          <Icon form name="Share" />
        </IconWrapper>
        Share Campaign
      </MenuItem>
      <MenuItem
        onClick={() =>
          history.push(
            generatePath(ROUTES.EDIT_CAMPAIGN_FORM, {
              artistGroupId,
              shoutoutId,
            })
          )
        }
      >
        <IconWrapper>
          <Icon tertiary name="Edit" />
        </IconWrapper>
        Edit Campaign
      </MenuItem>
      <MenuItem
        isLast={true}
        onClick={() => {
          setIsEndCampaignModalOpen(true);
          setIsMenuOpen(false);
        }}
      >
        <IconWrapper isRed>
          <Icon media name="Stop" height="20px" width="20px" />
        </IconWrapper>
        End Campaign
      </MenuItem>
    </Div>
  );

  const quantityProgressBarUI = (
    <ProgressBarWrapper mb_4>
      <Div lite fs_10 w100 centered mb_4>
        {submittedRequests}/{selectedShoutout.data.quantity.limit} requests
      </Div>
      <PercentageBar
        light={isVerticalDisplay}
        percent={
          (submittedRequests / selectedShoutout.data.quantity.limit) * 100
        }
        width="100%"
      />
    </ProgressBarWrapper>
  );

  const createdAt = selectedShoutout?.data?.createdAt;
  const endDate = selectedShoutout.data.endDate;
  const dateProgressBarUI = selectedShoutout.data.allocationMode ===
    ShoutoutAllocationMode.date && (
    <ProgressBarWrapper mb_4>
      <Div lite fs_10 w100 mb_4 spaceBetween>
        <Div>{getDate(createdAt).toFormat("DD")}</Div>
        <Div bold>
          {Math.floor(
            getDate(endDate).diff(DateTime.now(), "days").toObject().days
          )}
          {" days left"}
        </Div>
        <Div>{getDate(endDate).toFormat("DD")}</Div>
      </Div>
      <PercentageBar
        light={isVerticalDisplay}
        percent={
          ((Date.now() - createdAt.toMillis()) /
            (endDate.toMillis() - createdAt.toMillis())) *
          100
        }
        width="100%"
      />
    </ProgressBarWrapper>
  );

  const campaignInfoUI = (
    <>
      {selectedShoutout.data.allocationMode ===
        ShoutoutAllocationMode.unlimited && (
        <CampaignInfo>
          {submittedRequests || 0} Request
          {submittedRequests !== 1 ? "s" : ""} Submitted
        </CampaignInfo>
      )}
      {selectedShoutout.data.allocationMode === ShoutoutAllocationMode.date && (
        <CampaignInfo>{dateProgressBarUI}</CampaignInfo>
      )}
      {selectedShoutout.data.allocationMode ===
        ShoutoutAllocationMode.quantity && quantityProgressBarUI}
    </>
  );

  const isCampaignEnded = selectedShoutout?.data?.endedAt;
  return (
    <>
      {isLoading && <AppLoading />}
      {endCampaignMessageUI}
      <EndCampaignModal
        isOpen={isEndCampaignModalOpen}
        onConfirm={endCampaign}
        onClose={() => setIsEndCampaignModalOpen(false)}
      />
      <ShareCampaignModal
        title="Share Campaign"
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
      {isMenuOpen && <Backdrop onClick={() => setIsMenuOpen(false)} />}
      <Wrapper dflex isMenuOpen={isMenuOpen}>
        {isMenuOpen ? (
          <MenuContainer w100>{menuUI}</MenuContainer>
        ) : (
          <>
            {isCampaignEnded ? (
              <CampaignEndedText bold>Campaign ended</CampaignEndedText>
            ) : (
              campaignInfoUI
            )}
            <Div>
              <MoreIcon ml_20 mr_n4 mb_n5 onClick={() => setIsMenuOpen(true)}>
                <Icon platform name="More" />
              </MoreIcon>
            </Div>
          </>
        )}
      </Wrapper>
    </>
  );
};
export default ShoutoutOptions;
