import {
  Div,
  ErrorMessageWarning,
  FormikErrorFocus,
  Input,
} from "@max/common-ui";
import { Layout, SectionHeader } from "./common";
import { Button as ButtonBase } from "notes";
import { Form as FForm, Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useState } from "react";
import { Icon } from "notes";
import { fb } from "firebase-tools";

const Button = styled(ButtonBase)`
  width: 100%;
`;

const ErrorMessage = styled(Div)`
  padding: 16px;
  font-size: 17px;
  font-weight: 600px;
  color: ${(props) => props.theme.colors.text};
  border: 1px solid #edb8b4;
  background-color: #faf1f0;
  margin-top: 35px;
  span {
    color: ${(props) => props.theme.colors.action};
    user-select: none;
    margin-left: 2px;
    :hover {
      cursor: pointer;
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

const Divider = styled(Div)`
  height: 1px;
  background-color: #a6aeb2;
  margin: 40px 0;
`;

const initialValues = {
  email: "",
  password: "",
};
const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");

const validationSchema = yup.object().shape({
  email: yupRequired.email("Please enter a valid email"),
  password: yupRequired,
});

const Label = styled(Div)`
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  margin-bottom: 8px;
`;

const emailLogin = async (email, password) => {
  return fb.auth().signInWithEmailAndPassword(email, password);
};

const Login = () => {
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const history = useHistory();

  const onSubmitForm = async ({ email, password }) => {
    try {
      await emailLogin(email, password);
      history.push("/");
    } catch (err) {
      setIsInvalidCredential(true);
    }
  };

  const fieldsUI = (
    <>
      <Label mt_28>Email</Label>
      <Input
        leftIcon={
          <Div>
            <Icon form name="Email" />
          </Div>
        }
        width="100%"
        name="email"
        placeholder="john@email.com"
      />
      <Label mt_35>Password</Label>
      <Input
        leftIcon={
          <Div mt_n1>
            <Icon form name="Key" />
          </Div>
        }
        width="100%"
        type="password"
        name="password"
        placeholder="Password…"
      />
      <Div mb_35 mt_20>
        <Div
          lite
          c_action
          link
          fs_12
          semiBold
          onClick={() => {
            window.open(
              `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}/reset`
            );
          }}
        >
          Forgot Password?
        </Div>
      </Div>
    </>
  );

  const invalidCredentialUI = (
    <ErrorMessage>
      You entered an incorrect email, password, or both. Need an account?{" "}
      <span onClick={() => alert("sign up")}>Sign Up</span>
    </ErrorMessage>
  );

  return (
    <Layout>
      <Div>
        <SectionHeader
          title="Log In"
          description="In oculis quidem faciunt, ut de utilitatibus, nihil molestiae consequatur."
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ submitForm }) => (
            <FForm>
              {!isInvalidCredential && (
                <Div mt_30>
                  <ErrorMessageWarning />
                </Div>
              )}
              {isInvalidCredential && invalidCredentialUI}
              {fieldsUI}
              <FormikErrorFocus />
              <Button type="primary" onClick={submitForm}>
                Login
              </Button>
            </FForm>
          )}
        </Formik>
        <Div mt_15 />
      </Div>
    </Layout>
  );
};

export default Login;
