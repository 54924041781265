import { createContext, useContext, useEffect, useState } from "react";

const VideoMessageContext = createContext(null);

const getDeviceOptions = (devices, kind) => {
  const dvs = devices.filter((device) => device.kind === kind);
  return dvs.map((inp) => ({
    text: inp.label,
    id: inp.deviceId,
  }));
};

const ls = localStorage;
const LS_PREFERENCES_KEY = "preferences";
const LS_MEDIA_PREFERENCES_KEY = "media_preferences";
const lsPreferences = JSON.parse(ls.getItem(LS_PREFERENCES_KEY));
const lsMediaPreferences = JSON.parse(ls.getItem(LS_MEDIA_PREFERENCES_KEY));

export const VideoMessageContextProvider = ({ children }) => {
  const [devices, setDevices] = useState(null);
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState(
    lsMediaPreferences?.selectedAudioDeviceId
  );
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState(
    lsMediaPreferences?.selectedVideoDeviceId
  );
  const [userIsRecordingVideo, setUserIsRecordingVideo] = useState(false);
  const [preferences, setPreferences] = useState(
    lsPreferences || {
      autoPlay: false,
      saveSelectedVideoAudioDeviceIds: false,
    }
  );

  const updateMediaPreferences = () => {
    if (preferences.saveSelectedVideoAudioDeviceIds)
      ls.setItem(
        LS_MEDIA_PREFERENCES_KEY,
        JSON.stringify({
          selectedAudioDeviceId,
          selectedVideoDeviceId,
        })
      );
    else ls.removeItem(LS_MEDIA_PREFERENCES_KEY);
  };

  useEffect(() => {
    updateMediaPreferences();
  }, [selectedAudioDeviceId, selectedVideoDeviceId]);

  useEffect(() => {
    updateMediaPreferences();
    ls.setItem(LS_PREFERENCES_KEY, JSON.stringify(preferences));
  }, [preferences]);

  let videoOptions = [];
  let audioOptions = [];
  if (devices) {
    videoOptions = getDeviceOptions(devices, "videoinput");
    audioOptions = getDeviceOptions(devices, "audioinput");
  }

  return (
    <VideoMessageContext.Provider
      value={{
        videoOptions,
        audioOptions,
        selectedAudioDeviceId,
        selectedVideoDeviceId,
        preferences,
        userIsRecordingVideo,
        setDevices,
        setUserIsRecordingVideo,
        setPreferences,
        setSelectedAudioDeviceId,
        setSelectedVideoDeviceId,
      }}
    >
      {children}
    </VideoMessageContext.Provider>
  );
};

export const useVideoMessageContext = () => useContext(VideoMessageContext);
