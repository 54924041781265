import { fstore } from "firebase-tools";
import ldb from "ldb";

export const resetUploadErrorCount = (messageRequestId) => {
  fstore.doc(`shoutout_requests/${messageRequestId}/private/data`).update({
    error: {
      count: 0,
      message: "",
    },
  });
};

export const getShoutoutPath = (shoutoutId) =>
  `${process.env.REACT_APP_FAN_APP_URL}/s/${shoutoutId}`;

export const removeLocalBlobsById = async (ids) => {
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const blob = await ldb.collection("blobs").doc(id).get();
    if (blob) await ldb.collection("blobs").doc(id).delete();
  }
};

export const getBlobFromUrl = async (url) => await (await fetch(url)).blob();

export const capitalize = (words) =>
  words.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
