import { Div, getObjectByString } from "@max/common-ui";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import {
  getResponseTimeWarningMessage,
  getTimetoLive,
} from "components/Messages/common";
import {
  messageIsCompleted,
  TABS,
  useMessagesContext,
} from "components/Messages/MessagesContextProvider";
import { ROUTES } from "components/Routes";
import { Autocomplete, Footnote, GridColumn, GridRow, Icon, P } from "notes";
import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const itemsArray = [
  {
    id: 1,
    location: "New York City, New York",
    venue: "Blue Swan Palace",
    unread: true,
  },
  {
    id: 2,

    location: "New York City, New York",
    venue: "Boulevard Hall",
    warn: true,
  },
  {
    id: 3,
    location: "New York City, New York",
    venue: "Bridgewater Manor",
  },
];

const compare = (request, searchText) => {
  const match = (field) =>
    getObjectByString(request, field)
      ?.toLowerCase()
      .includes(searchText.toLowerCase());

  return (
    match("private.form.requestedBy") ||
    match("private.form.messageFor") ||
    match("private.form.relation") ||
    match("private.form.starter") ||
    match("public.shoutout.name")
  );
};

const SearchBox = ({ onClose, ...props }) => {
  const [selected, setSelected] = useState("");
  const { messageRequests, selectMessage, selectTab } = useMessagesContext();
  const history = useHistory();
  const { artistGroupId } = useParams<any>();
  const items = messageRequests;

  const search = async (searchTerm) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(items.filter((r) => compare(r, searchTerm)));
      }, 1000);
    });

  const handleChange = (result) => {
    setSelected(result.venue);
  };

  const Result = ({ result, ...props }) => {
    let responseTimeWarningMessage = null;
    if (result?.public) {
      responseTimeWarningMessage = getResponseTimeWarningMessage(
        getTimetoLive(
          result.public.createdAt,
          result.public.shoutout.responseTimeDays
        ).toObject()
      );
    }

    const onClick = () => {
      const selectedMessage = messageRequests.find((m) => m.id === result.id);

      if (messageIsCompleted(selectedMessage)) selectTab(TABS.COMPLETED);
      else selectTab(TABS.QUEUE, true);

      selectMessage(selectedMessage, true);
      history.push(
        generatePath(ROUTES.MESSAGES, {
          artistGroupId,
        }),
        { isFocusingMessage: true }
      );
      onClose();
    };

    return (
      <Item {...props} onClick={onClick}>
        <IconContainer xCenter yCenter>
          {responseTimeWarningMessage && !messageIsCompleted(result) ? (
            <WarnIcon form name="Error" />
          ) : result?.public?.status == RequestStatus.received ? (
            <AlertIcon />
          ) : (
            ""
          )}
        </IconContainer>
        <GridColumn>
          <StyledP>{result?.private?.form?.requestedBy}</StyledP>
          <Div mb_5>
            <Footnote>{result?.public?.shoutout?.name}</Footnote>
          </Div>
        </GridColumn>
      </Item>
    );
  };

  return (
    <GridColumn stretch>
      <Autocomplete
        onChange={handleChange}
        search={search}
        selected={selected}
        placeholder="Search by artist name or venue..."
        Result={Result}
        {...props}
      />
    </GridColumn>
  );
};

export default SearchBox;

const AlertIcon = styled.div`
  background-color: ${(props) => props.theme.palette.green.lighter};
  border-radius: 50%;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
`;

const WarnIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.yellow.primary};
  }
`;

const IconContainer = styled(GridRow)`
  padding: 0 12px;
  width: 40px;
`;

const StyledP = styled(P)`
  font-weight: 300;
  margin-bottom: -5px;
`;

const Item = styled(GridRow)`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  position: relative;
  padding: 0px 12px 0px 0;
  &:hover {
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;
