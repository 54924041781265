import { useUserContext } from "components/UserContextProvider";
import { useEffect } from "react";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import AccountSetup from "./AccountSetUp";
import ForgotPassword from "./ForgotPassword";
import { Layout } from "./common";
import Login from "./Login";
import NewPassword from "./NewPassword";

export const ROUTES = {
  ACCOUNT_SET_UP: "/account-set-up",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
};

const User = () => {
  const match = useRouteMatch();
  const baseUrl = match.path;
  const { isLoggedIn } = useUserContext();

  const DefaultRoute = () => (
    <Redirect to={isLoggedIn ? `${baseUrl}${ROUTES.LOGIN}` : "/"} />
  );
  return (
    <Layout>
      <Switch>
        <Route exact path={`${baseUrl}${ROUTES.ACCOUNT_SET_UP}`}>
          <AccountSetup />
        </Route>
        {/* <Route exact path={`${baseUrl}${ROUTES.LOGIN}`}>
          <Login />
        </Route>
        <Route exact path={`${baseUrl}${ROUTES.FORGOT_PASSWORD}`}>
          <ForgotPassword />
        </Route>
        <Route exact path={`${baseUrl}${ROUTES.NEW_PASSWORD}`}>
          <NewPassword />
        </Route> */}
        <Route component={() => <DefaultRoute />} />
      </Switch>
    </Layout>
  );
};

export default User;
