var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Div } from "./Div";
export var overlay = {
    Backdrop: styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    margin: 0px;\n    width: 100vw;\n    height: 100vh;\n    z-index: 10000;\n  "], ["\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    margin: 0px;\n    width: 100vw;\n    height: 100vh;\n    z-index: 10000;\n  "]))),
    MenuItem: styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    font-size: 17px;\n    color: #222222;\n    user-select: none;\n    font-weight: normal;\n    padding: 12px 10px;\n    :hover {\n      background-color: #e2e6e8;\n      cursor: pointer;\n      opacity: 0.9;\n    }\n  "], ["\n    display: flex;\n    align-items: center;\n    font-size: 17px;\n    color: #222222;\n    user-select: none;\n    font-weight: normal;\n    padding: 12px 10px;\n    :hover {\n      background-color: #e2e6e8;\n      cursor: pointer;\n      opacity: 0.9;\n    }\n  "]))),
    DropdownDiv: styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: white;\n    border: solid 1px#F9FAFB;\n    position: absolute;\n    border-radius: 4px;\n    box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n    z-index: 100000;\n  "], ["\n    background-color: white;\n    border: solid 1px#F9FAFB;\n    position: absolute;\n    border-radius: 4px;\n    box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n    z-index: 100000;\n  "]))),
    ModalContainer: styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    top: 0px;\n    left: 0px;\n    margin: 0px;\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    z-index: 100000;\n    width: 100vw;\n    height: 100vh;\n    margin-top: 115px;\n    pointer-events: none;\n    div {\n      pointer-events: auto;\n    }\n  "], ["\n    top: 0px;\n    left: 0px;\n    margin: 0px;\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    z-index: 100000;\n    width: 100vw;\n    height: 100vh;\n    margin-top: 115px;\n    pointer-events: none;\n    div {\n      pointer-events: auto;\n    }\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
