import { TABS, useMessagesContext } from "../MessagesContextProvider";
import { useState } from "react";
import { useVideoMessageContext } from "../VideoMessageContextProvider";
import DiscardRercordingModal from "../../common/DiscardRecordingModal";
import { Div, useIsMobile } from "@max/common-ui";
import styled, { css } from "styled-components";

export const TabLabelWrapper = styled(Div)`
  svg {
    path {
      fill: white;
    }
  }
  user-select: none;
  font-weight: 500;
  font-size: 17px;
  color: #cdcfd4;
  padding: 9px 5px;
  font-weight: bold;
  :hover {
    cursor: pointer;
    border-radius: 5px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0;
    font-weight: 400;
    width: 100%;
  }
  ${(props) =>
    props.isSelected &&
    css`
      @media all and ${(props) => props.theme.media.desktop} {
        color: ${(props) => props.theme.colors.white};
        svg {
          path {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }
    `};
`;

const TabWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

export const TabLabel = styled(Div)`
  padding: 5px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  @media all and ${(props) => props.theme.media.mobile} {
    margin: 9px 0;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: ${(props) => props.theme.colors.white};
    `}
`;

export const BorderBottom = styled(Div)`
  ${(props) =>
    props.isSelected &&
    css`
      width: 100%;
      height: 4px;
      background-color: ${(props) => props.theme.colors.white};
    `};
`;

const Tab = ({ label, value, count }) => {
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const { selectedTab, selectTab } = useMessagesContext();
  const { userIsRecordingVideo } = useVideoMessageContext();

  const isSelected = value === selectedTab;

  return (
    <>
      <DiscardRercordingModal
        setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
        isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
        onConfirm={() => {
          selectTab(value);
        }}
      />
      <TabWrapper>
        <TabLabelWrapper
          centered
          isSelected={isSelected}
          onClick={() => {
            if (userIsRecordingVideo && value !== TABS.QUEUE)
              setIsDiscardRecordingModalOpen(true);
            else if (value !== selectedTab) selectTab(value);
          }}
        >
          <TabLabel isSelected={isSelected}>
            <Div ml_8 mr_8 dflex>
              {label}
              <Div ml_3>{`(${count})`}</Div>
            </Div>
          </TabLabel>
        </TabLabelWrapper>
        <BorderBottom isSelected={isSelected} />
      </TabWrapper>
    </>
  );
};

export const Tabs = () => {
  const { messageQueueCount, completedMessagesCount } = useMessagesContext();
  const isMobile = useIsMobile();
  return (
    <Div dflex w100 pt_3>
      <Tab
        label={isMobile ? "Queue" : "Message Queue"}
        count={messageQueueCount}
        value={TABS.QUEUE}
      />
      <Tab
        count={completedMessagesCount}
        label={`Completed`}
        value={TABS.COMPLETED}
      />
    </Div>
  );
};
