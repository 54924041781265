var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import styled from "styled-components";
import { Div } from "./Div";
import { theme } from "./theme";
var Wrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background-color: blue;\n"], ["\n  color: ", ";\n  background-color: blue;\n"])), function (props) { return props.theme.colors.yellow10; });
export var Test = function () {
    var _a = useState(0), count = _a[0], setCount = _a[1];
    return (React.createElement(Wrapper, { onClick: function () {
            setCount(count + 1);
        } },
        React.createElement("pre", null, JSON.stringify({ theme: theme }, null, 2)),
        "test2: ",
        count));
};
export default Test;
var templateObject_1;
