import { Div } from "@max/common-ui";
import { ROUTES } from "components/Routes";
import { useFormikContext } from "formik";
import { ButtonPrimary, ButtonSecondary } from "notes";
import { generatePath, useHistory, useParams } from "react-router";
import styled from "styled-components";

const LaunchButton = styled(ButtonPrimary)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

export const LaunchCampaignButton = ({ isEdit = false }) => {
  const { submitForm } = useFormikContext();
  const { artistGroupId, shoutoutId } = useParams<any>();
  const history = useHistory();
  return (
    <Div pb_15>
      {isEdit ? (
        <Div dflex>
          <ButtonSecondary
            onClick={() =>
              history.push(
                generatePath(ROUTES.SHOUTOUT_MESSAGES, {
                  artistGroupId,
                  shoutoutId,
                })
              )
            }
          >
            Discard Changes
          </ButtonSecondary>
          <Div ml_27>
            <ButtonPrimary onClick={submitForm}>Apply Changes</ButtonPrimary>
          </Div>
        </Div>
      ) : (
        <LaunchButton onClick={submitForm}>Launch Campaign</LaunchButton>
      )}
    </Div>
  );
};
