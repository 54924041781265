import styled, { css } from "styled-components";
import Div from "./Div";

export const paddingLeftCss = css`
  padding-left: 23px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-left: 2.5%;
  }
`;

export const paddingRightCss = css`
  padding-right: 30px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-right: 1.7%;
  }
`;

export const scrollBarDarkCss = css`
  scrollbar-width: thin;
  scrollbar-color: #404658 transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #404658;
    opacity: 0.3;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: #404658;
  }
`;

export const scrollBarCss = css`
  scrollbar-width: thin;
  scrollbar-color: #7f7f7f transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    opacity: 0.3;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: #7f7f7f;
  }
`;

export const AppContainer = styled(Div)`
  ${paddingRightCss}
  ${paddingLeftCss}
`;
