import styled from "styled-components";
import { Icon } from "notes";
import Div from "../../../common/Div";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { fstore } from "../../../../firebase-tools";
import { LoaderInline } from "notes";
import { DateTime } from "luxon";
import { ShoutoutRequest } from "../../MessagesContextProvider";
import {
  RequestEvent,
  RequestStatus,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";

type Props = {
  message: ShoutoutRequest;
};
const RejectedExpiredMessage: React.FC<Props> = ({ message }) => {
  const [events, isLoadingEvents] = useCollectionData(
    fstore.collection(`shoutout_requests/${message.id}/events`)
  );

  return (
    <>
      {isLoadingEvents && (
        <Div mt_40>
          <LoaderInline />
        </Div>
      )}
      {events && <RejectedExpiredWithData message={message} events={events} />}
    </>
  );
};

const eventMessageText = {
  [RequestStatus.rejected]: "was declined",
  [RequestStatus.expired]: "expired",
};

const Wrapper = styled(Div)`
  background-color: ${(props) => props.theme.colors.yellow10};
  border: solid 1px ${(props) => props.theme.colors.yellow15};
  padding: 10px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.yellow40};
    }
  }
`;

type WithDataProps = {
  events: any;
  message: ShoutoutRequest;
};
const RejectedExpiredWithData: React.FC<WithDataProps> = ({
  events,
  message,
}) => {
  const evts = events.map((e) => {
    try {
      return RequestEvent.raw(e);
    } catch (err) {
      console.log(err);
    }
  });
  evts.reverse();
  const date: DateTime = evts.find((e) => e?.status === message.public.status)
    ?.createdAt;
  return (
    <>
      {date && (
        <Wrapper c_text fs_17 w100 dflex>
          <Div mr_10 mt_2>
            <Icon form name="Error" width="30px" />
          </Div>
          <Div>
            This request {eventMessageText[message.public.status]}{" "}
            {date.toFormat("DDD")} and has been refunded.
          </Div>
        </Wrapper>
      )}
    </>
  );
};
export default RejectedExpiredMessage;
