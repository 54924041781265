import { AppContainer, Div } from "@max/common-ui";
import { ShoutoutLogo } from "components/common/ShoutoutLogo";
import UserMenu from "components/common/UserMenu";
import { useHistory } from "react-router";
import styled from "styled-components";

const Container = styled(Div)`
  background: ${(props) => props.theme.colors.white};
  box-shadow: inset 0 -2px 0 0 ${(props) => props.theme.colors.gray10};
`;

const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  height: 70px;
`;

const ShoutoutLogoWrapper = styled(Div)`
  margin-left: -20px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin: 0;
  }
`;

const Header = () => {
  const history = useHistory();
  return (
    <Container>
      <AppContainer>
        <Wrapper spaceBetween mcontainer>
          <ShoutoutLogoWrapper onClick={() => history.push("/")}>
            <ShoutoutLogo />
          </ShoutoutLogoWrapper>
          <UserMenu />
        </Wrapper>
      </AppContainer>
    </Container>
  );
};

export default Header;
