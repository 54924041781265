import {
  AppContainer,
  AppLayout,
  AppLoading,
  Div,
  ErrorMessageWarning as ErrorMessageWarningBase,
  ErrorWarningMessageContent,
  Footer,
  FormikErrorFocus,
  MobileDivider,
  useIsMobile,
  WarningMessage,
} from "@max/common-ui";
import {
  Shoutout,
  ShoutoutAllocationMode,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { useAppContext } from "components/AppContextProvider";
import { ShoutoutLogo } from "components/common/ShoutoutLogo";
import { ROUTES } from "components/Routes";
import { fb, fstore, shoutoutI } from "firebase-tools";
import { Form as FForm, Formik } from "formik";
import { Icon } from "notes";
import { useContext, useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { generatePath, useHistory, useParams } from "react-router";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import { getBlobFromUrl } from "utils";
import * as yup from "yup";
import BasicFields from "./BasicFields";
import CampaignTypeField from "./CampaignTypeField";
import {
  campaignFormInitialValues,
  getInitialValuesFromShoutout,
  getShoutoutPayload,
} from "./common/initialValues";
import { LaunchCampaignButton } from "./LaunchButton";
import MediaFields from "./MediaFields";
import StarterFields from "./StarterFields";
import { useBlobUploads } from "./useBlobUploads";
import VideoField from "./VideoField";
import VideoRecorderInput from "./VideoRecorderInput";

const Header = styled(Div)`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    justify-content: left;
    height: 60px;
    margin-bottom: -15px;
    margin-top: 10px;
  }
`;

const Columns = styled(Div)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 65px;
  margin-bottom: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    grid-gap: 0;
    flex-direction: column;
  }
`;

const WarningMessageContainer = styled(Div)`
  margin-bottom: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const VideoRecorderWrapper = styled(Div)`
  height: 500px;
  width: 300px;
  border-radius: 5px;
  position: relative;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    border-radius: 0;
    height: 454.54px;
    width: 271px;
  }
`;

const ErrorMessageWarning = styled(ErrorMessageWarningBase)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-bottom: 0px;
  }
  ${ErrorWarningMessageContent} {
    margin-bottom: 24px;
    @media all and ${(props) => props.theme.media.mobile} {
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
`;

const warningMessageUI = (
  <WarningMessageContainer>
    <WarningMessage message="Please note that any changes made to this campaign will not impact Shout Out requests already received…" />
  </WarningMessageContainer>
);

export const requiredFieldMessage =
  "This is a required field and cannot be blank.";
const yupRequiredString = yup
  .string()
  .nullable()
  .required(requiredFieldMessage);
const yupRequiredPositiveNumber = yup
  .number()
  .positive()
  .required(requiredFieldMessage)
  .min(1);

const validationSchemaObj = {
  introduction: yupRequiredString,
  type: yup.number(),
  responseTimeDays: yupRequiredString,
  unitPrice: yupRequiredPositiveNumber.label("Cost per message"),
  campaignEndDate: yup
    .date()
    .nullable()
    .default(null)
    .when("type", {
      is: ShoutoutAllocationMode.date,
      then: yup
        .date()
        .required(requiredFieldMessage)
        .min(new Date(), "Date cannot be in the past")
        .nullable()
        .default(null),
    }),
  maxNumberRequests: yup
    .number()
    .nullable()
    .when("type", {
      is: ShoutoutAllocationMode.quantity,
      then: yup.number().required(),
    })
    .label("Maximum number of requests"),
  customStarter: yup
    .string()
    .nullable()
    .when("starters", (starters, schema) =>
      starters.includes("Other") ? yupRequiredString : schema
    ),
  starters: yup
    .array()
    .min(1, "Please select at least one Conversation Starter"),
  desktopImg: yup.object().shape({ blob: yup.string().required() }),
  mobileImg: yup.object().shape({ blob: yup.string().required() }),
};

const CampaignForm = () => {
  const { shoutoutId } = useParams<any>();
  return <>{shoutoutId ? <EditCamapignForm /> : <NewCampaignForm />}</>;
};

const EditCamapignForm = () => {
  const { shoutoutId } = useParams<any>();
  const [shoutout, isLoadingShoutout]: any = useDocumentData(
    fstore.doc(`shoutouts/${shoutoutId}`)
  );
  return (
    <Div>
      {isLoadingShoutout ? (
        <AppLoading />
      ) : (
        <CampaignFormWithData shoutout={shoutout} />
      )}
    </Div>
  );
};

const NewCampaignForm = () => {
  const { artistGroupId } = useParams<any>();
  const [artistGroup, isLoadingartistGroup]: any = useDocumentData(
    fstore.doc(`artist_groups/${artistGroupId}`)
  );
  return (
    <Div>
      {isLoadingartistGroup ? (
        <AppLoading />
      ) : (
        <CampaignFormWithData artistGroupName={artistGroup.name} />
      )}
    </Div>
  );
};

const CampaignFormWithData = ({ shoutout = null, artistGroupName = "" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(
    campaignFormInitialValues(artistGroupName)
  );
  const [recordingBlob, setRecordingBlob] = useState(null);

  useEffect(() => {
    (async () => {
      if (shoutout) {
        setIsLoading(true);
        setInitialValues(await getInitialValuesFromShoutout(shoutout));
        const videoUrl = shoutout?.assets?.video?.path;
        if (videoUrl) setRecordingBlob(await getBlobFromUrl(videoUrl));
      }
      setIsLoading(false);
    })();
  }, []);

  const themeBase = useContext(ThemeContext);
  const theme = { ...themeBase };
  theme.media = { ...theme.media, mobile: "(max-width: 1200px)" };
  theme.media = { ...theme.media, desktop: "(min-width: 1201px)" };

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <AppLoading />}
      {!isLoading && (
        <CampaignFormWithInitialValues
          initialRecordingBlob={recordingBlob}
          {...{ shoutout, initialValues }}
        />
      )}
    </ThemeProvider>
  );
};

const CampaignFormWithInitialValues = ({
  shoutout = null,
  initialValues,
  initialRecordingBlob,
}) => {
  const {
    colors: { white: whiteColor, background: backgroundColor },
  } = useContext(ThemeContext);
  const isMobile = useIsMobile();
  const [isVideoRecorderOpen, setIsVideoRecorderOpen] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState({
    blob: initialRecordingBlob,
    isInitialValue: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setIsNewCampaign, setIsCampaignEdit } = useAppContext();
  const history = useHistory();
  const { artistGroupId, shoutoutId } = useParams<any>();
  const { uploadBlobs, progressModalUI } = useBlobUploads();
  const isEdit = !!shoutoutId;

  useEffect(() => {
    if (isVideoRecorderOpen) return;
    if (isMobile) document.body.style.backgroundColor = whiteColor;
    else document.body.style.backgroundColor = backgroundColor;
  }, [isMobile, isVideoRecorderOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isVideoRecorderOpen]);

  const onSubmitForm = async (values) => {
    const urls = await uploadBlobs(
      !values.desktopImg.isInitialValue && values.desktopImg.blob,
      !values.mobileImg.isInitialValue && values.mobileImg.blob,
      !recordingBlob.isInitialValue && recordingBlob.blob
    );

    let videoUrl = urls.videoUrl || shoutout?.assets?.video?.path;
    if (!recordingBlob.blob) videoUrl = "";
    const newShoutout = getShoutoutPayload(
      values,
      {
        desktopImgUrl:
          urls.desktopImgUrl || shoutout?.assets?.headerLarge?.path,
        mobileImgUrl: urls.mobileImgUrl || shoutout?.assets?.headerSmall?.path,
        videoUrl,
      },
      isEdit
    );
    setIsLoading(true);
    if (!isEdit) {
      const res = await shoutoutI.functions().shoutouts.createShoutout({
        ...newShoutout,
        artistGroupId,
      } as Shoutout);
      if (res?.result?.success) {
        setIsNewCampaign(true);
        history.push(
          generatePath(ROUTES.SHOUTOUT_MESSAGES, {
            artistGroupId,
            shoutoutId: res.result.shoutoutId,
          })
        );
      }
    } else {
      await fstore.doc(`shoutouts/${shoutoutId}`).set(
        {
          ...newShoutout,
          endDate: newShoutout.endDate
            ? fb.firestore.Timestamp.fromDate(
                new Date(newShoutout?.endDate?.toMillis())
              )
            : null,
        },
        { merge: true }
      );
      setIsCampaignEdit(true);
      history.push(
        generatePath(ROUTES.SHOUTOUT_MESSAGES, {
          artistGroupId,
          shoutoutId,
        })
      );
    }
    setIsLoading(false);
  };

  const headerUI = (
    <Header mcontainer>
      <Div spaceBetween w100>
        <ShoutoutLogo />
        <Div clickable onClick={() => history.goBack()}>
          <Icon platform name="Close" height={18} />
        </Div>
      </Div>
    </Header>
  );

  const formFieldsUI = (
    <Div>
      {isEdit && warningMessageUI}
      <ErrorMessageWarning />
      <CampaignTypeField />
      <MobileDivider />
      <BasicFields />
      <MobileDivider />
      <StarterFields />
      <MobileDivider />
      <MediaFields />
    </Div>
  );

  const formUI = (
    <>
      {progressModalUI}
      {headerUI}
      <Columns>
        {formFieldsUI}
        <Div>
          <MobileDivider />
          <VideoField
            {...{ recordingBlob, setRecordingBlob, setIsVideoRecorderOpen }}
          />
          <MobileDivider />
        </Div>
        <Div mcontainer forMobile>
          <Div mt_26>
            <LaunchCampaignButton isEdit={isEdit} />
          </Div>
        </Div>
      </Columns>
      <Footer
        withLogo={false}
        background={isMobile ? whiteColor : backgroundColor}
      />
    </>
  );
  if (shoutout?.quantity?.total)
    validationSchemaObj.maxNumberRequests = validationSchemaObj.maxNumberRequests.min(
      shoutout.quantity.total + 1,
      `This must be greater than the number of requests already received (${shoutout.quantity.total}).`
    );
  else
    validationSchemaObj.maxNumberRequests = validationSchemaObj.maxNumberRequests.min(
      1,
      `Maximum number of requests must be greater than 0`
    );
  return (
    <AppLayout>
      {isLoading && <AppLoading />}
      <AppContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={yup.object().shape(validationSchemaObj)}
          enableReinitialize
        >
          {() => (
            <FForm>
              {!isVideoRecorderOpen ? (
                formUI
              ) : (
                <VideoRecorderInput
                  setRecordingBlob={(blob) =>
                    setRecordingBlob({ blob, isInitialValue: false })
                  }
                  {...{
                    isVideoRecorderOpen,
                    setIsVideoRecorderOpen,
                  }}
                />
              )}
              <FormikErrorFocus />
            </FForm>
          )}
        </Formik>
      </AppContainer>
    </AppLayout>
  );
};

export default CampaignForm;
