var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled, { css } from "styled-components";
import { Div } from "./Div";
export var scrollBarDarkCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  scrollbar-width: thin;\n  scrollbar-color: #404658 transparent;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: #404658;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #404658;\n  }\n"], ["\n  scrollbar-width: thin;\n  scrollbar-color: #404658 transparent;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: #404658;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #404658;\n  }\n"])));
export var scrollBarCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  scrollbar-width: thin;\n  scrollbar-color: #7f7f7f transparent;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: white;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #7f7f7f;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #efebe9;\n  }\n"], ["\n  scrollbar-width: thin;\n  scrollbar-color: #7f7f7f transparent;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: white;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #7f7f7f;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #efebe9;\n  }\n"])));
export var paddingLeftCss = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 50px;\n  @media all and ", " {\n    padding-left: 0%;\n  }\n"], ["\n  padding-left: 50px;\n  @media all and ", " {\n    padding-left: 0%;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export var paddingRightCss = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-right: 50px;\n  @media all and ", " {\n    padding-right: 0%;\n  }\n"], ["\n  padding-right: 50px;\n  @media all and ", " {\n    padding-right: 0%;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export var AppLayout = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-rows: auto 1fr auto;\n  height: 100vh;\n"], ["\n  display: grid;\n  grid-template-rows: auto 1fr auto;\n  height: 100vh;\n"])));
export var Container = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  ", "\n  width: 100%;\n  max-width: 1224px;\n"], ["\n  ", "\n  ", "\n  width: 100%;\n  max-width: 1224px;\n"])), paddingRightCss, paddingLeftCss);
export var AppContainer = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? "" : _b;
    return (React.createElement(Div, { justifyCenter: true, className: className },
        React.createElement(Container, null, children)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
