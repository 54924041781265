import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import videojs from "video.js";
import Div from "../../../common/Div";

let player;

const defaultOptions = {
  muted: false,
  controls: true,
};

const Videojs = ({
  src = null,
  width = "350px",
  height = "620px",
  blob = null,
  autoPlay = false,
  options = defaultOptions,
  withObjectFitCover = false,
}) => {
  const videoRef = useRef();

  useEffect(() => {
    player = videojs(videoRef.current, {
      fill: true,
      autoplay: autoPlay,
      controlBar: {
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
      ...options,
    });

    return () => {
      player.dispose();
    };
  }, []);

  useEffect(() => {
    if (src) player.src(src);
  }, [src]);

  useEffect(() => {
    if (blob) {
      player.src({
        src: window.URL.createObjectURL(blob),
        type: blob.type,
      });
    }
  }, [blob]);

  return (
    <Wrapper positionRelative width={width} height={height}>
      <Video
        playsInline
        withObjectFitCover={withObjectFitCover}
        ref={videoRef}
        className="video-js vjs-big-play-centered"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Div)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const Video = styled<any>("video")`
  outline: none;
  overflow: hidden;
  ${(props) =>
    props.withObjectFitCover &&
    css`
      .vjs-tech {
        object-fit: cover;
      }
    `}
  .vjs-big-play-button {
    background-color: #26a6fa;
    opacity: 1 !important;
    border: none;
  }
`;

export default Videojs;
