import styled, { css } from "styled-components";
import { theme } from "theme";

const prefixPropMappings = {
  mt: "margin-top",
  ml: "margin-left",
  mr: "margin-right",
  mb: "margin-bottom",
  pt: "padding-top",
  pb: "padding-bottom",
  pr: "padding-right",
  pl: "padding-left",
  p: "padding",
  m: "margin",
  w: "width",
  h: "height",
  fs: "font-size",
  br: "border-radius",
  ls: "letter-spacing",
  lh: "line-height",
  maxw: "max-width",
  minw: "min-width",
};

const Div = styled<any>("div")`
  ${(props) => props.dflex && `display: flex`};
  ${(props) =>
    props.dflexColumn &&
    css`
      display: flex;
      flex-direction: column;
    `};
  ${(props) => props.w100 && `width: 100%`};
  ${(props) => props.h100 && `height: 100%`};
  ${(props) => props.h50 && `height: 50%`};
  ${(props) => props.textAlignCenter && `text-align: center`};
  ${(props) => props.uppercase && "text-transform: uppercase"};
  ${(props) => props.capitalize && "text-transform: capitalize"};
  ${(props) => props.lowercase && "text-transform: lowercase"};
  ${(props) =>
    props.justifyCenter &&
    css`
      display: flex;
      justify-content: center;
    `};
  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};
  ${(props) =>
    props.spaceBetween &&
    css`
      display: flex;
      justify-content: space-between;
    `};
  ${(props) =>
    props.spaceAround &&
    css`
      display: flex;
      justify-content: space-around;
    `};
  ${(props) =>
    props.justifyEnd &&
    css`
      display: flex;
      justify-content: flex-end;
    `};
  ${(props) => props.alignItemsCenter && `align-items: center`};
  ${(props) => props.border && `border: solid 1px black`};
  ${(props) => props.borderwhite && `border: solid 1px white`};
  ${(props) => props.borderred && `border: solid 1px red`};
  ${(props) => props.bgred && `background: red`};
  ${(props) => props.positionRelative && `position: relative`};
  ${(props) => props.positionRelative && `position: relative`};
  ${(props) => props.positionAbsolute && `position: absolute`};
  ${(props) => props.opacity5 && `opacity: .5`};
  ${(props) => props.lite && "font-weight: 300"};
  ${(props) => props.semiBold && "font-weight: 500"};
  ${(props) => props.bold && `font-weight: 600`};
  ${(props) => props.extraBold && `font-weight: 700`};
  ${(props) =>
    props.flexRowReverse &&
    css`
      display: flex;
      flex-direction: row-reverse;
    `};
  ${(props) =>
    props.centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
  ${(props) =>
    props.flexWrap &&
    css`
      display: flex;
      flex-wrap: wrap;
    `};
  ${(props) => props.grow && "flex-grow: 1"};
  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      align-items: center;
    `};
  ${(props) =>
    props.alignItemsEnd &&
    css`
      align-items: flex-end;
      display: flex;
    `};
  ${(props) =>
    props.clickable &&
    css`
      user-select: none;
      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    `};
  ${(props) =>
    props.forMobile &&
    css`
      @media all and ${(props) => props.theme.media.desktop} {
        display: none;
      }
    `}
  ${(props) =>
    props.forDesktop &&
    css`
      @media all and ${(props) => props.theme.media.mobile} {
        display: none;
      }
    `}
  ${(props) => {
    const propKeys = Object.keys(props);
    const css = [];
    const addCss = (prefix, property) => {
      const prp = propKeys.filter((pk) => pk.startsWith(`${prefix}_`))[0];
      if (prp)
        css.push(`${property}: ${prp.split("_")[1].replace("n", "-")}px`);
    };
    const prefixPropMappingKeys = Object.keys(prefixPropMappings);
    for (let i = 0; i < prefixPropMappingKeys.length; i++) {
      const key = prefixPropMappingKeys[i];
      addCss(key, prefixPropMappings[key]);
    }
    const color = propKeys.filter((pk) => pk.startsWith("c_"))[0];
    if (color) css.push(`color: ${theme.colors[color.split("_")[1]]};`);
    const hcolor = propKeys.filter((pk) => pk.startsWith("c_h"))[0];
    if (hcolor) css.push(`color: #${hcolor.split("_h")[1]};`);
    const bgcolor = propKeys.filter((pk) => pk.startsWith("bg_"))[0];
    if (bgcolor)
      css.push(`background: ${theme.colors[bgcolor.split("_")[1]]};`);
    const bghcolor = propKeys.filter((pk) => pk.startsWith("bg_h"))[0];
    if (bghcolor) css.push(`background: #${bghcolor.split("_h")[1]};`);
    return css.join(";");
  }}

  ${(props) =>
    props.mcontainer &&
    css`
      @media all and ${(props) => props.theme.media.mobile} {
        padding-left: 5%;
        padding-right: 5%;
      }
    `}
  ${(props) =>
    props.mcontainer_left &&
    css`
      @media all and ${(props) => props.theme.media.mobile} {
        padding-left: 5%;
      }
    `}
`;

export default Div;
