import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.div`
  border-bottom: 1px solid #e6e9eb;
  cursor: pointer;
  padding: 17px 0;
`;

export const Content = styled.div`
  border-bottom: 1px solid #e6e9eb;
  font-size: 17px;
  line-height: 27px;
  font-weight: 300;
  padding-bottom: 24px;
  padding-right: 10px;
  ${(props) => props.theme.fonts.default};
`;

export const IconContainer = styled.div`
  display: inline-block;
  width: 30px;
  vertical-align: top;
  padding-top: 2px;
`;

export const TitleContainer = styled.div`
  display: inline-block;
  max-width: 91%;
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 80%;
  }
`;
