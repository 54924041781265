import { Div } from "@max/common-ui";
import styled from "styled-components";
import { Icon } from "notes";

const Header = styled(Div)`
  height: 49px;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray20};
`;

const Container = styled(Div)`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-x: hidden;
  background: ${(props) => props.theme.colors.white};
`;

const FullScreenModal = ({ title, onClose, children }) => {
  return (
    <Container>
      <Header alignCenter>
        <Div mcontainer w100>
          <Div spaceBetween alignCenter>
            <Div bold c_text fs_17 bg_white ml_n6 mt_3>
              {title}
            </Div>
            <Div clickable mt_9 mr_n5>
              <Icon platform name="Close" onClick={onClose} />
            </Div>
          </Div>
        </Div>
      </Header>
      <Div mcontainer>{children}</Div>
    </Container>
  );
};

export default FullScreenModal;
