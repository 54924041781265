import { useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { Route, Switch, useRouteMatch } from "react-router";
import {
  AppContainer,
  AppLayout,
  Div,
  Footer,
  useIsMobile,
} from "@max/common-ui";
import AddArtistGroupForm from "./AddArtistGroupForm";
import ArtistGroupSearch from "./ArtistGroupSearch";
import Header from "../common/Header";
import ArtistGroup from "./ArtistGroup";

export const ROUTES = {
  ARTIST_GROUP: "/artist/:artistGroupId",
  ADD_ARTIST_GROUP: "/add-artist",
};

const Title = styled(Div)`
  font-family: Work Sans;
  font-weight: 300;
  margin-top: 56px;
  margin-bottom: 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 35px;
    margin-bottom: 10px;
    font-size: 40px;
  }
`;

const Layout = ({ children }) => {
  const isMobile = useIsMobile();
  const {
    colors: { white: whiteColor, background: backgroundColor },
  } = useContext(ThemeContext);

  return (
    <AppLayout>
      <Header />
      <AppContainer>
        <Div mcontainer>
          <Title fs_64>ARTIST MANAGEMENT</Title>
        </Div>
        <Div mb_70>{children}</Div>
      </AppContainer>
      <Footer background={isMobile ? whiteColor : backgroundColor} />
    </AppLayout>
  );
};

const ArtistManagement = () => {
  const match = useRouteMatch();
  const baseUrl = match.path;
  const isMobile = useIsMobile();
  const {
    colors: { white: whiteColor, background: backgroundColor },
  } = useContext(ThemeContext);

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = whiteColor;
    else document.body.style.backgroundColor = backgroundColor;
  }, [isMobile]);

  return (
    <Layout>
      <Switch>
        <Route exact path={`${baseUrl}${ROUTES.ARTIST_GROUP}`}>
          <ArtistGroup />
        </Route>
        <Route exact path={`${baseUrl}${ROUTES.ADD_ARTIST_GROUP}`}>
          <AddArtistGroupForm />
        </Route>
        <Route>
          <ArtistGroupSearch />
        </Route>
      </Switch>
    </Layout>
  );
};

export default ArtistManagement;
