import { Div } from "@max/common-ui";
import { useAppContext } from "components/AppContextProvider";
import { useMessagesAppContext } from "components/Messages/MessagesAppContextProvider";
import styled, { css } from "styled-components";
import { AppContainer } from "../../common/styles";
import { Troubleshoot } from "../../Troubleshoot";
import { useMessagesContext } from "../MessagesContextProvider";
import { Header } from "./Header";
import ShoutoutOptions from "./ShoutoutOptions";
import { ShoutoutsSelect } from "./ShoutoutsSelect";
import { Tabs } from "./Tabs";

const TabsWrapper = styled(Div)`
  align-items: flex-end;
  display: flex;
  @media all and ${(props) => props.theme.media.mobile} {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }
`;

const Container = styled(Div)`
  background-color: ${(props) => props.theme.colors.purple40};
  color: white;
  z-index: 300;
  box-shadow: 0 8px 24px 0 ${(props) => props.theme.colors.purple60};
`;

const NavigationWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    height: auto;
    ${(props) =>
      props.isMessageSelected &&
      css`
        display: none;
      `}
  }
`;

const TroubleshootWrapper = styled(Div)`
  position: fixed;
  background-color: white;
  z-index: 100000000000;
  height: 100vh;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100vw;
`;

const AppNavigationWrapper = styled(AppContainer)`
  width: 100%;
  padding-top: 20px;
  ${(props) =>
    props.isNewArtistGroup &&
    css`
      padding-top: 0;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    ${(props) =>
      props.isMessageSelected &&
      css`
        background: white;
        padding-left: 0;
      `}
    padding-top: 0;
  }
`;

const MessageFiltersWrapper = styled(Div)`
  display: flex;
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
`;

const AppNavigation = () => {
  const { selectedMessage } = useMessagesContext();
  const { selectedShoutout, isNewArtistGroup } = useMessagesAppContext();

  const {
    isTroubleshootModalOpen,
    setIsTroubleshootModalOpen,
  } = useAppContext();

  const isMessageSelected = !!selectedMessage;

  const navigationUI = (
    <NavigationWrapper isMessageSelected={isMessageSelected} spaceBetween h_68>
      <MessageFiltersWrapper>
        <ShoutoutsSelect />
        <TabsWrapper>
          <Tabs />
        </TabsWrapper>
      </MessageFiltersWrapper>
      {selectedShoutout && !isNewArtistGroup && <ShoutoutOptions />}
    </NavigationWrapper>
  );

  const troubleshootUI = (
    <>
      {isTroubleshootModalOpen && (
        <TroubleshootWrapper>
          <Troubleshoot
            onClose={() => {
              setIsTroubleshootModalOpen(false);
            }}
          />
        </TroubleshootWrapper>
      )}
    </>
  );

  return (
    <>
      {troubleshootUI}
      <Container w100 alignItemsEnd>
        <AppNavigationWrapper
          isNewArtistGroup={isNewArtistGroup}
          isMessageSelected={isMessageSelected}
        >
          <Header />
          {!isNewArtistGroup && navigationUI}
        </AppNavigationWrapper>
      </Container>
    </>
  );
};

export default AppNavigation;
