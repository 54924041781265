import Div from "./Div";
import styled, { css } from "styled-components";

export const Button = styled(Div)`
  height: 38px;
  width: 184px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  user-select: none;
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  background-color: #26a6fa;
  border: solid 2px transparent;

  ${(props) =>
    props.secondary &&
    css`
      border: solid 2px #26a6fa;
      background-color: white;
      color: #26a6fa;
    `}
  :hover {
    border: solid 2px transparent;
    color: white;
    background-color: #3f7595;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
    cursor: pointer;
  }
`;
