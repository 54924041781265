import {
  Div,
  Modal as ModalBase,
  ModalContainer,
  ModalWrapper,
} from "@max/common-ui";
import styled from "styled-components";
import { ButtonPrimary, ButtonSecondary } from "notes";
import { useMessagesAppContext } from "../MessagesAppContextProvider";

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    width: 472px;
    padding-bottom: 40px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 60vw;
    }
  }
  ${ModalContainer} {
    @media all and ${(props) => props.theme.media.mobile} {
      margin-top: 50px;
    }
  }
`;

const ButtonsWrapper = styled(Div)`
  width: 100%;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
    height: 100px;
  }
`;

export const EndCampaignModal = ({ isOpen, onClose, onConfirm }) => {
  const { selectedShoutout } = useMessagesAppContext();
  return (
    <Modal {...{ isOpen, onClose }}>
      <Div c_text textAlignCenter>
        <Div fs_25 extraBold>
          End Campaign
        </Div>
        <Div bold fs_14 c_gray20 truncate>
          {selectedShoutout.data.name}
        </Div>
        <Div fs_17 c_text lite mt_24 centered w100>
          <Div maxw_392>
            Are you sure you want to end this campaign? This will disable all
            request forms (and links) related to this campaign and cannot be
            undone. Requests that have already been submitted will remain
            accessible and active until they are declined or completed.
          </Div>
        </Div>
        <ButtonsWrapper mt_40 spaceAround>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Yes, End Campaign
          </ButtonPrimary>
        </ButtonsWrapper>
      </Div>
    </Modal>
  );
};
