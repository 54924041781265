var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Icon } from "notes";
import { Div } from "../../../../../Div";
import { happyCloudSvg, sadCloudSvg } from "./svgs";
var PlaceholderText = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 288px;\n  @media all and ", " {\n    width: 80%;\n  }\n"], ["\n  width: 288px;\n  @media all and ", " {\n    width: 80%;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var contentOptions = {
    inactive: {
        message: "Drag and drop your image",
        icon: (React.createElement(Div, { svg_gray20: true },
            React.createElement(Icon, { form: true, name: "Upload", width: "80px", height: "80px" }))),
    },
    active: {
        valid: {
            message: "Drop It!",
            icon: happyCloudSvg,
        },
        invalid: {
            message: "Are you sure this is an image?",
            icon: sadCloudSvg,
        },
    },
};
var DefaultWrapper = function (_a) {
    var children = _a.children;
    return React.createElement(Div, null, children);
};
var ImageDropzone = function (_a) {
    var onSelect = _a.onSelect, _b = _a.placeholderText, placeholderText = _b === void 0 ? "" : _b, _c = _a.Wrapper, Wrapper = _c === void 0 ? DefaultWrapper : _c;
    var dzProps = useDropzone({
        accept: "image/*",
        onDrop: function (_a) {
            var file = _a[0];
            if (file) {
                var reader_1 = new FileReader();
                reader_1.readAsDataURL(file);
                reader_1.onload = function () { return onSelect(reader_1.result); };
            }
        },
    });
    var getRootProps = dzProps.getRootProps, getInputProps = dzProps.getInputProps, isDragActive = dzProps.isDragActive, isDragAccept = dzProps.isDragAccept;
    var content = !isDragActive
        ? contentOptions.inactive
        : contentOptions.active[isDragAccept ? "valid" : "invalid"];
    var contentUI = (React.createElement(Div, null,
        React.createElement(Div, { centered: true, mt_n10: true }, content.icon),
        React.createElement(Div, { bold: true, mt_10: true }, content.message)));
    return (React.createElement(Div, { c_text: true, clickable: true, fs_17: true, textAlignCenter: true },
        React.createElement(Wrapper, __assign({}, getRootProps()),
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement(Div, null,
                contentUI,
                !isDragActive && (React.createElement(Div, { dflex: true, centered: true },
                    "or",
                    React.createElement(Div, { ml_5: true, underline: true, bold: true, c_action: true }, "click to browse"))),
                React.createElement(Div, { centered: true },
                    React.createElement(PlaceholderText, { lite: true, fs_12: true, c_gray30: true, mt_5: true }, placeholderText))))));
};
export default ImageDropzone;
var templateObject_1;
