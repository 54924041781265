import * as yup from "yup";
import { Form as FForm, Formik } from "formik";
import { useState } from "react";
import { IconWrapper, FormLink } from "./common";
import { AppLoading, Div, FormikErrorFocus, Input } from "@max/common-ui";
import { Icon } from "notes";
import { yupStringRequired } from "../common";
import { useArtistGroupContext } from "../../common/ArtistGroupProvider";
import { fstore } from "firebase-tools";

const ArtistGroupName = ({ name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { artistGroupId, getData } = useArtistGroupContext();

  const onSubmitForm = async (values) => {
    setIsLoading(true);
    await fstore
      .doc(`artist_groups/${artistGroupId}`)
      .update({ name: values.name });
    await getData();
    setIsLoading(false);
    setIsEditing(false);
  };

  const editDataUI = isEditing && (
    <Formik
      enableReinitialize
      initialValues={{ name }}
      onSubmit={onSubmitForm}
      validationSchema={yup.object({
        name: yupStringRequired,
      })}
    >
      {({ submitForm }) => (
        <FForm>
          <Input width="100%" name="name" placeholder="Artist Name..." />
          <Div dflex h_86 alignCenter>
            <FormLink mr_60 onClick={() => setIsEditing(false)}>
              Cancel
            </FormLink>
            <FormLink onClick={submitForm}>Save</FormLink>
          </Div>
          <FormikErrorFocus />
        </FForm>
      )}
    </Formik>
  );

  const viewDataUI = (
    <Div spaceBetween dflex mb_25>
      <Div fs_25 bold>
        {name}
      </Div>
      <IconWrapper
        title="Edit Artist Name"
        clickable
        onClick={() => setIsEditing(true)}
      >
        <Icon tertiary name="Edit" />
      </IconWrapper>
    </Div>
  );

  return (
    <>
      {isLoading && <AppLoading />}
      {isEditing ? editDataUI : viewDataUI}
    </>
  );
};

export default ArtistGroupName;
