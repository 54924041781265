import { Div } from "@max/common-ui";
import { generatePath, useHistory, useParams } from "react-router";
import { Select as SelectBase } from "notes";
import { ROUTES } from "components/Routes";
import styled from "styled-components";
import { LoaderInline } from "notes";
import { useMessagesAppContext } from "../MessagesAppContextProvider";
import { TABS, useMessagesContext } from "../MessagesContextProvider";

export const Select = styled(SelectBase)`
  background: ${(props) => props.theme.colors.purple55};
  border: solid 1px ${(props) => props.theme.colors.purple55};
  border-radius: 3px;
  overflow: hidden;
  user-select: none;
  :hover {
    cursor: pointer;
  }
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
  span {
    color: ${(props) => props.theme.colors.white};
  }
`;

const ShoutoutsSelectWrapper = styled(Div)`
  width: 350px;
  margin-right: 26px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin-right: 0;
  }
`;

export const ShoutoutsSelect = () => {
  const { shoutouts, isLoadingShoutouts } = useMessagesAppContext();
  return (
    <Div alignCenter>
      <ShoutoutsSelectWrapper>
        {isLoadingShoutouts ? (
          <LoaderInline />
        ) : (
          <ShoutoutsSelectWithData shoutouts={shoutouts} />
        )}
      </ShoutoutsSelectWrapper>
    </Div>
  );
};

const ShoutoutsSelectWithData = ({ shoutouts }) => {
  const { artistGroupId, shoutoutId } = useParams<any>();
  const { selectTab } = useMessagesContext();
  const history = useHistory();

  const defaultOption = { id: "All", text: "All Requests" };
  const shoutoutOptions = shoutouts.map((s) => ({
    text: s.data.name,
    id: s.id,
  }));
  return (
    <Select
      options={[defaultOption, ...shoutoutOptions]}
      selected={
        shoutoutId
          ? {
              id: shoutoutId,
              text: shoutoutOptions.find((s) => s.id === shoutoutId)?.text,
            }
          : defaultOption
      }
      onChange={(s) => {
        selectTab(TABS.QUEUE);
        if (s.id === defaultOption.id)
          history.push(
            generatePath(ROUTES.MESSAGES, {
              artistGroupId,
            })
          );
        else
          history.push(
            generatePath(ROUTES.SHOUTOUT_MESSAGES, {
              artistGroupId,
              shoutoutId: s.id,
            })
          );
      }}
    />
  );
};
