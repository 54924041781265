var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Div } from "../Div";
import { Icon } from "notes";
import styled from "styled-components";
var ErrorMessageText = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 15px;\n  color: ", ";\n"], ["\n  font-size: 15px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.inputErrorBorder; });
export var ErrorMessage = function (_a) {
    var msg = _a.msg;
    return (React.createElement(ErrorMessageText, { mt_10: true, dflex: true, alignCenter: true },
        React.createElement(Icon, { form: true, name: "Error" }),
        React.createElement(Div, { ml_10: true, mt_3: true }, msg)));
};
export default ErrorMessage;
var templateObject_1;
