import styled, { ThemeContext } from "styled-components";
import { AppContainer, Div, useIsMobile } from "@max/common-ui";
import { Icon } from "notes";
import { useContext, useEffect, useState } from "react";
import VideoRecorder from "components/Messages/VideoMessage/VideoMessageRecorder/VideoRecorder";
import {
  useVideoMessageContext,
  VideoMessageContextProvider,
} from "components/Messages/VideoMessageContextProvider";
import Settings from "components/common/Settings";
import { Troubleshoot } from "components/Troubleshoot";
import { VideoRecorderWrapper } from ".";
import Videojs from "components/Messages/VideoMessage/VideoMessageRecorder/Videojs";
import DiscardRercordingModal from "components/common/DiscardRecordingModal";
import { useAppContext } from "components/AppContextProvider";

const VideoRecorderInput = ({
  isVideoRecorderOpen,
  setIsVideoRecorderOpen,
  setRecordingBlob,
}) => {
  const onStopRecording = (blob) => {
    if (blob) {
      setIsVideoRecorderOpen(false);
      setRecordingBlob(blob);
    }
  };

  return (
    <VideoMessageContextProvider>
      {isVideoRecorderOpen && (
        <VideoRecorderInputWithContext
          onClose={() => setIsVideoRecorderOpen(false)}
          onStopRecording={onStopRecording}
        />
      )}
    </VideoMessageContextProvider>
  );
};

const IconWrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const TroubleshootWrapper = styled(Div)`
  position: fixed;
  background-color: white;
  z-index: 100000000000;
  height: 100vh;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100vw;
`;

const HeaderWrapper = styled(Div)`
  height: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    height: 70px;
  }
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Container = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 10px;
  }
`;

const BottomIconLabel = styled(Div)`
  font-size: 11px;
  font-weight: 600;
  :hover {
    text-decoration: underline;
  }
`;

const VideoRecorderInputWithContext = ({ onClose, onStopRecording }) => {
  const {
    selectedAudioDeviceId,
    selectedVideoDeviceId,
    setSelectedAudioDeviceId,
    setSelectedVideoDeviceId,
    setDevices,
  } = useVideoMessageContext();
  const [userInitializedCamera, setUserInitializedCamera] = useState(false);
  const [recordingBlob, setRecordingBlob] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const {
    isTroubleshootModalOpen,
    setIsTroubleshootModalOpen,
  } = useAppContext();
  const isMobile = useIsMobile();
  const {
    colors: { black: colorBlack },
  } = useContext(ThemeContext);

  useEffect(() => {
    if (isMobile) setIsSettingsModalOpen(false);
  }, [isMobile]);

  useEffect(() => {
    if (isTroubleshootModalOpen) {
      document.body.style.overflow = "hidden";
      setIsSettingsModalOpen(false);
    } else document.body.style.overflow = "auto";
  }, [isTroubleshootModalOpen]);

  useEffect(() => {
    document.body.style.backgroundColor = colorBlack;
  }, []);

  const _onClose = () => {
    if (recordingBlob) setIsDiscardRecordingModalOpen(true);
    else onClose();
  };

  const onGearIconClick = () => {
    if (isMobile) setIsTroubleshootModalOpen(true);
    else setIsSettingsModalOpen(true);
  };

  const discardRecordingUI = (
    <DiscardRercordingModal
      setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
      isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
      onConfirm={onClose}
    />
  );

  const troubleshootUI = (
    <>
      {isTroubleshootModalOpen && (
        <TroubleshootWrapper>
          <Troubleshoot
            onClose={() => {
              setIsTroubleshootModalOpen(false);
            }}
          />
        </TroubleshootWrapper>
      )}
    </>
  );

  const settingsUI = (
    <>
      {isSettingsModalOpen && (
        <Div mt_20>
          <Settings
            withAutoPlayOption={false}
            onClose={() => setIsSettingsModalOpen(false)}
          />
        </Div>
      )}
    </>
  );

  const headerUI = (
    <AppContainer>
      <HeaderWrapper mcontainer>
        <IconWrapper onClick={_onClose}>
          <Icon name="Left" height={32} width={32} />
        </IconWrapper>
        <Div>
          {settingsUI}
          <IconWrapper onClick={onGearIconClick}>
            <Icon name="Gear" height={32} width={32} />
          </IconWrapper>
        </Div>
      </HeaderWrapper>
    </AppContainer>
  );

  const BottomIcon = ({ label, icon, onClick = null }) => {
    return (
      <Div clickable c_white onClick={onClick}>
        <IconWrapper>{icon}</IconWrapper>
        <BottomIconLabel mt_8>{label}</BottomIconLabel>
      </Div>
    );
  };

  const bottomControlsUI = (
    <Div spaceAround mt_30 mb_50>
      <BottomIcon
        label="Delete"
        icon={<Icon name="Delete" />}
        onClick={() => setRecordingBlob(null)}
      />
      <BottomIcon
        label="Use This"
        icon={<Icon badge name="FacebookVerified" width="23px" height="23px" />}
        onClick={() => onStopRecording(recordingBlob)}
      />
    </Div>
  );

  return (
    <>
      {discardRecordingUI}
      {headerUI}
      {troubleshootUI}
      <Container>
        <Div>
          <VideoRecorderWrapper>
            {recordingBlob ? (
              <Videojs
                withObjectFitCover={true}
                width="100%"
                height="100%"
                blob={recordingBlob}
              />
            ) : (
              <VideoRecorder
                width="100%"
                height="100%"
                initializeOnMount={userInitializedCamera}
                onInit={() => setUserInitializedCamera(true)}
                selectedAudioDeviceId={selectedAudioDeviceId}
                selectedVideoDeviceId={selectedVideoDeviceId}
                setSelectedAudioDeviceId={setSelectedAudioDeviceId}
                setSelectedVideoDeviceId={setSelectedVideoDeviceId}
                setDevices={setDevices}
                onStopRecording={(blob) => setRecordingBlob(blob)}
                onTroubleshoot={() => setIsTroubleshootModalOpen(true)}
              />
            )}
          </VideoRecorderWrapper>
          {!!recordingBlob && bottomControlsUI}
        </Div>
      </Container>
    </>
  );
};

export default VideoRecorderInput;
