import styled, { css } from "styled-components";
import MessageList from "./MessageList";
import VideoMessage from "./VideoMessage";
import Div from "../common/Div";
import waveSvg from "../../assets/wave.svg";
import {
  MessagesContextProvider,
  useMessagesContext,
} from "./MessagesContextProvider";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "../../hooks";
import { theme } from "../../theme";
import { scrollBarDarkCss } from "../common/styles";
import { BrowserInfo, Message } from "notes";
import { VideoMessageContextProvider } from "components/Messages/VideoMessageContextProvider";
import AppNavigation from "components/Messages/AppNavigation";
import {
  MessagesAppContextProvider,
  useMessagesAppContext,
} from "./MessagesAppContextProvider";
import { ShareCampaignModal } from "components/common/ShareCampaignModal";
import { useAppContext } from "components/AppContextProvider";
import { StripeConnectMessage } from "../common/StripeConnectMessage";
import { NewArtistGroupMessage } from "./NewArtistGroupMessage";
import { AppLoading } from "@max/common-ui";
import {
  ArtistGroupContextProvider,
  useArtistGroupContext,
} from "components/common/ArtistGroupProvider";
import { ArtistGroupRole } from "@musicaudienceexchange/shoutout-interface/lib/firestore/artists";
import { sRedirects } from "components/StripeConnectRedirect";

const AppContainer = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled(Div)`
  height: 100%;
  display: flex;
  overflow-y: hidden;
`;

const Messages = () => {
  return (
    <MessagesAppContextProvider>
      <MessagesContextProvider>
        <VideoMessageContextProvider>
          <ArtistGroupContextProvider>
            <AppContainer>
              <MessagesWithContext />
            </AppContainer>
          </ArtistGroupContextProvider>
        </VideoMessageContextProvider>
      </MessagesContextProvider>
    </MessagesAppContextProvider>
  );
};

const admin: ArtistGroupRole = "admin";

const MessagesWithContext = () => {
  const { isLoadingShoutouts } = useMessagesAppContext();
  const { data, isArtistGroupAdmin, artistGroupId } = useArtistGroupContext();
  const isStripeReady =
    data?.group?.stripeConnectId &&
    data?.group?.stripeConnectStatus === "active";

  const stripeConnectUI = data && (
    <StripeConnectMessage
      artistGroupId={artistGroupId}
      isStripeReady={isStripeReady}
      artistGroupAdmins={data?.members
        .filter((d) => d.roles.includes(admin))
        .map((a) => a?.name)}
      isArtistGroupAdmin={isArtistGroupAdmin}
      stripeRedirectTo={sRedirects.artistGroupMessages}
    />
  );

  return (
    <>
      {!isLoadingShoutouts ? (
        <>
          <Div>
            {stripeConnectUI}
            <AppNavigation />
          </Div>
          <MessagesWithShoutouts />
        </>
      ) : (
        <AppLoading />
      )}
    </>
  );
};

const MessagesWithShoutouts = () => {
  const { selectedShoutout, isNewArtistGroup } = useMessagesAppContext();
  const {
    isNewCampaign,
    isCampaignEdit,
    setIsNewCampaign,
    setIsCampaignEdit,
  } = useAppContext();
  const { selectedTab, selectedMessage } = useMessagesContext();
  const leftPanelRef = useRef(null);
  const isMessageSelected = !!selectedMessage;
  const isMobile = useMediaQuery(theme.media.mobile);

  useEffect(() => {
    if (
      BrowserInfo.getBrowser().name === "Safari" &&
      BrowserInfo.getOS().name === "Mac OS"
    )
      alert(
        "Recording of video messages on Safari for OS X is not supported at this time. Please use Chrome or another device to record your Shoutouts"
      );
  }, []);

  useEffect(() => {
    if (leftPanelRef?.current) leftPanelRef.current.scrollTop = 0;
  }, [selectedTab]);

  const contentUI = (
    <Div positionAbsolute w100 h100>
      <VideoMessage />
    </Div>
  );

  const editCampaignUI = (
    <Message
      open={isCampaignEdit}
      setOpen={setIsCampaignEdit}
      color="green"
      timeout={3000}
    >
      <Div semiBold>Campaign updated successfully!</Div>
    </Message>
  );

  const shareCampaignModalUI = (
    <>
      {selectedShoutout && (
        <ShareCampaignModal
          title="New Campaign Created!"
          isOpen={isNewCampaign}
          onClose={() => {
            setIsNewCampaign(false);
          }}
        />
      )}
    </>
  );

  return (
    <Wrapper>
      {editCampaignUI}
      {shareCampaignModalUI}
      {((isMobile && !isMessageSelected) || !isMobile) && !isNewArtistGroup && (
        <LeftPanelContainer
          ref={leftPanelRef}
          isMessageSelected={isMessageSelected}
        >
          <MessageList />
        </LeftPanelContainer>
      )}
      {((isMobile && isMessageSelected) || !isMobile || isNewArtistGroup) && (
        <ContentContainer isMessageSelected={isMessageSelected}>
          <BackgroundGraphicsWrapper h100 w100 positionRelative>
            <BackgroundGraphics positionAbsolute />
            {!isNewArtistGroup && contentUI}
            {isNewArtistGroup && (
              <Div positionAbsolute w100 h100>
                <NewArtistGroupMessage />
              </Div>
            )}
          </BackgroundGraphicsWrapper>
        </ContentContainer>
      )}
    </Wrapper>
  );
};

const BackgroundGraphicsWrapper = styled(Div)`
  overflow: hidden;
`;

const BackgroundGraphics = styled(Div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: -6%;
  background-image: url(${waveSvg});
  background-size: 100% 100%;
  background-position: 40% 0%;
  opacity: 15%;
  pointer-events: none;
  background-size: cover;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    background: black;
  }
`;

const LeftPanelContainer = styled(Div)`
  @keyframes slide-left {
    from {
      margin-right: 40%;
      width: 300%;
    }
    to {
      margin-right: 0%;
      width: 100%;
    }
  }
  height: 100%;
  background-color: #1d2336;
  width: 380px;
  @media all and (max-width: 1000px) {
    width: 320px;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    -webkit-animation: 0.2s slide-left; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: 0.2s slide-left; /* Firefox < 16 */
    -ms-animation: 0.2s slide-left; /* Internet Explorer */
    -o-animation: 0.2s slide-left; /* Opera < 12.1 */
    animation: 0.2s slide-left;
    animation-timing-function: ease-out;
    width: 100vw;
  }
  overflow-y: scroll;
  ${scrollBarDarkCss}
`;

const ContentContainer = styled(Div)`
  @keyframes slide-right {
    from {
      margin-left: 70%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  height: 100%;
  flex: 1;
  @media all and ${(props) => props.theme.media.mobile} {
    -webkit-animation: 0.2s slide-right; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: 0.2s slide-right; /* Firefox < 16 */
    -ms-animation: 0.2s slide-right; /* Internet Explorer */
    -o-animation: 0.2s slide-right; /* Opera < 12.1 */
    animation: 0.2s slide-right;
    animation-timing-function: ease-out;
    width: 100vw;
  }
  background-color: ${(props) => props.theme.colors.purple50};
`;

export default Messages;
