import React from "react";
import SafariDefault from "Images/SafariDefault.png";
import { ImageLogo } from "./windows";

const imagePath = `${process.env.REACT_APP_ASSETS_PATH}/troubleshoot/ios`;

export const ios = [
  {
    key: "safari",
    name: "Safari",
    logo: <ImageLogo src={SafariDefault} />,
    data: [
      {
        type: "text",
        content: "Allow Safari access to Microphone and Camera",
      },
      {
        type: "image",
        content: `${imagePath}/safari/1.png`,
      },
      {
        type: "text",
        content: "Or, click the double A icon and choose Website Settings",
      },
      {
        type: "image",
        content: `${imagePath}/safari/2.png`,
      },
      {
        type: "text",
        content: "Allow access to Camera and Microphone",
      },
      {
        type: "image",
        content: `${imagePath}/safari/3.png`,
      },
      {
        type: "image",
        content: `${imagePath}/safari/4.png`,
      },
    ],
  },
];
