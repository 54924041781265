export const micIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7v1a3 3 0 005.995.176L11 8V7h1v1a4 4 0 01-3.5 3.969V15h2a.5.5 0 110 1h-5a.5.5 0 110-1h2v-3.03A4 4 0 014 8V7h1zm3-7a2 2 0 012 2v6a2 2 0 11-4 0V2a2 2 0 012-2zm_0 1a1 1 0 00-.993.883L7 2v6a1 1 0 001.993.117L9 8V2a1 1 0 00-1-1z"
      fill="#596266"
    />
  </svg>
);

export const quoteOpenIcon = (
  <svg
    width="42px"
    height="30px"
    viewBox="0 0 42 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Quotes, Open</title>
    <g
      id="Desktop-2.0"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M9.88235294,30 C6.71022379,30 4.27016105,28.9091018 2.5620915,26.7272727 C0.854021961,24.5454436 0,21.7575927 0,18.3636364 C0,14.3636164 1.08277784,10.7424405 3.24836601,7.5 C5.41395418,4.25755955 8.57078536,1.75758455 12.7189542,0 L19.5816993,4.81818182 C13.481451,7.18183 9.69935373,10.4242218 8.23529412,14.5454545 L8.60130719,14.8181818 C9.39433948,14.1515118 10.3398638,13.8181818 11.4379085,13.8181818 C13.4509905,13.8181818 15.2200359,14.4848418 16.745098,15.8181818 C18.2701601,17.1515218 19.0326797,19.0605936 19.0326797,21.5454545 C19.0326797,24.2121345 18.1939082,26.2878714 16.5163399,27.7727273 C14.8387716,29.2575832 12.6274647,30 9.88235294,30 Z M32.3006536,30 C29.1285244,30 26.6884617,28.9091018 24.9803922,26.7272727 C23.2723226,24.5454436 22.4183007,21.7575927 22.4183007,18.3636364 C22.4183007,14.3636164 23.5010785,10.7424405 25.6666667,7.5 C27.8322548,4.25755955 30.989086,1.75758455 35.1372549,0 L42,4.81818182 C35.8997516,7.18183 32.1176544,10.4242218 30.6535948,14.5454545 L31.0196078,14.8181818 C31.8126401,14.1515118 32.7581644,13.8181818 33.8562092,13.8181818 C35.8692911,13.8181818 37.6383366,14.4848418 39.1633987,15.8181818 C40.6884608,17.1515218 41.4509804,19.0605936 41.4509804,21.5454545 C41.4509804,24.2121345 40.6122088,26.2878714 38.9346405,27.7727273 C37.2570722,29.2575832 35.0457654,30 32.3006536,30 Z"
        id="Quotes,-Open"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export const quoteClosedIcon = (
  <svg
    width="42px"
    height="30px"
    viewBox="0 0 42 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Quotes, Closed</title>
    <g
      id="Desktop-2.0"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M9.88235294,30 C6.71022379,30 4.27016105,28.9091018 2.5620915,26.7272727 C0.854021961,24.5454436 0,21.7575927 0,18.3636364 C0,14.3636164 1.08277784,10.7424405 3.24836601,7.5 C5.41395418,4.25755955 8.57078536,1.75758455 12.7189542,0 L19.5816993,4.81818182 C13.481451,7.18183 9.69935373,10.4242218 8.23529412,14.5454545 L8.60130719,14.8181818 C9.39433948,14.1515118 10.3398638,13.8181818 11.4379085,13.8181818 C13.4509905,13.8181818 15.2200359,14.4848418 16.745098,15.8181818 C18.2701601,17.1515218 19.0326797,19.0605936 19.0326797,21.5454545 C19.0326797,24.2121345 18.1939082,26.2878714 16.5163399,27.7727273 C14.8387716,29.2575832 12.6274647,30 9.88235294,30 Z M32.3006536,30 C29.1285244,30 26.6884617,28.9091018 24.9803922,26.7272727 C23.2723226,24.5454436 22.4183007,21.7575927 22.4183007,18.3636364 C22.4183007,14.3636164 23.5010785,10.7424405 25.6666667,7.5 C27.8322548,4.25755955 30.989086,1.75758455 35.1372549,0 L42,4.81818182 C35.8997516,7.18183 32.1176544,10.4242218 30.6535948,14.5454545 L31.0196078,14.8181818 C31.8126401,14.1515118 32.7581644,13.8181818 33.8562092,13.8181818 C35.8692911,13.8181818 37.6383366,14.4848418 39.1633987,15.8181818 C40.6884608,17.1515218 41.4509804,19.0605936 41.4509804,21.5454545 C41.4509804,24.2121345 40.6122088,26.2878714 38.9346405,27.7727273 C37.2570722,29.2575832 35.0457654,30 32.3006536,30 Z"
        id="Quotes,-Closed"
        fill="#000000"
        fillRule="nonzero"
        transform="translate(21.000000, 15.000000) scale(-1, -1) translate(-21.000000, -15.000000) "
      ></path>
    </g>
  </svg>
);
