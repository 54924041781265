import React from "react";
import styled, { css } from "styled-components";
import { GridRow, Select, Icon } from "notes";
import { useVideoMessageContext } from "../../Messages/VideoMessageContextProvider";
import { micIcon } from "../icons";
import Div from "../Div";

export const IODeviceSelector = () => {
  const {
    userIsRecordingVideo,
    selectedAudioDeviceId,
    selectedVideoDeviceId,
    setSelectedAudioDeviceId,
    setSelectedVideoDeviceId,
    videoOptions,
    audioOptions,
  } = useVideoMessageContext();

  const isDeviceSelectionEnabled =
    videoOptions.length > 0 && audioOptions.length > 0 && !userIsRecordingVideo;

  return (
    <Container isDeviceSelectionDisabled={!isDeviceSelectionEnabled}>
      <SelectVideo
        disabled={!isDeviceSelectionEnabled}
        icon={
          <Label yCenter>
            <Icon form name="Video" /> Video:
          </Label>
        }
        options={videoOptions || []}
        selected={
          videoOptions.find((vo) => vo.id == selectedVideoDeviceId) || ""
        }
        onChange={(o) => setSelectedVideoDeviceId(o.id)}
      />
      <Div mt_15>
        <SelectMicrophone
          disabled={!isDeviceSelectionEnabled}
          icon={<Label yCenter>{micIcon} Microphone:</Label>}
          options={audioOptions || []}
          selected={
            audioOptions.find((vo) => vo.id == selectedAudioDeviceId) || ""
          }
          onChange={(o) => setSelectedAudioDeviceId(o.id)}
        />
      </Div>
    </Container>
  );
};

const Container = styled(Div)`
  ${(props) =>
    props.isDeviceSelectionDisabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.3;
    `}
  margin-top: 16px;
  z-index: 100;
`;

const SelectVideo = styled(Select)`
  cursor: default;
  padding-left: 94px;
`;

const SelectMicrophone = styled(Select)`
  cursor: default;
  padding-left: 140px;
`;

const Label = styled(GridRow)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    margin-right: 12px;
  }
`;

export default IODeviceSelector;
