import styled from "styled-components";
import Div from "./Div";

export const Backdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
`;

export const DropdownDiv = styled(Div)`
  background-color: white;
  border: solid 1px#F9FAFB;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  z-index: 10000000000;
`;

export const ModalContainer = styled(Div)`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000000000;
  width: 100vw;
  height: 100vh;
  margin-top: 115px;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;
